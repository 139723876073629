import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DEFAULT_LNG, SITE_LANGUAGES, SiteLanguage } from "../../../util/const";
import { Language } from "../../../types";

const enabledLngs = ["en"];
const LanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");

    // Find the language based on priority: storedLanguage, navigator.language, i18n.language
    const foundLanguage = SITE_LANGUAGES.find(
      (lng) => lng.code === storedLanguage,
    );
    const languageToDisplay =
      foundLanguage ||
      SITE_LANGUAGES.find((lng) => lng.code === navigator.language) ||
      SITE_LANGUAGES.find((lng) => lng.code === i18n.language);

    return DEFAULT_LNG.name || languageToDisplay?.name;
  });

  const changeLanguage = (language: SiteLanguage) => {
    i18n.changeLanguage(language.code);
    localStorage.setItem("selectedLanguage", language.code);
    setCurrentLanguage(language.name); // Save the selected language
  };

  return (
    <Dropdown className="sm-text-center">
      <Dropdown.Toggle
        variant="secondary"
        className="dropdown-default"
        id="dropdown-language"
      >
        {currentLanguage}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="secondary">
        {SITE_LANGUAGES.map((language) => (
          <Dropdown.Item
            disabled={!enabledLngs.includes(language.code)}
            key={language.code}
            onClick={() => changeLanguage(language)}
          >
            {enabledLngs.includes(language.code)
              ? language.name
              : `${language.name} (Coming soon)`}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
