import { Button, Col, Container, Row } from "react-bootstrap";
import "./index.css";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { URLMAP } from "../../util/const";

function BonusesPage() {
  const { t } = useTranslation();

  return (
    <div className="purple-gradient inner-page-container mb-5">
      <Container className="py-5 ">
        <Row className="justify-content-between">
          <Col xs className="sm-opacity-0 d-md-block">
            <div>
              {" "}
              <Link to={URLMAP.HOME}>
                <Button
                  variant="secondary"
                  className="secondary text-uppercase text-white d-flex gap-2 align-items-center justify-content-center"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  {t("bonusesPage.backToGame")}
                </Button>
              </Link>
            </div>
          </Col>

          <Col xs className=" md-text-center sm-text-end">
            <h4 className="text-uppercase text-golden">
              {t("bonusesPage.heading")}
            </h4>
          </Col>

          <Col xs className="text-end">
            <Link to={URLMAP.HOME}>
              <Button className="bg-transparent" variant="info">
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </Col>
        </Row>
        <hr></hr>
        <div className="text-white mt-5">
          <h3>{t("bonusesPage.heroBonus")}</h3>
          <p className="text-white">{t("bonusesPage.heroBonusDesc")}</p>
        </div>

        <div className="text-white mt-5">
          <h5>{t("bonusesPage.dailyRewards")}</h5>
          <ul className="list-highlight">
            <li>{<Trans i18nKey={"bonusesPage.dailyFirstPlace"} />}</li>
            <li>{<Trans i18nKey={"bonusesPage.dailySecondPlace"} />}</li>
            <li>{<Trans i18nKey={"bonusesPage.dailyThirdPlace"} />}</li>
            <li>
              {<Trans i18nKey={"bonusesPage.dailyFourthToFifthPlaces"} />}
            </li>
          </ul>
        </div>

        <div className="text-white mt-5">
          <h5>{t("bonusesPage.weeklyRewards")}</h5>
          <ul className="list-highlight">
            <li>{<Trans i18nKey={"bonusesPage.weeklyFirstPlace"} />}</li>
            <li>{<Trans i18nKey={"bonusesPage.weeklySecondPlace"} />}</li>
            <li>{<Trans i18nKey={"bonusesPage.weeklyThirdPlace"} />}</li>
            <li>
              {<Trans i18nKey={"bonusesPage.weeklyFourthToFifthPlaces"} />}
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
}

export default BonusesPage;
