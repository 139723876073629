import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import CryptoModal from "../CryptoModal";
import SkinsWithdrawModal from "../SkinsWithdrawModal";
import {
  Coin,
  GameMethod,
  MethodType,
  Payment,
  PaymentMethodType,
} from "../../../types";
import { paymentMethods } from "../../../util/const";
import DepositItem from "../../DepositItem";

const WithdrawModal: React.FC = () => {
  const { t } = useTranslation();

  const [payment, setPayment] = useState<Payment>({
    paymentType: null,
    method: null,
  });

  const goBack = () => {
    setPayment({
      paymentType: null,
      method: null,
    });
  };

  const handleMethodClick = (
    paymentType: PaymentMethodType,
    method: MethodType,
  ) => {
    setPayment({ method, paymentType });
  };

  if (payment.paymentType) {
    return (
      <div className="withdrawal-modal-body px-1 md-px-5">
        {payment.paymentType === "crypto" && (
          <CryptoModal
            action="withdraw"
            goBack={goBack}
            chosenCoin={payment.method as Coin}
          />
        )}
        {payment.paymentType === "game" && (
          <SkinsWithdrawModal
            chosenGame={payment.method as GameMethod}
            goBack={goBack}
          />
        )}
      </div>
    );
  }

  return (
    <div className="withdrawal-modal-body px-1 md-px-5">
      <div className="d-flex flex-column gap-3">
        {Object.entries(paymentMethods).map(
          ([category, { methods, withdrawDisabled, discount }]) => {
            if (withdrawDisabled) {
              return <></>;
            }

            return (
              <div
                className=" border-top border-secondary pt-4 mt-1"
                key={category}
              >
                <h5 className="fw-bold cash-option-title text-white">
                  {t(("withdrawOptions." + category) as PaymentMethodType)}{" "}
                  {discount && (
                    <span className="m-2 bg-primary rounded-md text-black px-3 py-1 fw-bold">
                      {`-${discount}%`}
                    </span>
                  )}
                </h5>
                <div className="d-flex flex-wrap  flex-row gap-3 mt-3">
                  {/* This is a not perfect workaround for a complex dota 2 re-render bug  */}
                  {methods.map((method, index) => {
                    if (method === "dota 2") {
                      return null; // Skip rendering if method is "dota 2"
                    }
                    if (withdrawDisabled) {
                      return (
                        <span
                          key={index + "*"}
                          className="bg-secondary rounded-md fw-bold px-2 py-1"
                        >
                          {t("comingSoon")}
                        </span>
                      );
                    }

                    return (
                      <DepositItem
                        category={category as PaymentMethodType}
                        onClick={handleMethodClick}
                        isCrypto={category === "crypto"}
                        key={index + "*"}
                        method={method as MethodType}
                      />
                    );
                  })}
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default WithdrawModal;
