import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import apiRequest from "../../util/apiUtil";
import axiosInstance from "../../util/axios";
import { GameMethod, GameSkin, PaginationType } from "../../types";
import SkinItem from "../SkinItem";
import { debounce } from "lodash";
import CustomInput from "../Inputs";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/slices/modalSlice";

import { COINS_PER_DOLLAR } from "../../util/const";
import { selectUser } from "../../store/slices/userSlice";
import BackButton from "../UI/BackButton";
import Balance from "../UI/Balance";

const ITEMS_PER_PAGE = 48;
interface WithdrawalItemsResponse extends PaginationType {
  data: GameSkin[];
}
interface SkinsWithdrawModalProps {
  goBack: () => void;
  chosenGame: GameMethod;
}
const SkinsWithdrawModal: React.FC<SkinsWithdrawModalProps> = ({
  goBack,
  chosenGame = "csgo",
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState<WithdrawalItemsResponse>({
    data: [],
    total: 0,
    page: 0,
    per_page: 0,
  });
  const [selectedSkins, setSelectedSkins] = useState<GameSkin[]>([]);
  const [page, setPage] = useState<number>(1);

  const [fetching, setFetching] = useState<boolean>(false);

  const [minPrice, setMinPrice] = useState<number>(0);

  const [maxPrice, setMaxPrice] = useState<number>(99999);

  const [game, setGame] = useState<GameMethod>(chosenGame);

  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [total, setTotal] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const containerRef = useRef<HTMLDivElement>(null);

  const fetchData = async (overrideData: boolean = false) => {
    setFetching(true);

    try {
      let combinedData: GameSkin[] = [];

      // if (!searchTerm && !minPrice && maxPrice) {
      //   const topSkins = await apiRequest({
      //     axiosFunction: () =>
      //       axiosInstance.get(
      //         `/skinsback/${game}?per_page=${6}&page=1&minPrice=${
      //           3000 * COINS_PER_DOLLAR
      //         }&maxPrice=${maxPrice * COINS_PER_DOLLAR}&sortOrder=${"desc"}`,
      //       ),
      //   });
      //   combinedData = topSkins.data;
      // }

      const newData = await apiRequest({
        axiosFunction: () =>
          axiosInstance.get(
            `/skinsback/${game}?per_page=${ITEMS_PER_PAGE}&page=${
              overrideData ? 1 : page
            }&minPrice=${minPrice * COINS_PER_DOLLAR}&maxPrice=${
              maxPrice * COINS_PER_DOLLAR
            }&sortOrder=${sortOrder}&searchTerm=${searchTerm}`,
          ),
      });

      let targetData = { ...newData };

      if (!overrideData && page === newData.page) {
        const existingIds = new Set(data.data.map((item) => item.id));
        const filteredNewData = newData.data.filter(
          (item: GameSkin) => !existingIds.has(item.id),
        );
        combinedData = [...combinedData, ...data.data, ...filteredNewData];
        targetData = {
          ...newData,
          data: combinedData,
        };
      } else if (combinedData.length > 0) {
        targetData = {
          ...newData,
          data: [...combinedData, ...newData.data],
        };
      }

      setData(targetData);
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    fetchData(page === data.page);
  }, [minPrice, maxPrice, sortOrder, game, page, searchTerm]);

  useEffect(() => {
    const totalPrice =
      selectedSkins.reduce((totalPrice, item) => {
        return totalPrice + item.price;
      }, 0) / COINS_PER_DOLLAR;

    setTotal(parseFloat(totalPrice.toFixed(2)));
  }, [selectedSkins]);

  const handleSkinClick = (skin: GameSkin) => {
    const index = selectedSkins.findIndex((s) => s.id === skin.id);
    if (index === -1) {
      setSelectedSkins([...selectedSkins, skin]);
    } else {
      const updatedSelectedSkins = [...selectedSkins];
      updatedSelectedSkins.splice(index, 1);
      setSelectedSkins(updatedSelectedSkins);
    }
  };
  const dispatch = useDispatch();
  const handleWithdraw = async () => {
    try {
      setFetching(true);
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("/skinsback/withdraw", {
            itemIds: selectedSkins.map((skin) => skin.id),
          }),
        successMessage: t("withdrawCreated"),
      });
      dispatch(closeModal());
    } catch (error) {
      console.error("Error during withdrawal:", error);
    } finally {
      setFetching(false);
    }
  };

  const debouncedSearch = debounce((term: string) => {
    setSearchTerm(term);
  }, 300);
  const { balance } = useSelector(selectUser);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
          containerRef.current.scrollHeight
      ) {
        if (!fetching && data.total > data.page * ITEMS_PER_PAGE) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    containerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      containerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [fetching, data]);
  const withdrawIsTooLow = total < 2;

  return (
    <>
      <BackButton goBack={goBack} />
      <Row className="mt-0 gap-3 gap-md-0">
        <Col xs={0} md={4}></Col>
        <Col xs={12} md={4}>
          <div className="text-center">
            {t("total")}
            <span className="text-golden fw-bold">{`: ${total}$ (${
              total * COINS_PER_DOLLAR
            }) coins`}</span>
            {withdrawIsTooLow && selectedSkins?.length > 0 && (
              <span className="text-red">. {t("minWithdrawSkins")}</span>
            )}
          </div>
        </Col>

        <Col xs={12} md={4}>
          <Balance balance={balance} />
        </Col>

        <div className="bg-secondary rounded-md  my-2 py-2">
          <Row className="filter-wrapper justify-content-between py-1">
            <Col xs={2} md={2}>
              <div className="d-flex justify-content-start md-justify-content-center h-100 gap-3 align-items-center">
                <Button
                  onClick={() => setGame("csgo")}
                  className="p-0"
                  variant="bg-transparent"
                >
                  <img
                    className={`rounded-full transition-all border  border-3 ${
                      game === "csgo"
                        ? " rounded-full border-primary  "
                        : " border-secondary"
                    }`}
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                    src="/images/csgo.png"
                  />
                </Button>
                <Button
                  onClick={() => setGame("rust")}
                  className="p-0"
                  variant="bg-transparent"
                >
                  <img
                    className={`rounded-full transition-all border  border-3 ${
                      game === "rust"
                        ? " rounded-full border-primary  "
                        : " border-secondary"
                    }`}
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                    src="/images/rust.jpg"
                  />
                </Button>
              </div>
            </Col>

            <Col xs={8} md={4} lg={4} xl={6}>
              <CustomInput
                rootClass="flex-1"
                name="search"
                type="text"
                className="search-skins"
                placeholder={t("searchPlaceholder")}
                onChange={(e) => debouncedSearch(e)}
              />
            </Col>

            <Col xs={12} md={6} lg={6} xl={4}>
              <div className="d-flex gap-3 mt-3 mt-md-0 align-items-center justify-content-end md-justify-content-end">
                <CustomInput
                  name="min"
                  type="number"
                  placeholder={t("min")}
                  onChange={(e) => setMinPrice(Number(e) || 0)}
                />
                -
                <CustomInput
                  name="max"
                  type="number"
                  placeholder={t("max")}
                  onChange={(e) => setMaxPrice(Number(e) || 999999)}
                />
                <Button
                  variant="secondary"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                  className="dropdown-default"
                  id="sort-button"
                >
                  {t(sortOrder)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="skins-wrapper pb-2" ref={containerRef}>
          {data?.data?.map((skin, index) => (
            <Col className="mt-4" key={index} xs={6} sm md lg={2}>
              <SkinItem
                float={skin.float}
                name={skin.name}
                image={skin.image}
                price={skin.price}
                id={skin.id}
                onClick={() => handleSkinClick(skin)}
                isSelected={selectedSkins.some((s) => s.id === skin.id)}
              />
            </Col>
          ))}
        </Row>
      </Row>

      <div className="d-flex px-4 mt-5 gap-5   justify-content-end">
        <div>
          <Button
            className="text-uppercase "
            onClick={handleWithdraw}
            disabled={
              fetching || selectedSkins.length === 0 || withdrawIsTooLow
            } // Disable if withdraw pending or no skins selected
          >
            {fetching ? (
              t("fetching")
            ) : (
              <>
                {t("withdraw")} {selectedSkins.length} {t("skins")}
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SkinsWithdrawModal;
