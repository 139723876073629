import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import "./Menu.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import GameModal from "../../Modals/GameModal";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/slices/modalSlice";
import WithdrawModal from "../../Modals/WithdrawModal/index";
import { CloseFooter } from "../../Modals";
import DepositModal from "../../Modals/DepositModal/DepositModal";

function Menu() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleGamesButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="header-button-quarter">
          <Button
            onClick={() =>
              dispatch(
                openModal({
                  modalHeader: t("deposit"),
                  modalContent: <DepositModal />,
                  modalFooter: <CloseFooter />,
                }),
              )
            }
            id="btn1"
            type="button"
            variant="success"
          >
            <span className="header-icos">
              <i className="bi bi-briefcase-fill"></i>
            </span>
          </Button>
          <Tooltip
            anchorSelect="#btn1"
            place="bottom"
            className="tooltip-button"
          >
            {t("deposit")}
          </Tooltip>
        </div>
        <div className="header-button-quarter">
          <Button
            id="btn2"
            onClick={() =>
              dispatch(
                openModal({
                  modalHeader: t("withdraw"),
                  modalContent: <WithdrawModal />,
                  modalFooter: <CloseFooter />,
                }),
              )
            }
            className="btn  btn-default"
            type="button"
            variant="secondary"
          >
            <span className="header-icos">
              <i className="bi bi-basket-fill"></i>
            </span>
          </Button>
          <Tooltip
            anchorSelect="#btn2"
            place="bottom"
            className="tooltip-button"
          >
            {t("withdraw")}
          </Tooltip>
        </div>
        <div className="header-button-quarter">
          <Button
            id="btn3"
            className="btn  btn-default"
            type="button"
            onClick={() =>
              dispatch(
                openModal({
                  activeTab: "affiliate",
                }),
              )
            }
            variant="secondary"
          >
            <span className="header-icos">
              <i className="bi bi-gift-fill"></i>
            </span>
          </Button>
          <Tooltip
            anchorSelect="#btn3"
            place="bottom"
            className="tooltip-button"
          >
            {t("freeCoins")}
          </Tooltip>
        </div>
        <div className="header-button-quarter">
          <Button
            key={showModal ? "blt-2" : "nx-4"} // stupid but it works, now the tooltip does not randomly reopon after modal close
            id={!showModal ? "btn4" : "btn-hide"}
            className="btn "
            type="button"
            onClick={handleGamesButtonClick}
            variant="primary"
          >
            <span className="header-icos">
              <i className="bi bi-controller"></i>
            </span>
          </Button>

          <Tooltip
            globalCloseEvents={{ scroll: true, resize: true, escape: true }}
            closeEvents={{ mouseleave: true, blur: true, click: true }}
            anchorSelect="#btn4"
            place="bottom"
            className="tooltip-button"
          >
            {t("games")}
          </Tooltip>
        </div>
      </div>

      {showModal && <GameModal open onClose={handleCloseModal} />}
    </div>
  );
}

export default Menu;
