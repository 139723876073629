import { Tab, Tabs } from "react-bootstrap";

import Heores from "../Heroes";
import "./index.css";
import Chat from "../../Chat";

import OnlineCount from "../../UI/OnlineCount";
import { useState } from "react";

function HallOfHeroes() {
  const [selectedTab, setSelectedTab] = useState<string>("heroes");

  const handleTabSelect = (tabKey: string | null) => {
    tabKey !== "online" && setSelectedTab(String(tabKey));
  };
  return (
    <div className=" rounded-md h-100 ">
      <Tabs
        defaultActiveKey="heroes"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
        className=" full-tabs pb-3 pt-3 border-b-primary gap-3 px-3 rounded-top  justify-content-between"
      >
        <Tab eventKey="heroes" title="Heroes">
          <Heores />
        </Tab>
        <Tab className="h-100" eventKey="chat" title="Chat">
          <Chat />
        </Tab>
        <Tab
          className="count-tab"
          role="div"
          tabClassName="cursor-default"
          eventKey="online"
          title={<OnlineCount />}
        ></Tab>
      </Tabs>
    </div>
  );
}

export default HallOfHeroes;
