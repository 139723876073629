import React, { useState, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import socket from "../../../websockets/socket";
import { toast } from "react-toastify";
import "./index.css";

interface ChatInputProps {
  lightInput?: boolean;
  onSend?: () => void;
}
interface ChatPayload {
  token: string | null;
  action: string;
  steamid?: string;
  time?: string;
  message?: string;
}
function ChatInput({ lightInput, onSend }: ChatInputProps) {
  const { t } = useTranslation();
  const { token } = useSelector(selectUser);
  const [message, setMessage] = useState("");
  const [fetching, setFetching] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [fetching]);

  const handleSend = async () => {
    setFetching(true);
    try {
      if (message.trim() !== "") {
        const s = message.trim().split(" ");
        const command = s[0];
        const steamid = s[1];
        const time = s[2];

        let action = "message";

        let payload: ChatPayload = { token, action };

        switch (command) {
          case "/mute":
            action = "mute";
            payload = { ...payload, action, steamid, time };
            break;
          case "/unmute":
            action = "unmute";
            payload = { ...payload, action, steamid };
            break;
          case "/ban":
            action = "ban";
            payload = { ...payload, action, steamid };
            break;
          case "/unban":
            action = "unban";
            payload = { ...payload, action, steamid };
            break;
          case "/clear":
            action = "clear";
            payload = { ...payload, action };
            break;
          default:
            payload = { ...payload, action, message };
        }

        await socket.emit("chat", payload);
        setMessage("");
        onSend && onSend();
      } else {
        toast.error("Message cannot be empty");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("An error occurred while sending the message");
    } finally {
      setFetching(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="d-flex flex-row chat-div">
      <Form.Group controlId="chat-field" className="chat-field w-100">
        <Form.Control
          value={message}
          disabled={fetching || !token}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={t(token ? "yourMessage" : "loginToChat")}
          className={"chat-input" + (lightInput ? " light-input" : "")}
          type="text"
          ref={inputRef}
        />
      </Form.Group>
      <Button
        disabled={fetching || !token}
        onClick={handleSend}
        className="chat-btn"
        variant="secondary"
      >
        {t("send")}
      </Button>
    </div>
  );
}

export default ChatInput;
