import { useTranslation } from "react-i18next";

const TosPage = () => {
  const { t } = useTranslation();
  return (
    <div className="purple-gradient  text-justify inner-page-container mb-5">
      <div className="container py-5">
        <h4 className="text-uppercase text-center text-golden">
          {t("termsOfService")}
        </h4>
        <hr></hr>
        <p>
          Intech Operations (registration number 306709958), (“Company”,
          “csgotower”, “we”, “our”, or “us”), is the proprietor and operator of
          the website csgotower.com (the “Site”). Through its associated
          products and services (collectively, such products and services,
          including any new features and applications, and the Site, referred to
          as the “Service(s)”), the following Terms of Service (which may be
          updated from time to time, hereafter the “Terms of Service”) apply. We
          reserve the right to amend these Terms of Service at our discretion.
          Changes will be announced on this page and the date of the latest
          revision will be indicated. Changes become effective no sooner than
          fourteen (14) days after they are posted, except for changes related
          to new service functions or changes for legal reasons, which are
          effective immediately. Continued use of the Service after changes
          signifies acceptance of the revised Terms of Service.
        </p>

        <p>
          Please read these Terms of Service carefully. They include an
          agreement to arbitrate and important information about your legal
          rights, remedies, and obligations. This arbitration agreement requires
          (with limited exceptions) that disputes between you and us be resolved
          through binding and final arbitration. Additionally, (1) claims
          against the Company must be brought on an individual basis, not as a
          plaintiff or className member in any className or representative
          action or proceeding, (2) relief (including monetary, injunctive, and
          declaratory relief) must be sought on an individual basis, and (3)
          claims may not be resolved by a jury or in a court of law.
        </p>

        <p>
          Additional terms applicable to specific services may be posted on the
          Service from time to time, including, but not limited to, our Privacy
          Policy located at <span>csgotower.com/privacy</span>. All such terms
          are incorporated into these Terms of Service.
        </p>

        <h5 className="fw-bold">Acknowledgment</h5>
        <p>
          These Terms and Conditions govern the use of all Services on
          csgotower.com and constitute the agreement between You and the
          Company. They set out the rights and obligations of all users
          regarding the use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your
          acceptance of and compliance with these Terms and Conditions. These
          Terms and Conditions apply to all users, visitors, and others who
          access the Service. Use of the Service without acceptance of these
          Terms is not permitted.
        </p>
        <p>
          By accessing or using the Service, You agree to be bound by these
          Terms and Conditions. If You disagree with any part of these Terms and
          Conditions, You may not access the Service. Access to and use of
          csgotower.com and the Services also depends on Your acceptance of and
          compliance with our Privacy Policy and our AML-KYC Policy, which
          describe our procedures on personal information collection, use, and
          disclosure, and our anti-money laundering policies, respectively.
          Please review these policies carefully.
        </p>
        <p>
          You may not create an account on csgotower.com if you are a resident
          of certain countries or regions, including but not limited to Iran,
          Iraq, Jordan, Afghanistan, Kuwait, North Korea, Pakistan, Yemen,
          Syria, and certain states in the USA (e.g., New York, Washington), or
          any other region that prohibits Steam Trades and games involving items
          of value.
        </p>

        <h5 className="fw-bold">Grant of License</h5>

        <p>
          We grant You a non-exclusive, personal, non-transferable right to use
          Our Service on any device able to connect the internet in your
          possession. All Games and Services will be only available online on
          Our Website. We may terminate your account at any point if you
          transfer your personal account to another individual, or company, or
          other legal entity. The Service is only for Adults, therefore Minors
          below the age of 18 are not permitted to access the Website or use any
          Services on the Website. We do not allow the consumption of Our
          Services below the age of 18, even if these Services are allowed under
          Your jurisdiction or by your Legal Guardians.
        </p>

        <p>
          You shall notify Us without delay upon becoming aware that an
          unauthorized person (every person, except the account owner, is
          unauthorized), is or was on your account and make sure that this shall
          not happen again. If no notice is sent right away, We shall see it as
          a violation of our Terms of Service and may terminate your Account
          without notice or delay.
        </p>

        <h5 className="fw-bold">General provisions</h5>

        <p>
          This text is a public agreement (offer) between the commercial party
          (us) and the end user of the Service (you). The terms of this
          agreement are the same and binding on all users of the Service. In
          accordance with generally accepted terminology, this agreement is
          considered a license agreement between the owner of the software
          product and the end user.
        </p>

        <p>
          The Company provides the User with access to the Service and the right
          to use it in accordance with this agreement solely for personal use
          and solely for entertainment purposes. The company allows the use of
          the Service only to individuals.
        </p>

        <p>
          Acceptance and implementation of this offer is a prerequisite for
          using the Service. A user who does not agree with these terms and
          conditions, does not accept them and refuses to comply with them, does
          not have the right to use the Service and is obliged to leave this
          website.
        </p>

        <p>
          If you do not have full legal capacity under the laws of your country,
          leave this site immediately. The Сompany denies you the possibility of
          using this Service.
        </p>

        <p>
          If you suffer from addiction to games, we urge you not to play! You
          can’t miss school or work because of games, you can’t borrow money or
          sell property for the sake of games, you can’t spend your last money
          and the idea of committing suicide because of the game is certainly
          absurd. Please turn to a psychologist you trust - you can find help.
          You can also find help by clicking on these links:
          https://www.begambleaware.org, http://www.psychguides.com,
          http://www.gamblersanonymous.org/ga/.
        </p>

        <p>
          The company is not responsible for death or personal injury caused by
          the player to himself or to third parties as a result of loss or in
          the state of affect.
        </p>

        <p>
          This offer must be read and understood by you completely before using
          the Service. If you do not understand anything in this text, please
          contact our support team before using the Service. This offer affects
          your legal rights and obligations. Misunderstanding does not exempt
          from legal liability.
        </p>

        <p>
          These terms and conditions govern the access and use of the Service,
          including any content, functionality and services offered by the
          Service. By clicking the “Agree” button and / or using the Service as
          a guest and / or registered user, you unconditionally agree to the
          terms and conditions set forth in this offer. These terms and
          conditions come into force as soon as you visit the site (get access
          to the Service).
        </p>

        <h5 className="fw-bold">Warranty and liability of the Company</h5>

        <p>
          The company presents the Service to the user on an “as is” and “as
          available” basis - with all the properties, advantages and
          disadvantages that exist or may arise. This means that the Company
          disclaims any responsibility to the user for the quality and
          properties of the Service, and does not provide any guarantees. Use of
          the Service is associated with the risk of financial loss. The user
          participates in the games at his own risk.
        </p>

        <p>
          Regardless of the situation and our efforts, we do not guarantee that
          customer service will be uninterrupted, timely and error-free, or that
          defects will be corrected.
        </p>

        <p>
          From time to time we may need to update, reset, temporarily interrupt
          or shut down some or all parts of the Service. Any of these actions
          may cause you to lose access to the Service. We shall have no
          liability to you if the Service or any aspect of it are interrupted or
          unavailable for any reason.
        </p>

        <p>
          The Company reserves the absolute and unlimited right in its sole
          discretion, at any time, without explanation, without prior notice and
          without liability to you:
        </p>

        <ul>
          <li>
            to amend this offer and to revise any provisions of these terms and
            conditions. All changes take effect from the moment they are
            published on the Service website;
          </li>
          <li>deny access to the Service;</li>
          <li>
            to temporarily block and / or completely delete the Account,
            including denying the User access to the Service;
          </li>
          <li>
            request any KYC documentation, restrict access and payments to the
            moment of sufficient identification;
          </li>
          <li>
            terminate any component of the Service or the Service as a whole.
          </li>
        </ul>
        <h5 className="fw-bold">Notifications and Changes</h5>

        <p>
          Notifications of all changes to this offer and Service components are
          published by the Company on the Service website. We do not
          individually notify users of changes. The user (you) should
          independently familiarize themselves with all updates. The validity of
          the changes does not depend on the knowledge of the User.
        </p>

        <p>
          The user (you), in case of disagreement with changes to these terms
          and conditions, should independently stop using the Service. User
          claims about disagreement with changes to these terms and conditions
          are not accepted by the Company. Also, the consequences of such
          changes cannot be the basis of the user&apos;s claims.
        </p>

        <p>
          Changes to this offer at the initiative of the user is not provided.
          This offer expires upon deletion of the user account.
        </p>

        <p>
          The user can refuse this offer without consequences and obligations
          only until the first of these events: the first deposit on the balance
          and / or the first game.
        </p>

        <h5 className="fw-bold">User. Player.</h5>

        <p>
          The end user of the Service is a Player. All information about the
          user needed to identify the Player, to access and use the Service, as
          well as information about all the Player’s activity on the Service’s
          website, is stored in the Account, which is created by the Service at
          the time of the first activity (registration) of the User.
        </p>

        <p>
          The User (you) undertakes, among other things, to provide the Company
          and / or the Service with reliable information about himself. All
          risks and responsibility for the consequences of providing false
          information lies with the user (you).
        </p>

        <p>
          The issues of protecting personal data of the parties to this
          agreement are regulated separately - by the Privacy Policy document.
        </p>

        <p>
          We use cookies. Cookies are governed separately by the Cookie Policy.
        </p>

        <p>
          The User has the right to be forgotten - at his request, the Service
          deletes the Account and all the information that it contains about the
          User.
        </p>

        <p>
          The service has the right to display information about large winnings
          and, accordingly, winners, for advertising purposes. For this, the
          consent of the winner is not required and no fee is due to the winner.
        </p>

        <h5 className="fw-bold">Financial relations</h5>

        <p>
          The service is not a banking institution (a place of storage,
          accumulation and savings of your deposits, as well as a money transfer
          service). The players ’operational funds required to participate in
          the game process are stored in a safe place, however, we do not
          recommend storing funds in the Account for a long time.
        </p>

        <p>
          Replenishment and refund of funds from the User Account is carried out
          through the agent for receiving payments (payment system), which is
          not managed by the Company. Relations between the Player and the
          payment system are not governed by this offer. The company does not
          accept claims of the Player on issues related to the activities of the
          payment system.
        </p>

        <p>
          The company makes efforts to timely process transactions for all users
          of the Service that comply with these Terms of Service and KYC / AML.
        </p>

        <p>
          Any crosschain Deposits (sending, for example, Bitcoin Cash to a
          Bitcoin address) will not be processed and coins will be permanently
          lost.
        </p>

        <p>
          In some cases, manual transaction processing is required, which takes
          considerable time - sometimes several days. You should be treat this
          with understanding.
        </p>

        <p>
          In case of closing the Account, the balance will be returned to you.
          If there is reason to claim that the funds of the Account were
          illegally received by you, these funds will be debited and not
          payable.
        </p>

        <p>
          We will not conduct any operations or in any way participate in
          transactions in any authorized countries of OFAC (United States
          Foreign Assets Control Office).
        </p>

        <p>
          Among other things, the Player (s) agrees to be solely responsible
          for:
        </p>

        <ul>
          <li>accidentally committed actions or decisions;</li>
          <li>losses due to a malfunction of devices used by the Player;</li>
          <li>
            losses of the Player due to transfer of his funds to other Players,
            granting them privileges or a loan;
          </li>
          <li>
            expenses incurred by the Player to access the Service (including
            Internet charges);
          </li>
          <li>
            for any other losses, given that the Service is provided on an
            &quot;as is&quot; basis without any guarantees from the Company.
          </li>
        </ul>

        <p>
          The result of the gameplay depends on chance. We adhere to an
          acceptable measure in the formation of game algorithms, therefore, we
          consider the game process to be honest. Any of your theories regarding
          dishonesty in the game process cannot be the basis for canceling the
          result of the game. Also, your life circumstances, your illness or
          stay under the influence of any drugs cannot be the basis for
          canceling the result of the game.
        </p>

        <p>
          Algorithms and rules for specific games of the Service are set forth
          in sufficient volume for full participation in the game process, on
          the Service website in the section of such a game. If you don’t
          understand anything, please contact our support team before placing a
          bet.
        </p>

        <p>
          The Company is not a tax agent in relation to the Player and is not
          liable for him financial obligations. Everyone independently
          calculates and pays taxes due from him. Everyone independently submits
          the proper tax reporting.
        </p>

        <p>
          The service does not provide services for the exchange of financial
          assets of various nature.
        </p>

        <h5 className="fw-bold">Prohibitions and restrictions</h5>

        <p>It is forbidden to use the Service to persons:</p>

        <ul>
          <li>blocked on any other gaming site or game object;</li>
          <li>included in the restricted list of any online games operator;</li>
          <li>
            in respect of which there is a prohibition of participation in games
            imposed by the state;
          </li>
          <li>
            under the age of majority, as well as by a person with respect to
            whom the state has limited legal capacity;
          </li>
          <li>
            who has not accepted or does not agree with the provisions of this
            offer (both fully and partially);
          </li>
          <li>
            who grossly or systematically violates the requirements of this
            offer and the generally accepted principles of online games ethics;
          </li>
          <li>who has officially diagnose &quot;addiction to games&quot;;</li>
          <li>
            who provided the Service with false information (until the situation
            is rectified).
          </li>
        </ul>

        <p>
          If in relation to you there is any of these circumstances arose, stop
          using the Service and inform us.
        </p>

        <p>
          The company, in addition to other conditions of this offer, expressly
          prohibits:
        </p>

        <ul>
          <li>
            use of the Service as an end user to legal entities (commercial and
            non-profit organizations);
          </li>
          <li>
            use the Service for any other purpose than the personal
            entertainment of the Player.
          </li>
        </ul>

        <p>
          Copying or reproduction of the Service or its components, including
          trademark, in any way is prohibited. Unauthorized access to the
          database, site and components of the Service, as well as copying,
          imitation and use of a trademark, is prohibited. Persons noticed in
          such actions will be held criminally liable and are obliged to
          compensate the Company for the damage caused.
        </p>

        <p>
          It is forbidden to use the information obtained during the use of the
          Service to cause reputational damage to the Company. Persons
          disseminating fraudulent information are prosecuted by law.
        </p>

        <p>
          It is forbidden to use offensive words and expressions in relation to
          the Players, the Service, the Company and its employees. You will be
          banned for insults, and the account funds will be irrevocably written
          off.
        </p>

        <p>
          In addition to the well-known, we consider and prohibit such actions
          on our website as clearly fraudulent:
        </p>

        <ul>
          <li>
            the player&apos;s use of multi-accounts to circumvent the
            restrictions established by this offer and the rules of the
            affiliate program;
          </li>
          <li>provide access to your account to third parties;</li>
          <li>use of automatic scripts;</li>
          <li>use any cryptocurrency vulnerability for personal gain;</li>
          <li>ask someone for money, both on credit and free of charge;</li>
          <li>create duplicate accounts using referral links;</li>
          <li>
            offer bonuses to users of the Service (or other persons) for the use
            of their affiliate promo code;
          </li>
          <li>
            in communication with players (or other persons) willfully represent
            themselves as an employee of the Company / Service;
          </li>
          <li>
            distribute spam, unauthorized advertising, as well as offensive and
            / or false information.
          </li>
        </ul>
        <h5 className="fw-bold">Third-Party Intellectual Property Rights</h5>

        <p>
          Steam® is a registered trademark of Valve Corporation. Intech
          Operations is neither endorsed by nor affiliated with Valve
          Corporation, Counter-Strike: Global Offensive, Steam®, or any other
          trademarks of Valve Corporation.
        </p>

        <p>
          Valve, the Valve logo, Half-Life, the Half-Life logo, the Lambda logo,
          Steam, the Steam logo, Team Fortress, the Team Fortress logo, Opposing
          Force, Day of Defeat, the Day of Defeat logo, Counter-Strike, the
          Counter-Strike logo, Source, the Source logo, Counter-Strike:
          Condition Zero, Portal, the Portal logo, Dota, the Dota 2 logo, and
          Defense of the Ancients are all trademarks or registered trademarks of
          Valve Corporation. All other trademarks belong to their respective
          owners.
        </p>

        <p>
          By using our Service, you agree that the terms of the Steam®
          Subscriber Agreement apply to you in all respects, including any terms
          and conditions, limitations on transferring or exchanging items, and
          any warranties or obligations related to your Steam account and Valve
          Corporation. These terms are in addition to, and not a replacement
          for, the terms of this Agreement.
        </p>

        <p>
          Intech Operations reserves the right to investigate complaints,
          suspected violations of these Terms of Service, and potential legal
          violations. We may take appropriate actions, including reporting
          suspected unlawful activities to law enforcement and disclosing user
          information as necessary. Any prohibited content or potential
          violations encountered on the Services should be immediately reported
          to csgotower.com@gmail.com. We may suspend or prohibit future use of
          the Website for users violating these Terms of Service or engaging in
          illegal activities.
        </p>
        <h5 className="fw-bold">Affiliates Program</h5>
        <p>
          Intech Operations reserves the right to modify the ownership of
          affiliate codes if necessary (e.g., to prevent namesquatting).
        </p>
        <p>
          We also reserve the right to confiscate any earnings from the
          affiliate program if deemed appropriate according to the
          program&apos;s terms and conditions.
        </p>

        <h5 className="fw-bold">
          Purpose of Website, Fees and Payments to Company
        </h5>
        <p>
          The Website is provided for your entertainment purposes only. The
          Website provides a method to spend real money in order to obtain only
          virtual items. The sole purpose of the virtual items or money is to
          play the games and/or access features on the Website. The virtual
          items or money have no value. You are using real money to purchase
          virtual items whose sole purpose is for playing the games on the
          Website.
        </p>
        <p>
          We do not and will not provide any means for you to convert your
          virtual items or site balance back to real money. Once you submit real
          money via the Website to purchase virtual items or increase your site
          balance, you cannot ever retrieve that real money.
        </p>
        <p>
          Any and all funds submitted for use on the Website (i.e. via the
          csgotower.com wallet) will be converted/redeemed to provide you with
          an equivalent “value” in your balance on the Website. However, the
          sole use of the balance on the Website is for use on the Website. The
          balance does not represent real money and there is no method to “cash
          out” your virtual items from the Website, withdraw your site balance,
          demand a refund, or convert your Website balance or virtual items back
          to real dollars. You are purchasing a right to play on our Website
          using your site balance (depicted in dollars for ease of reference
          only) but those funds and your virtual items cannot be converted or
          refunded back to real dollars.
        </p>
        <p>
          By providing Company with a payment method, you (i) represent that you
          are authorized to use the payment method that you provided and that
          any payment information you provide is true and accurate; (ii)
          authorize Company to charge you for the virtual items or services
          purchased using your payment method; and (iii) authorize Company to
          charge you for any paid virtual items or services that you choose to
          sign up for or use while this agreement is in force. We may bill you
          at the time of purchase or shortly after purchase. Also, we may charge
          you or place a hold on your account (e.g. a pending authorization
          hold) up to the amount you&apos;ve approved.
        </p>
        <p>
          When you use a third-party payment service (e.g. GameMoney or PayPal®
          services) to make a purchase on our website, responsibility over your
          purchase will first be transferred to such third party before it is
          delivered to you. The third-party services provider assumes primary
          responsibility, with our assistance, for payment and payment related
          customer support. The terms between the third-party services provider
          and customers who utilize services of the third party are governed by
          separate agreements and are not subject to the Terms of Service on
          this website.
        </p>

        <p>
          For customer service inquiries or disputes, you may contact us by
          email at csgotower.com@gmail.com.
        </p>
        <h5 className="fw-bold">Affiliates Program</h5>
        <p>
          Intech Operations reserves the right to modify the ownership of
          affiliate codes if necessary (e.g., to prevent namesquatting).
        </p>
        <p>
          We also reserve the right to confiscate any earnings from the
          affiliate program if deemed appropriate according to the
          program&apos;s terms and conditions.
        </p>

        <h5 className="fw-bold">
          Purpose of Website, Fees and Payments to Company
        </h5>
        <p>
          The Website is provided for your entertainment purposes only. The
          Website provides a method to spend real money in order to obtain only
          virtual items. The sole purpose of the virtual items or money is to
          play the games and/or access features on the Website. The virtual
          items or money have no value. You are using real money to purchase
          virtual items whose sole purpose is for playing the games on the
          Website.
        </p>
        <p>
          We do not and will not provide any means for you to convert your
          virtual items or site balance back to real money. Once you submit real
          money via the Website to purchase virtual items or increase your site
          balance, you cannot ever retrieve that real money.
        </p>
        <p>
          Any and all funds submitted for use on the Website (i.e. via the
          csgotower.com wallet) will be converted/redeemed to provide you with
          an equivalent “value” in your balance on the Website. However, the
          sole use of the balance on the Website is for use on the Website. The
          balance does not represent real money and there is no method to “cash
          out” your virtual items from the Website, withdraw your site balance,
          demand a refund, or convert your Website balance or virtual items back
          to real dollars. You are purchasing a right to play on our Website
          using your site balance (depicted in dollars for ease of reference
          only) but those funds and your virtual items cannot be converted or
          refunded back to real dollars.
        </p>
        <p>
          By providing Company with a payment method, you (i) represent that you
          are authorized to use the payment method that you provided and that
          any payment information you provide is true and accurate; (ii)
          authorize Company to charge you for the virtual items or services
          purchased using your payment method; and (iii) authorize Company to
          charge you for any paid virtual items or services that you choose to
          sign up for or use while this agreement is in force. We may bill you
          at the time of purchase or shortly after purchase. Also, we may charge
          you or place a hold on your account (e.g. a pending authorization
          hold) up to the amount you&apos;ve approved.
        </p>
        <p>
          When you use a third-party payment service (e.g. GameMoney or PayPal®
          services) to make a purchase on our website, responsibility over your
          purchase will first be transferred to such third party before it is
          delivered to you. The third-party services provider assumes primary
          responsibility, with our assistance, for payment and payment related
          customer support. The terms between the third-party services provider
          and customers who utilize services of the third party are governed by
          separate agreements and are not subject to the Terms of Service on
          this website.
        </p>

        <p>
          For customer service inquiries or disputes, you may contact us by
          email at csgotower.com@gmail.com
        </p>
        <h5 className="fw-bold">Authentication and Usage Issues</h5>
        <p>
          <strong>User Interface errors</strong> - Interface glitches and/or
          manipulation of the Website does not entitle you or provide with any
          rights to a certain item. All outcomes on the Website are generated by
          our provably fair system and are subject to such verification
          (including reversal of an outcome previously determined). You hereby
          agree that the outcome will be determined by the provably fair system
          outcome and any user interface errors do not entitle the user to an
          item.
        </p>
        <p>
          <strong>Bots and Steam® API</strong> - The trade offer request sent
          by our bots are subject to Steam Guard authentication and the buyer
          must have Steam Guard activated for at least 15 days on their
          smartphone so the trade can be completed.
        </p>
        <p>
          Please note that this is a Steam® restriction. The items in the trade
          request will not expire but you will need to wait for the end of the
          15-day period.
        </p>

        <h5 className="fw-bold">User Contributions</h5>
        <p>
          The Website may contain chat message boards, profiles, forums, product
          names (custom cases), user names/pictures and other interactive
          features (collectively, "Interactive Services") that allow users to
          post, submit, publish, display or transmit to other users or other
          persons (hereinafter, "post") content or materials (collectively,
          "User Contributions") on or through the Website. All User
          Contributions must comply with the Content Standards set out in these
          Terms of Use. Any User Contribution you post to the site will be
          considered non-confidential and non-proprietary. By providing any User
          Contribution on the Website, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors and assigns the right to use, reproduce, modify, perform,
          display, distribute and otherwise disclose to third parties any such
          material for any purpose/according to your account settings.
        </p>
        <p>You represent and warrant that:</p>
        <ul>
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and our
            affiliates and service providers, and each of their and our
            respective licensees, successors and assigns.
          </li>
          <li>
            All of your User Contributions do and will comply with these Terms
            of Use.
          </li>
        </ul>
        <p>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          fully responsibility for such content, including its legality,
          reliability, accuracy and appropriateness. We are not responsible, or
          liable to any third party, for the content or accuracy of any User
          Contributions posted by you or any other user of the Website.
        </p>

        <h5 className="fw-bold">Monitoring and Enforcement; Termination</h5>
        <p>We have the right to:</p>
        <ul>
          <li>
            Remove or refuse to post any User Contributions for any or no reason
            in our sole discretion.
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Contribution violates the Terms of Use,
            including the Content Standards, infringes any intellectual property
            right or other right of any person or entity, threatens the personal
            safety of users of the Website or the public or could create
            liability for the Company.
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party who claims that material posted by you violates their rights,
            including their intellectual property rights or their right to
            privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Website.
          </li>
          <li>
            Terminate or suspend your access to all or part of the Website for
            any or no reason, including without limitation, any violation of
            these Terms of Use.
          </li>
        </ul>
        <p>
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
          COMPANY OR ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
          INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
          INVESTIGATIONS BY EITHER THE COMPANY, SUCH PARTIES, OR LAW ENFORCEMENT
          AUTHORITIES. However, we do not undertake to review material before it
          is posted on the Website, and cannot ensure prompt removal of
          objectionable material after it has been posted. Accordingly, we
          assume no liability for any action or inaction regarding
          transmissions, communications or content provided by any user or third
          party. We have no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this
          section.
        </p>

        <h5 className="fw-bold">Content Standards</h5>
        <p>
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must in their entirety
          comply with all applicable federal, state, local and international
          laws and regulations. Without limiting the foregoing, User
          Contributions must not:
        </p>
        <ul>
          <li>
            Contain any material which is defamatory, obscene, indecent,
            abusive, offensive, harassing, violent, hateful, inflammatory or
            otherwise objectionable.
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>
            Infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms of Use and our
            Privacy Policy, found at <span>csgotower.com/privacy</span>.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Promote any illegal activity, or advocate, promote or assist any
            unlawful act.
          </li>
          <li>
            Cause annoyance, inconvenience or needless anxiety or be likely to
            upset, embarrass, alarm or annoy any other person.
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization.
          </li>
          <li>
            Involve commercial activities or sales, such as contests,
            sweepstakes and other sales promotions, barter or advertising.
          </li>
          <li>
            Give the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.
          </li>
        </ul>
        <h5 className="fw-bold">Copyright Infringement</h5>
        <p>
          If you believe that any User Contributions violate your copyright,
          please see the instructions found in the section titled “Digital
          Millennium Copyright Act (DMCA)” on sending us a notice of copyright
          infringement. It is the policy of the Company to terminate the user
          accounts of repeat infringers.
        </p>

        <h5 className="fw-bold">Reliance on Information Posted</h5>
        <p>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>
        <p>
          This Website may include content provided by third parties, including
          materials provided by other third-party licensors, syndicators,
          aggregators and/or reporting services. All statements and/or opinions
          expressed in these materials, and all articles and responses to
          questions and other content, other than the content provided by the
          Company, are solely the opinions and the responsibility of the person
          or entity providing those materials. These materials do not
          necessarily reflect the opinion of the Company. We are not
          responsible, or liable to you or any third party, for the content or
          accuracy of any materials provided by any third parties.
        </p>

        <h5 className="fw-bold">Changes to the Website</h5>
        <p>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>

        <h5 className="fw-bold">
          Information About You and Your Visits to the Website
        </h5>
        <p>
          All information we collect on this Website is subject to our Privacy
          Policy found at <span>csgotower.com/privacy</span>. By using the
          Website, you consent to all actions taken by us with respect to your
          information in compliance with the Privacy Policy.
        </p>

        <h5 className="fw-bold">
          Linking to the Website and Social Media Features
        </h5>
        <p>
          You may link to our homepage, provided you do so in a way that is fair
          and legal and does not damage our reputation or take advantage of it,
          but you must not establish a link in such a way as to suggest any form
          of association, approval or endorsement on our part without our
          express written consent.
        </p>
        <p>
          This Website may provide certain social media features that enable you
          to:
        </p>
        <ul>
          <li>
            Link from your own or certain third-party websites to certain
            content on this Website.
          </li>
          <li>
            Send e-mails or other communications with certain content, or links
            to certain content, on this Website.
          </li>
          <li>
            Cause limited portions of content on this Website to be displayed or
            appear to be displayed on your own or certain third-party websites.
          </li>
        </ul>
        <p>
          You may use these features solely as they are provided by us, solely
          with respect to the content they are displayed with, and otherwise in
          accordance with any additional terms and conditions we provide with
          respect to such features. Subject to the foregoing, you must not:
        </p>
        <ul>
          <li>Establish a link from any website that is not owned by you.</li>
          <li>
            Cause the Website or portions of it to be displayed, or appear to be
            displayed by, for example, framing, deep linking or in-line linking,
            on any other site.
          </li>
          <li>Link to any part of the Website other than the homepage.</li>
          <li>
            Otherwise take any action with respect to the materials on this
            Website that is inconsistent with any other provision of these Terms
            of Use.
          </li>
        </ul>
        <p>
          The website from which you are linking, or on which you make certain
          content accessible, must comply in all respects with the Content
          Standards set out in these Terms of Use. You agree to cooperate with
          us in causing any unauthorized framing or linking immediately to
          cease. We reserve the right to withdraw linking permission without
          notice. We may disable all or any social media features and any links
          at any time without notice in our discretion.
        </p>

        <h5 className="fw-bold">Links from the Website</h5>
        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites. You further acknowledge and
          agree that the Company shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with use of or reliance on any such content, goods or
          services available on or through any such web sites or services.
        </p>

        <h5 className="fw-bold">Threats against the Website</h5>
        <p>
          Threatening the website with attacks / malicious reports will result
          in your funds being confiscated and account closure.
        </p>

        <h5 className="fw-bold">Disclaimer of Warranties</h5>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET
          YOUR NEEDS OR EXPECTATIONS. THE COMPANY HEREBY DISCLAIMS ALL
          WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <h5 className="fw-bold">Limitation on Liability</h5>
        <p>
          IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
          SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE
          FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
          WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
          WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH
          OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
          PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
          REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
          LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
          TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
          FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT
          BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <h5 className="fw-bold">Indemnification</h5>
        <p>
          You agree to defend, indemnify and hold harmless the Company, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys' fees) arising out of
          or relating to your violation of these Terms of Use or your use of the
          Website, including, but not limited to, your User Contributions, any
          use of the Website's content, services and products other than as
          expressly authorized in these Terms of Use or your use of any
          information obtained from the Website.
        </p>
        <h5 className="fw-bold">Digital Millennium Copyright Act (DMCA)</h5>
        <p>
          <strong>DMCA Complaint Requirements.</strong> If you believe that your
          copyrighted work has been copied in a way that constitutes copyright
          infringement or that there is infringing material available through
          our Website, please notify our copyright agent, in accordance with the
          Digital Millennium Copyright Act of 1998 ("DMCA"). For your complaint
          to be valid under the DMCA, you must provide the following information
          in writing:
        </p>
        <ul>
          <li>
            An electronic or physical signature of a person authorized to act on
            behalf of the copyright owner;
          </li>
          <li>
            Specific identification of the copyrighted work that you claim has
            been infringed;
          </li>
          <li>
            Specific identification of the material that is claimed to be
            infringing and where it is located on the Website;
          </li>
          <li>
            Information reasonably sufficient to permit us to contact you, such
            as your address, telephone number, and email address;
          </li>
          <li>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or law;
          </li>
          <li>
            A statement, made under penalty of perjury, that the above
            information is accurate, and that you are the copyright owner or are
            authorized to act on behalf of the owner.
          </li>
        </ul>
        <p>
          <strong>DMCA Agent.</strong> The above information must be submitted
          to: <span>corp@ag.limited</span>.
        </p>

        <h5 className="fw-bold">
          Dispute Resolution by Arbitration / No Class Action
        </h5>
        <p>
          You understand and agree that all claims, disputes or controversies
          between you and Company, or its affiliates, or their respective
          directors, officers, employees, representatives and agents, arising
          under or related to this Agreement (including the Privacy Policy), the
          content or the use or operation of the Website, including tort and
          contract claims, claims based upon any federal, state or local
          statute, law, order, ordinance or regulation, and the issue of
          arbitrability, shall be resolved by final and binding arbitration
          before the American Arbitration Association (“AAA”), in accordance
          with the rules of the AAA.
        </p>
        <p>
          IT IS IMPORTANT THAT YOU READ THIS ARBITRATION CLAUSE. YOU UNDERSTAND
          THAT UNLESS YOU EXERCISE THE RIGHT TO OPT-OUT OF ARBITRATION IN THE
          MANNER DESCRIBED BELOW, YOU AGREE THAT ANY DISPUTE WILL BE RESOLVED BY
          BINDING ARBITRATION.
        </p>
        <p>
          <strong>RIGHT TO OPT OUT:</strong> If you do not wish to be bound by
          this arbitration clause, you must notify Company in writing within
          sixty (60) days after such time as you become bound by this Agreement
          as set forth above in the section titled “Acceptance of the Terms of
          Use”, or your rejection of arbitration will not be effective. Your
          notification must include your telephone number(s) and a clear
          statement of your intent to opt out, such as “I reject the Dispute
          Resolution by Arbitration/No Class Action clause stated in the Terms
          of Use Agreement.”
        </p>
        <p>
          ARBITRATION REPLACES THE RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO
          HAVE A JURY, TO ENGAGE IN DISCOVERY (EXCEPT AS MAY BE PROVIDED IN THE
          ARBITRATION RULES), AND TO PARTICIPATE IN A CLASS ACTION OR SIMILAR
          PROCEEDING. IN ARBITRATION, A DISPUTE IS RESOLVED BY AN ARBITRATOR
          INSTEAD OF A JUDGE OR JURY. YOU ALSO AGREE ANY ARBITRATION WILL BE
          LIMITED TO THE DISPUTE BETWEEN YOURSELF AND COMPANY AND WILL NOT BE
          PART OF A CLASS-WIDE OR CONSOLIDATED ARBITRATION PROCEEDING. ANY SUCH
          ARBITRATION PROCEEDING SHALL BE CONDUCTED IN BALTIMORE COUNTY,
          MARYLAND. THE SOLE AND EXCLUSIVE VENUE AND JURISDICTION WITH RESPECT
          TO ANY JUDICIAL ACTION ARISING UNDER OR RELATING IN ANY FASHION TO
          THIS AGREEMENT SHALL LIE IN THE STATE AND FEDERAL COURTS FOR BALTIMORE
          COUNTY, MARYLAND.
        </p>

        <h5 className="fw-bold">Limitation on Time to File Claims</h5>
        <p>
          SUBJECT TO THE ARBITRATION CLAUSE ABOVE, ANY CAUSE OF ACTION OR CLAIM
          YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE
          WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
          ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
        </p>

        <h5 className="fw-bold">Waiver and Severability</h5>
        <p>
          No waiver of by the Company of any term or condition set forth in
          these Terms of Use shall be deemed a further or continuing waiver of
          such term or condition or a waiver of any other term or condition, and
          any failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>

        <h5 className="fw-bold">No Assignments or Transfers</h5>
        <p>
          You may not assign this Agreement or your rights and obligations
          hereunder, in whole or in part, to any third party without Company’s
          prior written consent (in its sole discretion), and any attempt by you
          to do so in violation hereof will be void and ineffective. Company and
          its affiliates may assign their respective rights and obligations
          under this Agreement (in whole or in part) without your consent or
          notice to you.
        </p>
      </div>
    </div>
  );
};
export default TosPage;
