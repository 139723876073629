import React from "react";
import { Button } from "react-bootstrap";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCoins } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";
import { SPIN_COLORS } from "../../../util/const";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { GameColor, Player } from "../../../types";

type Color = "primary" | "orange" | "green" | "purple";
export type Multiplier = 2 | 3 | 5 | 50;
export type BetComponent = {
  color: Color;
  multiplier: Multiplier;
};
interface BetAreaProps extends BetComponent {
  color: Color;
  bet: number;
  totalBet: number;
  totalPlayers: number;
  players: Player[];
  winColor?: GameColor;
  counter: number;
  animationEnded: boolean;
}

function BetArea({
  color,
  bet,
  multiplier,
  totalBet,
  totalPlayers,
  players,
  counter,
  winColor,
  animationEnded,
}: BetAreaProps) {
  const { token } = useSelector(selectUser);
  const placeBet = async () => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("/wof/placebet", {
            color: SPIN_COLORS[color],
            amount: String(bet),
            token,
          }),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={`d-flex flex-column gap-2 bet-area ${color}`}>
      <Button
        onClick={placeBet}
        variant="outline"
        className={`bg-transparent py-3 px-5 ${color}`}
      >
        {multiplier}X
      </Button>{" "}
      <div className="d-flex py-2 px-2 justify-content-between">
        <div className="d-flex align-items-center flex-row gap-2">
          <FontAwesomeIcon className="bet-area-icon" icon={faUser} />
          <span className="fw-bold">
            {<CountUp start={totalPlayers / 2} end={totalPlayers} />}
          </span>
        </div>
        <div className="d-flex align-items-center flex-row gap-2">
          <FontAwesomeIcon className="bet-area-icon" icon={faCoins} />
          <span className="fw-bold">
            {<CountUp start={totalBet / 2} end={totalBet} />}
          </span>
        </div>
      </div>
      {players.map((p: Player, index) => {
        if (counter === 0 && animationEnded) {
          return winColor === SPIN_COLORS[color] ? (
            <div
              key={index}
              className="d-flex py-2 px-2 justify-content-between bg-glass rounded-md"
            >
              <img
                src={p.avatar}
                alt="profile-img"
                className="rounded-full icon-image-lg"
              />
              <div className="d-flex align-items-center flex-row gap-2">
                <img className="balance-ic" src="/images/balance.svg" />
                <span className="fw-bold">
                  <span className="fw-bold text-green">
                    +{(p.bet * multiplier).toFixed(0)}
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <div
              key={index}
              className="d-flex py-2 px-2 justify-content-between bg-glass rounded-md"
            >
              <img
                src={p.avatar}
                alt="profile-img"
                className="rounded-full icon-image-lg"
              />
              <div className="d-flex align-items-center flex-row gap-2">
                <img className="balance-ic" src="/images/balance.svg" />
                <span className="fw-bold text-red">-{p.bet}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="d-flex py-2 px-2 justify-content-between bg-glass rounded-md"
            >
              <img
                src={p.avatar}
                alt="profile-img"
                className="rounded-full icon-image-lg"
              />
              <div className="d-flex align-items-center flex-row gap-2">
                <img className="balance-ic" src="/images/balance.svg" />
                <span className="fw-bold">
                  {<CountUp start={p.bet / 2} end={p.bet} />}
                </span>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default BetArea;
