import { Button, Col, Container, Row } from "react-bootstrap";
import "./index.css";
import { useTranslation } from "react-i18next";
import ChatBar from "../../components/Chat/ChatBar";

import CustomInput from "../../components/Inputs";
import { useCallback, useEffect, useMemo, useState } from "react";
import BetArea, { BetComponent } from "../../components/Roll/BetArea";

import Chat from "../../components/Chat";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";
import {
  MAX_BET,
  SPIN_COLORS,
  WS_EVENTS,
  middlewareUrl,
} from "../../util/const";
import {
  BetStatData,
  GameColor,
  PlayerMap,
  RollStat,
  WOFSpinAction,
  WebsocketAction,
} from "../../types";
import {
  getLastRollColorsCount,
  getLastWonColors,
  transformData,
} from "../../helpers";
import CountdownComponent from "../../components/Countdown";
import CopyToClipboard from "../../components/UI/CopyToolTip";

interface BettingOption {
  label: string;
  action: (bet: number) => void;
}

const bettingButtons: BetComponent[] = [
  {
    color: "primary",
    multiplier: 2,
  },
  {
    color: "orange",
    multiplier: 3,
  },
  {
    color: "green",
    multiplier: 5,
  },
  {
    color: "purple",

    multiplier: 50,
  },
];
const redirectToLogin = () => {
  const url = `${middlewareUrl}/auth/steam`;
  window.location.href = url;
};
function ColorsPage() {
  const { t } = useTranslation();
  const { balance: userBalance, token } = useSelector(selectUser);

  const userMsgs = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.user] || [],
  );

  const spinMsgs = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.spin] || [],
  );

  const reversedSpinMsgs = spinMsgs.slice()?.reverse();

  const counterObj = reversedSpinMsgs.find(
    (x: WebsocketAction) => x.action === "WOFCounter",
  );

  let counter = -1;
  let hash = "";
  if (counterObj) {
    counter = counterObj.counter;
    hash = counterObj.hash;
  }
  const [spinning, setSpinning] = useState<boolean>(false);

  const playersObj: PlayerMap = reversedSpinMsgs.find(
    (x: WebsocketAction) => x.action === "WOFPlayers",
  )?.players;

  const spinResult: WOFSpinAction = reversedSpinMsgs.find(
    (x: WebsocketAction) => x.action === "WOFSpin",
  );

  const historyObj =
    reversedSpinMsgs.find((x: WebsocketAction) => x.action === "WOFHistory") ||
    counterObj;
  let rollStats: RollStat[] = [];
  let lastRolls: GameColor[] = [];
  if (historyObj) {
    rollStats = getLastRollColorsCount(historyObj?.history, 100);
    lastRolls = getLastWonColors(historyObj?.history, 10);
  }

  const transformedData: BetStatData = playersObj && transformData(playersObj);

  const wsBalance = parseInt(userMsgs[userMsgs.length - 1]?.balance);
  const [winColor, setWinColor] = useState<GameColor | string>("");
  const [balance, setBalance] = useState<number>(userBalance);
  const [bet, setBet] = useState<number>(100);
  const [animationEnded, setAnimationEnded] = useState<boolean>(false);

  const handleAnimationEnd = () => {
    setAnimationEnded(true);
  };

  useEffect(() => {
    if (wsBalance !== userBalance)
      setBalance(
        parseInt(wsBalance.toFixed(0)) || parseInt(userBalance.toFixed(0)),
      );
  }, [userBalance, wsBalance]);

  useEffect(() => {
    if (!token) {
      // if user suddenly logs out
      setBalance(0);
    }
  }, [token]);

  useEffect(() => {
    setSpinning(counter === 0);
    setWinColor(spinResult?.color as GameColor);
  }, [counter, spinResult?.color]);

  const updateBet = useCallback(
    (actionLabel: string, value: number, currentBet: number) => {
      if (!token) {
        redirectToLogin();
      }
      let newBet = Math.min(balance, Math.max(0, currentBet + value));
      if (newBet > MAX_BET) {
        newBet = balance;
      }

      setBet(Number(newBet));
    },
    [balance],
  );
  const bettingOptions: BettingOption[] = useMemo(
    () => [
      {
        label: "Clear",
        action: () => setBet(0),
      },
      // { label: "+10", action: (bet: number) => updateBet("+10", 10, bet) },
      {
        label: "+100",
        action: (bet: number) => updateBet("+100", 100, bet),
      },
      {
        label: "+1k",
        action: (bet: number) => updateBet("+1k", 1000, bet),
      },
      {
        label: "+10k",
        action: (bet: number) => updateBet("+10k", 10000, bet),
      },
      {
        label: "1/2",
        action: (bet: number) =>
          token ? bet && setBet(bet / 2) : redirectToLogin(),
      },
      {
        label: "2x",
        action: (bet: number) => updateBet("2x", bet, bet),
      },
      {
        label: "Max",
        action: () =>
          token
            ? setBet(balance > MAX_BET ? MAX_BET : balance)
            : redirectToLogin(),
      },
    ],
    [bet, updateBet, balance],
  );

  useEffect(() => {
    if (bet > MAX_BET) {
      setBet(MAX_BET);
    }
  }, [bet]);

  const handleBetChange = (value: string) => {
    const inputBet = parseInt(String(value), 10) || 0;
    const newBet = Math.min(balance, Math.max(0, inputBet));
    setBet(newBet);
  };

  return (
    <Container className="container-page">
      <Row className=" h-100">
        <Col className="order-md-2" xs={12} xl={4}>
          <div className="d-flex flex-column gap-3">
            <Button
              className="btn-success text-white text-uppercase w-100 d-none"
              size="lg"
              variant="success"
            >
              {t("youtubersWanted")}
            </Button>
            <div>
              <div className="d-flex gap-2 balance-wrapper pt-3 border-b-primary">
                <img className="balance-ic" src="/images/balance.svg" />
                <span className="fw-bold text-lg text-golden">
                  {new Intl.NumberFormat().format(balance)}
                </span>
              </div>
              <div className="d-flex gap-2 mt-2 betting-bar">
                {bettingOptions.map((option, index) => (
                  <Button
                    key={index}
                    onClick={() => option.action(bet)}
                    variant="info"
                    className="bg-transparent  "
                  >
                    <span className="">{option.label}</span>
                  </Button>
                ))}
              </div>
            </div>
            <CustomInput
              lightInput
              type="number"
              value={bet > 0 ? bet : ""}
              onChange={handleBetChange}
              name={"bet"}
            />
            <div className="hash-div">
              {hash && (
                <>
                  <p className="text-sm mb-0">{t("currentGameHash") + ":"}</p>
                  <div className="d-flex gap-1 w-100 pl-2 align-items-center justify-content-center justify-content-between">
                    <span className="text-sm hash-text">{hash}</span>
                    <CopyToClipboard value={hash} />
                  </div>
                </>
              )}
            </div>
            {rollStats?.length > 0 && (
              <>
                <div className="roll-stats mb-2 align-items-center d-flex gap-3">
                  <p className="fw-bold my-0">{t("last100Rolls")}</p>
                  {rollStats.map((roll) => (
                    <div
                      className={`roll-stat fade-in p-2  ${roll.color}`}
                      key={roll.color}
                    >
                      {roll.count}
                    </div>
                  ))}
                </div>
                <div className=" flex-column gap-3 d-none d-md-flex">
                  <p className="fw-bold my-0">{t("latestSpins")}</p>
                  {lastRolls?.map((color, index) => (
                    <div
                      key={`roll-bar-${index}-${color}-${hash}`}
                      className={`roll-bar ${color} fade-in`}
                    ></div>
                  ))}
                </div>
              </>
            )}
          </div>
        </Col>

        <Col
          className="roll-mid d-flex justify-content-center order-md-1  order-xl-2"
          xs={12}
          xl={4}
        >
          <div className="relative monkey-root">
            <img
              className="roll-monkey"
              src="/images/rollgame/monkey-over.png"
            />
            <img className="roll-table" src="/images/rollgame/roll-table.png" />

            <img
              onAnimationEnd={handleAnimationEnd}
              onAnimationStart={() => setAnimationEnded(false)}
              key={spinning ? "suka" : "nesuka"}
              className={`roll-colors ${spinning ? `spin-${winColor}` : ""}`}
              src="/images/rollgame/roll-colors.png"
            />
            <div className="roll-arrow">
              <div id="roll-text">
                <CountdownComponent
                  animationEnded={animationEnded}
                  winColor={winColor}
                  counter={counter}
                />
              </div>
              <span></span>
            </div>
          </div>
        </Col>
        <Col xs={12} xl={4} className="d-none d-md-block order-md-3">
          <div className=" d-flex flex-column justify-content-between">
            <ChatBar customLayout />

            <Chat sm />
          </div>
        </Col>
      </Row>
      <Row className="my-1 py-1 my-md-3 py-md-5">
        {bettingButtons.map((bettingOption, index) => (
          <Col className="mt-5" key={index} xs={12} md={3}>
            <BetArea
              {...bettingOption}
              totalBet={
                transformedData?.[SPIN_COLORS[bettingOption.color]]?.totalBet ||
                0
              }
              totalPlayers={
                transformedData?.[SPIN_COLORS[bettingOption.color]]
                  ?.totalPlayers || 0
              }
              players={
                transformedData?.[SPIN_COLORS[bettingOption.color]]?.players ||
                []
              }
              winColor={winColor as GameColor} // todo: prevent casting
              counter={counter}
              animationEnded={animationEnded}
              bet={bet}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ColorsPage;
