import React, { useEffect, useState } from "react";

import apiRequest from "../../util/apiUtil";
import axiosInstance from "../../util/axios";

import PaginationComponent from "../Pagination";
import TabTable from "../Tables/TabTable";

import { useTranslation } from "react-i18next";
import { PaginationType, BetRecord, WofEntry } from "../../types";
import { DEFAULT_ITEMS_PER_PAGE, SPIN_COLORS_REVERSED } from "../../util/const";
import { transformData } from "../../util/helpers";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";
import { Button } from "react-bootstrap";

interface BetResponse extends PaginationType {
  data: BetRecord[];
}

interface BetsModalProps {
  steamIdProps?: string;
}

// Update the component to accept props
const BetsModal: React.FC<BetsModalProps> = ({ steamIdProps }) => {
  const { t } = useTranslation();
  const { steamid } = useSelector(selectUser);
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<BetResponse>({
    data: [],
    total: 0,
    page: 0,
    per_page: 0,
  });
  const [selectedMode, setSelectedMode] = useState<"towers" | "wof">("towers");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);

        const request = await apiRequest({
          axiosFunction: () =>
            axiosInstance.post(
              `/bets/${selectedMode}/${steamIdProps || steamid}?page=${page}&sortOrder=desc&per_page=${DEFAULT_ITEMS_PER_PAGE}`,
            ),
        });
        let { data } = request;
        data = [];
        // TODO: to give pizdy to backenders for this retarded shit. Inconsistency
        if (selectedMode === "wof") {
          for (const record of request.data) {
            record.data.altInfo = SPIN_COLORS_REVERSED[record.data.altInfo];
            delete record.data.userId;
            record.data.timestamp = record.timestamp;

            const wofObject: WofEntry = {
              altInfo: record.data.altInfo,
              betValue: record.data.betValue,
              id: record.id,
              timestamp: record.timestamp,
              winnings: record.data.winnings,
            };

            data.push(wofObject);
          }
        } else {
          for (const record of request.data) {
            delete record.userData;
            delete record.towers;
            delete record.username;
            data.push(record);
          }
        }

        setData({
          ...request,
          data,
        });
      } catch (error) {
        console.error("Error during API request:", error);
      } finally {
        setFetching(false);
      }
    };

    steamid && fetchData();
  }, [page, steamid, selectedMode]);

  const transformedData = transformData(data?.data, t) || [];

  return (
    <div>
      <div className="d-flex flex-column flex-col gap-3 py-3">
        <div className="d-flex justify-content-start gap-3">
          <Button
            disabled={selectedMode === "towers"}
            variant={selectedMode === "towers" ? "primary" : "secondary"}
            onClick={() => setSelectedMode("towers")}
          >
            {t("tower")}
          </Button>
          <Button
            disabled={selectedMode === "wof"}
            variant={selectedMode === "wof" ? "primary" : "secondary"}
            onClick={() => setSelectedMode("wof")}
          >
            {t("wof")}
          </Button>
        </div>
        <TabTable data={transformedData} />
        {data?.data?.length > 0 && (
          <PaginationComponent
            hasNext={data.total >= page * DEFAULT_ITEMS_PER_PAGE}
            onPageChange={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default BetsModal;
