import React from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";
import { WS_EVENTS } from "../../../util/const";
import { useSelector } from "react-redux";

const OnlineCount: React.FC = () => {
  const { t } = useTranslation();
  const onlineCountArr = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.onlineUsers] || 0,
  );
  let onlineCount = 0;
  if (onlineCountArr) {
    onlineCount = onlineCountArr.slice().reverse()[0];
  }
  return (
    <div
      id="online-count"
      className="d-flex gap-1 align-items-center justify-content-center"
    >
      <FontAwesomeIcon className="online-ic" icon={faPeopleGroup} size="xs" />
      <Tooltip anchorSelect="#online-count" id="tooltip-online">
        {t("onlineCount")}
      </Tooltip>
      <span className="fw-bold">{onlineCount}</span>
    </div>
  );
};

export default OnlineCount;
