import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { Provider } from "react-redux";
import store from "./store/store";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={1000 * 5}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      theme="dark"
      rtl={false}
      pauseOnFocusLoss={false}
      limit={1}
      draggable
      pauseOnHover
    />
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
);
// React strict mode would cause issues with re-renders: double mounting components.
// https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
