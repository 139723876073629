import { Button } from "react-bootstrap";
import { SPIN_COLORS, SPIN_COLORS_REVERSED } from "../../../../util/const";
import { useState, useEffect } from "react";
import { GameColor } from "../../../../types";
import PropTypes from "prop-types";
import axiosInstance from "../../../../util/axios";
import apiRequest from "../../../../util/apiUtil";

interface WinnerSelectProps {
  onGameEnd: boolean;
}

const WinnerSelect: React.FC<WinnerSelectProps> = ({ onGameEnd }) => {
  const [color, setColor] = useState<GameColor | null>(null);

  useEffect(() => {
    if (onGameEnd) {
      setColor(null);
    }
  }, [onGameEnd]);

  const overrideBet = async (color: GameColor) => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("/admin/wof", {
            color,
          }),
      });
      setColor(color);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      id="win-select"
      className="d-flex justify-content-center flex-wrap gap-2 flex-row"
    >
      {Object.entries(SPIN_COLORS).map(([key, val]) => {
        const isSelected =
          color && SPIN_COLORS_REVERSED[color] && color === val;
        return (
          <Button
            key={key}
            onClick={() => overrideBet(val)}
            variant="outline"
            className="flex-1 py-1.5 px-3"
            style={{
              border: `1px solid var(--${key})`,
              background: isSelected ? `var(--${key})` : "transparent",
              zIndex: 1000,
            }}
          >
            <span
              className="text-capitalize"
              style={{
                color: isSelected ? `white` : `var(--${key})`,
              }}
            >
              {SPIN_COLORS_REVERSED[val]}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

WinnerSelect.propTypes = {
  onGameEnd: PropTypes.bool.isRequired,
};

export default WinnerSelect;
