import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CopyToClipboardProps {
  value: string;
  label?: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ value, label }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className="copy-to-clipboard">
        <Button id="copy" variant="outline" onClick={handleCopy}>
          <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
        </Button>
        <Tooltip anchorSelect="#copy" id="tooltip">
          {t(copied ? "copied" : label || "copy")}
        </Tooltip>
      </div>
    </div>
  );
};

export default CopyToClipboard;
