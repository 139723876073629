import { Col, Row, Tab, Tabs, Button } from "react-bootstrap";
import "./style.css";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ColumnValues, Difficulty, GameState } from "../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { findBreakpoint, generateFakeBombArray } from "../../../helpers";
import {
  MIN_BET,
  WS_EVENTS,
  gameConfig,
  middlewareUrl,
} from "../../../util/const";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import axiosInstance from "../../../util/axios";
import apiRequest from "../../../util/apiUtil";
import checkAlternative from "../../../actions/checkAlternative";
import createGame from "../../../actions/createTowerGame";
import AccordionComponent from "../../Accordion";
import { setPublicHash, setTower } from "../../../store/slices/towerSlice";
import { getRandomExceptSelected, playGameSound } from "../../../util/helpers";
import DepositModal from "../../Modals/DepositModal/DepositModal";
import { CloseFooter } from "../../Modals";
import { openModal } from "../../../store/slices/modalSlice";
import { GameDisplay } from "../../Tower";
import { toast } from "react-toastify";

enum BetAction {
  INCREASE,
  DECREASE,
  MIN,
  MAX,
}
const DEMO_COINS = 500;

// todo: in future gather all possible WS payloads and type define them

function TowerGame() {
  const dispatch = useDispatch();
  const {
    balance: userBalance,
    token,
    fetching: userFetching,
  } = useSelector(selectUser);

  const towerMsgs: GameState[] = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.onTower] || [],
  );

  const userMsgs = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.user]?.filter((x : any) => x.action!=='update value') || [],
  );

  const wsBalance = parseInt(userMsgs[userMsgs.length - 1]?.balance);

  const [gambled, setGambled] = useState<boolean>(false);



  const balance = gambled && userMsgs?.length>0 ? wsBalance || 0 : userBalance;

  const [mode, setMode] = useState<Difficulty>("easy");
  // todo: most of these hooks already come from gameState, so would really need to tidy up all useless hooks!
  const [tickets, setTickets] = useState<number[]>([]);

  const [demoBombs, setDemoBombs] = useState<number[]>([]);
  const [choices, setChoices] = useState<number[]>([]);
  const [gambles, setGambles] = useState<number[]>([]);
  const [recentChoices, setRecentChoices] = useState<number[]>([]);
  const [columnValues, setColumnValues] = useState<ColumnValues>({
    easy: [14, 21, 30, 44, 64, 92, 134, 195, 283, 410],
    medium: [19, 38, 74, 144, 281, 549, 1072, 2090, 4076, 7949],
    hard: [29, 87, 256, 757, 2234, 6590, 19442, 57355, 169199, 499137],
  });

  const [gameState, setGameState] = useState<GameState>();
  const [fetching, setFetching] = useState<boolean>(false);

  const [creatingGame, setCreatingGame] = useState<boolean>(false);

  const [demoMode, setDemo] = useState<boolean>(false);

  const [showDemo, setShowDemo] = useState<boolean>(false);
  const [userCheckedOut, setCheckedOut] = useState<boolean>(false);
  // this is stupid but nescessary due to backend WS doing stuff like rightAnswer: false after doing a checkout.

  const [win, setWin] = useState<number>(0);

  const [demoBalance, setDemoBalance] = useState<number>(DEMO_COINS);
  const [bet, setBet] = useState<number>(MIN_BET);

  const [playing, setPlaying] = useState<boolean>(false);

  const [currentStep, setStep] = useState<number>(1);

  const reversedCheckMessages = towerMsgs
    ?.slice()
    .reverse()
    .filter((x: GameState) => x.action === WS_EVENTS.onCheck);

  const lastCheckMsg: GameState | undefined = reversedCheckMessages?.[0];

  useEffect(() => {
    const mutableLastCheckMsg = { ...lastCheckMsg };

    // Modify the tickets property of the mutable copy
    mutableLastCheckMsg.tickets =
      mutableLastCheckMsg.tickets?.slice(0, columnValues[mode].length) || [];
    setGameState(mutableLastCheckMsg);
    if (lastCheckMsg?.fairness) {
      dispatch(setTower(lastCheckMsg.fairness));
    }
  }, [lastCheckMsg]);

  const showDemoOverlay = () => {
    setShowDemo(true);
    setDemo(true);
    setBet(DEMO_COINS);
    setDemoBalance(0);
  };

  const handleTabChange = (k: Difficulty) => {
    setMode(k);
    if (!demoMode) {
      setTickets([]);
      setChoices([]);
    }
  };

  useEffect(() => {
    if (userFetching || creatingGame) {
      return;
    }

    if (balance > 0 || gameState?.active) {
      setShowDemo(false);
      setDemo(false);
    } else if (
      ((balance < 1 && !demoMode && !gameState?.active) ||
        (demoBalance < MIN_BET && demoMode)) &&
      !playing
    ) {
      showDemoOverlay();
    }
  }, [balance, playing, demoMode, creatingGame, gameState, userFetching]);

  useEffect(() => {
    if (!token) {
      showDemoOverlay();
    }
  }, [token]);
  const fetchTower = async () => {
    try {
      const data = await apiRequest({
        axiosFunction: () => axiosInstance.post("/towers/getcurrentgame"),
      });
      setPublicHash(data?.data?.publicHash);

      setGameState(data.data);
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  useEffect(() => {
    if (token) fetchTower();
  }, [token]);

  useEffect(() => {
    if (gameState?.rightAnswer) {
      setPlaying(true);
      setBet(parseInt(gameState.bet));
      setStep(gameState.level + 1);
      setMode(gameState.mode);

      setChoices(gameState.answers);
      setGambles(gameState.answers);
      const potentialWinning =
        gameState.level > 0
          ? Math.floor(columnValues[mode][gameState.level - 1])
          : parseInt(gameState.bet);
      setWin(potentialWinning);

      gameState.level > 0 && gambled && playGameSound(true);
    } else {
      !userCheckedOut &&
        gambled &&
        gameState?.done &&
        playGameSound(
          gameState &&
            !Object.prototype.hasOwnProperty.call(gameState, "rightAnswer")
            ? true
            : false,
        );
      setTickets(
        gameState?.tickets
          ? gameState?.tickets?.slice(0, columnValues[mode].length)
          : [],
      );

      setPlaying(false);
    }
  }, [gameState]);

  useEffect(() => {
    if (gameState?.tickets && gameState?.done) {
      const { mode } = gameState;

      let i = 0;
      let isChanged = false;
      const payloadArr = [];
      for (const ticket of gameState.tickets) {
        if (userCheckedOut && i === gambles.length - 1) {
          if (mode !== "hard") {
            payloadArr[gambles.length - 1] = getRandomExceptSelected(
              gambles[gambles.length - 1],
              mode === "easy",
            );

            isChanged = true;
          } else if (mode === "hard") {
            payloadArr[gambles.length - 1] = gambles[gambles.length - 1];

            isChanged = true;
          }
        } else if (
          Object.prototype.hasOwnProperty.call(gameState, "rightAnswer") &&
          !gameState.rightAnswer &&
          i === gambles.length - 1
        ) {
          if (mode !== "hard" && ticket !== gambles[gambles.length - 1]) {
            payloadArr[gambles.length - 1] = gambles[gambles.length - 1];
            isChanged = true;
          } else if (
            mode === "hard" &&
            ticket === gambles[gambles.length - 1]
          ) {
            payloadArr[gambles.length - 1] = getRandomExceptSelected(
              gambles[gambles.length - 1],
              false,
            );

            isChanged = true;
          }
        } else if (gameState.rightAnswer || i <= gameState.level) {
          if (ticket !== gambles[i] && mode === "hard") {
            payloadArr[i] = gambles[i];
            isChanged = true;
          } else if (mode !== "hard" && gambles[i] === ticket) {
            payloadArr[i] = getRandomExceptSelected(
              gambles[i],
              mode === "easy",
            );

            isChanged = true;
          }
        }

        if (!payloadArr[i]) {
          payloadArr[i] = ticket;
        }
        i++;
      }

      if (
        isChanged &&
        gambles.length > 0 &&
        !gameState.rightAnswer &&
        gameState.done
      ) {
        setTickets(payloadArr);
      }
    }
  }, [gameState?.rightAnswer, gambles, userCheckedOut]);

  const calculateGameMap = () => {
    if (bet < 0 || !bet) {
      return;
    }
    const brkpoint = findBreakpoint(
      gameConfig.gameDetails[mode].breakPoints,
      bet,
    );

    const currentModeValues: number[] = [];
    const betVal = bet * gameConfig.gameDetails[mode].multiplier;
    currentModeValues.push(betVal);
    !playing && setWin(bet);

    for (let i = 0; i < brkpoint.steps - 1; i++) {
      const val =
        currentModeValues[i] * gameConfig.gameDetails[mode].multiplier;
      currentModeValues.push(parseFloat(String(val)));
    }

    setColumnValues({
      ...columnValues,
      [mode]: currentModeValues,
    });
  };

  const handleReset = () => {
    setGameState(undefined);
    setStep(1);
    setChoices([]);
    setTickets([]);
    calculateGameMap();
    setDemoBombs([]);
    setGambles([]);
  };

  useEffect(() => {
    calculateGameMap();
    if (demoMode && bet > demoBalance && !playing) {
      setBet(demoBalance);
    }
  }, [mode, bet, playing]);

  useEffect(() => {
    if (!showDemo && demoMode) {
      setBet(gameConfig.gameDetails[mode].minBet);
      handleReset();
      setPlaying(false);
    }
  }, [mode, demoMode, showDemo]);

  const handlePlay = async () => {
    handleReset();
    setCheckedOut(false);
    if (!gameState?.rightAnswer && !demoMode) {
      try {
        setCreatingGame(true);
        const data = await createGame(mode, String(bet));
        setGambled(true);
        await fetchTower();
        dispatch(setPublicHash(data.publicHash));
        setPlaying(true);
      } catch (error) {
        console.error(error);
      } finally {
        setCreatingGame(false);
      }
    } else {
      const { minBet } = gameConfig.gameDetails[mode];
      if (minBet > bet) {
        toast.error(t("errors.minBet"));
        return;
      }
      setPlaying(true);
      setDemoBalance(demoBalance - bet);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePlay();
    }
  };
  const handleSetBet = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = 0;
    const { value } = event.target;
    const { maxBet } = gameConfig.gameDetails[mode];

    if (demoMode) {
      if (parseInt(value) > demoBalance) {
        return;
      }
    }

    if (maxBet < parseInt(value)) {
      newValue = maxBet;
    } else {
      newValue = parseInt(value);
    }
    setBet(newValue);
  };

  const handleGamble = async (key: number, potentialWinnings: number) => {
    setCheckedOut(false);
    if (demoMode) {
      const bombArray: Array<boolean> = generateFakeBombArray(mode, key);
      setChoices([...choices, key + 1]);
      const arr: number[] = [];
      bombArray.map((bomb: boolean, index) => {
        if (bomb && mode !== "hard") {
          arr.push(index + 1);
        } else if (mode === "hard" && !bomb) {
          arr.push(index + 1);
        }
      });

      setDemoBombs([...demoBombs, ...arr]);

      setRecentChoices([key]);
      if (!bombArray[key]) {
        setWin(potentialWinnings);
        setStep(currentStep + 1);
        playGameSound(true);
      } else {
        setWin(0);
        setTickets([...demoBombs, ...arr]);
        setPlaying(false);
        playGameSound(false);
      }
      return;
    }

    setFetching(true);
    setRecentChoices([key]);
    setGambled(true);
    try {
      setGambles([...gambles, key + 1]);
      await checkAlternative({
        alternative: key + 1,
      });
    } catch (e) {
      console.error(e);
    }
    setFetching(false);
  };

  const handleBetChange = (
    action: BetAction,
  ): MouseEventHandler<HTMLButtonElement> | undefined => {
    let { maxBet } = gameConfig.gameDetails[mode];
    const { minBet } = gameConfig.gameDetails[mode];
    if (demoMode) {
      maxBet = demoBalance;
    }
    switch (action) {
      case BetAction.INCREASE:
        if (bet > 0 && bet * 2 <= maxBet)
          setBet(parseFloat((bet * 2).toFixed(0)));
        else if (!bet) {
          setBet(minBet);
        } else {
          setBet(maxBet);
        }
        break;
      case BetAction.DECREASE:
        if (bet > 0 && bet / 2 >= minBet) {
          setBet(parseFloat((bet / 2).toFixed(0)));
        } else {
          setBet(minBet);
        }
        break;
      case BetAction.MIN:
        setBet(minBet);
        break;
      case BetAction.MAX:
        setBet(demoMode ? demoBalance : balance < maxBet ? balance : maxBet);
        break;
      default:
        break;
    }
    return;
  };

  const { t } = useTranslation();

  const { fieldsPerRow } = gameConfig.gameModes[mode];

  const elements = Array.from({ length: fieldsPerRow });
  const lost: boolean = tickets?.length > 0;

  const handleCheckout = async () => {
    setCheckedOut(true);
    if (demoMode) {
      if (currentStep === 1) {
        return;
      }
      setPlaying(false);
      setDemoBalance(demoBalance + win);
      return;
    }
    if (!lost) {
      try {
        await apiRequest({
          axiosFunction: () => axiosInstance.post("/towers/cashout"),
        });
        setPlaying(false);
        setStep(0);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const inputDisabled = !lost && playing;

  return (
    <div className="relative monkey-div mx-auto">
      {mode !== "medium" && (
        <img
          className={`monkey-head-wrapper bounce-in`}
          src="/images/monkey2x.png"
          alt="monkey"
        />
      )}

      <div
        className={`monkey-wrapper ${
          mode === "medium" ? "opacity-1" : "opacity-0"
        }`}
      >
        <img
          className="monkey-head-wrapper-second bounce-in"
          src="/images/head.svg"
          alt="monkey-head"
        />
        <img
          className="monkey-body bounce-in"
          src="/images/Body.png"
          alt="monkey-head"
        />
        <img
          className="monkey-right bounce-in"
          src="/images/handright2.png"
          alt="monkey-right"
        />
        <img
          className="monkey-left bounce-in"
          src="/images/handleft2.png"
          alt="monkey-left"
        />
      </div>

      <div className={`ticket-wrapper mx-auto ${mode}`}>
        {demoMode && showDemo && (
          <div className="overlay fade-in-fast demo-overlay">
            <div className="d-flex flex-column gap-3 px-5 align-items-center justify-content-center h-50 w-100">
              <Button
                onClick={() => {
                  setDemoBalance(DEMO_COINS), setShowDemo(false);
                }}
                className="w-100 py-3 text-uppercase"
                variant="secondary"
              >
                {t("playDemo")}
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    openModal({
                      modalHeader: t("deposit"),
                      modalContent: <DepositModal />,
                      modalFooter: <CloseFooter />,
                    }),
                  )
                }
                className="w-100 py-3 text-uppercase"
                variant="success"
              >
                {token ? (
                  t("depositPlay")
                ) : (
                  <a
                    className="text-uppercase text-white"
                    href={`${middlewareUrl}/auth/steam`}
                  >
                    {t("depositPlay")}
                  </a>
                )}
              </Button>
              {/* <div className="rounded-full text-uppercase"> {t("or")} </div> */}
            </div>
          </div>
        )}

        {demoMode && !showDemo && (
          <div className="demo-label-wrapper">
            <div className="demo-label fade-in-fast py-2 bg-danger absolute ">
              <div className="text-uppercase text-golden fw-bold">
                {t("demo")}
              </div>
            </div>
          </div>
        )}
        <div className="px-4 py-2 pt-4 d-flex flex-column gap-2">
          <Row>
            <div className="d-flex align-items-center justify-content-between balance-unit ">
              <div className="flex-1 d-flex">
                <img src="./images/balance.svg" />
              </div>

              <CountUp
                className="flex-1"
                key={balance}
                start={balance}
                end={demoMode ? demoBalance : balance}
                duration={1}
              />

              <span className="flex-1 bi bi-coin opacity-0" />
            </div>
          </Row>

          <div
            key={mode}
            className="d-flex flex-column-reverse gap-2 betting-tiles"
          >
            {columnValues[mode as Difficulty].map((value, index) => {
              const currentLevel: boolean = currentStep === index + 1;

              return (
                <Row key={index} className="gap-1 relative ">
                  {currentLevel && playing && !lost && (
                    <>
                      {" "}
                      <FontAwesomeIcon
                        className="fa-icon fa-left bounce-right"
                        icon={faChevronRight}
                      />{" "}
                      <FontAwesomeIcon
                        className="fa-icon fa-right bounce-left"
                        icon={faChevronLeft}
                      />{" "}
                    </>
                  )}
                  {elements.map((val, key) => {
                    // this stuff has grown to be unreadable and hardly maintable. You must doa seperate component, which simply evaluates all the element state and returns () needed JSX
                    const wonButton: boolean = choices[index] === key + 1;
                    const latestChoice: boolean = recentChoices[0] === key;
                    const awaitingCheck: boolean =
                      fetching && latestChoice && currentLevel;

                    const isBomb =
                      (tickets[index] === key + 1 && mode !== "hard") ||
                      (mode === "hard" && tickets[index] !== key + 1);

                    return (
                      <Col key={key}>
                        <Button
                          onClick={() => handleGamble(key, Math.floor(value))}
                          disabled={
                            !playing || !currentLevel || lost || fetching
                          }
                          className="bg-transparent  w-100 h-100 p-0 "
                        >
                          <GameDisplay
                            currentLevel={currentLevel}
                            lost={lost}
                            isBomb={isBomb}
                            latestChoice={latestChoice}
                            t={t}
                            mode={mode}
                            awaitingCheck={awaitingCheck}
                            wonButton={wonButton}
                            value={value}
                          />
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>

          <Row className="d-flex flex-column gap-1">
            <Button
              key={playing ? "2" : "@"}
              disabled={creatingGame}
              variant={lost || !playing ? "success" : "secondary"}
              onClick={() =>
                lost || !playing
                  ? (handleReset(), handlePlay())
                  : handleCheckout()
              }
              className="text-uppercase py-3 text-white fadeIn w-100"
            >
              {lost || !playing ? (
                <span className="fw-bold text-white">
                  {t(demoMode ? "playDemo" : "play")}
                </span>
              ) : (
                <Trans
                  i18nKey="claimWin"
                  values={{
                    winnings:
                      currentStep && currentStep > 1
                        ? Math.floor(
                            columnValues[mode][(currentStep - 2) as number],
                          )
                        : bet || win,
                  }}
                  components={[
                    <span className="fw-bold text-golden" key="0">
                      {0}
                    </span>,
                  ]}
                />
              )}
            </Button>

            <div className="d-flex action-wrapper bg-secondary-lighter rounded-md p-3 justify-content-between ">
              <div className="d-flex button-wrapper justify-content-between">
                <Button
                  className="text-uppercase px-2 bg-secondary-base"
                  size="sm"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.MIN)}
                >
                  {t("min")}
                </Button>
                <Button
                  className="bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.DECREASE)}
                >
                  <i className="bi bi-dash-lg"></i>{" "}
                </Button>
              </div>

              <input
                className="bg-transparent border-0 text-center text-golden bet-input"
                type="number"
                onKeyPress={handleKeyPress}
                disabled={inputDisabled}
                value={bet}
                onChange={handleSetBet}
              />

              <div className="d-flex button-wrapper justify-content-between">
                <Button
                  className="bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.INCREASE)}
                >
                  <i className="bi bi-plus-lg"></i>{" "}
                </Button>
                <Button
                  className="text-uppercase px-2 bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.MAX)}
                >
                  {t("max")}
                </Button>
              </div>
            </div>
          </Row>
        </div>

        <Tabs
          id="tab-switcher"
          activeKey={mode}
          onSelect={(k) => handleTabChange(k as Difficulty)}
          className=" w-100 game-tabs full-tabs px-0 text-uppercase active-green justify-content-between"
        >
          <Tab
            eventKey="easy"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/easy.png" alt="easy" />
                <span>{t("easy")}</span>
              </div>
            }
          />
          <Tab
            eventKey="medium"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/medium.png" alt="medium" />
                <span>{t("medium")}</span>
              </div>
            }
          />
          <Tab
            eventKey="hard"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/hard.png" alt="hard" />
                <span>{t("hard")}</span>
              </div>
            }
          />
        </Tabs>

        <AccordionComponent />
      </div>
    </div>
  );
}

export default TowerGame;
