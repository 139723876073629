import { ChangeEvent } from "react";
import { Button, Form } from "react-bootstrap";
import "./index.css";

type ChangeHandler = (value: string) => void;

interface CustomInputProps {
  label?: string | JSX.Element;
  onChange?: ChangeHandler;
  name: string;
  placeholder?: string;
  buttonText?: string;
  type?: "text" | "number";
  lightInput?: boolean;
  value?: number | string;
  onClick?: () => void;
  disabled?: boolean;
  disabledBtn?: boolean;
  disabledInput?: boolean;
  readOnly?: boolean;
  className?: string;
  rootClass?: string;
  step?: number | string | undefined;
}

function CustomInput({
  label,
  onChange,
  name,
  buttonText,
  type = "text",
  lightInput,
  value,
  placeholder,
  onClick,
  readOnly,
  disabledBtn,
  disabledInput,
  className,
  rootClass,
  step,
}: CustomInputProps) {
  const inputClassName = `${name}  ${className ? className + " " : ""}${
    lightInput ? "light-input " : ""
  }${buttonText ? "radius-left" : ""}`;

  return (
    <div className={`d-flex flex-column custom-input ${rootClass}`}>
      {label && (
        <Form.Label htmlFor={name} className="mb-2">
          {label}
        </Form.Label>
      )}
      <div className="d-flex flex-row">
        <Form.Group controlId={name} className="w-100">
          <Form.Control
            step={step}
            readOnly={readOnly ?? false}
            type={type}
            name={name}
            disabled={disabledInput ?? false}
            placeholder={placeholder}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.target.value)
            }
            value={value}
            className={inputClassName}
          />
        </Form.Group>

        {buttonText && (
          <Button
            disabled={disabledBtn ?? false}
            onClick={onClick ?? (() => {})}
            className="text-uppercase"
            variant="secondary"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default CustomInput;
