import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VoucherState {
  open: boolean;
}

const initialState: VoucherState = {
  open: false,
};

const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const { setOpen } = voucherSlice.actions;

export const selectVoucher = (state: { voucher: VoucherState }) => state.voucher;

export default voucherSlice.reducer;
