import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import SkinDepositModal from "./SkinDepositModal";
import CryptoDepositModal from "../CryptoModal";
import { Coin, MethodType, Payment, PaymentMethodType } from "../../../types";
import { paymentMethods } from "../../../util/const";
import DepositItem from "../../DepositItem";
import CardModal from "./CardModal";

const DepositModal: React.FC = () => {
  const { t } = useTranslation();

  const [payment, setPayment] = useState<Payment>({
    paymentType: null,
    method: null,
  });

  const goBack = () => {
    setPayment({
      paymentType: null,
      method: null,
    });
  };

  const handleMethodClick = (
    paymentType: PaymentMethodType,
    method: MethodType,
  ) => {
    setPayment({ method, paymentType });
  };

  if (payment.paymentType) {
    return (
      <div className="deposit-modal-body px-1 md-px-5">
        {payment.paymentType === "crypto" && (
          <CryptoDepositModal
            action="deposit"
            goBack={goBack}
            chosenCoin={payment.method as Coin}
          />
        )}
        {payment.paymentType === "game" && <SkinDepositModal goBack={goBack} />}
        {payment.paymentType === "cash" && <CardModal goBack={goBack} />}
      </div>
    );
  }

  return (
    <div className="deposit-modal-body px-1 md-px-5">
      <div className="d-flex flex-column gap-3">
        {Object.entries(paymentMethods).map(
          ([category, { methods, discount }]) => {
            category === "game" &&
              !methods.some((x) => x === "dota 2") &&
              methods.splice(2, 0, "dota 2");
            return (
              <div
                className="deposit-item border-top border-secondary pt-4 mt-1"
                key={"$" + category}
              >
                <h5 className="fw-bold cash-option-title text-white">
                  {t(("depositOptions." + category) as PaymentMethodType)}{" "}
                  {discount && (
                    <span className="m-2 bg-primary rounded-md text-black px-3 py-1 fw-bold">
                      {`-${discount}%`}
                    </span>
                  )}
                </h5>
                <div className="d-flex flex-wrap  flex-row gap-3 mt-3">
                  {methods.map((method, key) => (
                   <React.Fragment key={key+'!'}>
                     <DepositItem
                      category={category as PaymentMethodType}
                      onClick={handleMethodClick}
                      isCrypto={category === "crypto"}
                      method={method as MethodType}
                    />
                   </React.Fragment>
                  ))}
                  {/* {disabled && (
                    <span className="bg-secondary rounded-md fw-bold px-2 py-1">
                      {t("comingSoon")}
                    </span>
                  )} */}
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default DepositModal;
