import React, { useState, useRef, useEffect } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import Menu from "./TopMenu/Menu";
import SoundButton from "./SoundButton";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { URLMAP, middlewareUrl } from "../../util/const";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/slices/userSlice";
import { openDefaultModal } from "../../store/slices/modalSlice";

function Navigation() {
  const { t } = useTranslation();
  const [navbar, setNavbar] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const changeNavbar = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    return () => window.removeEventListener("scroll", changeNavbar);
  }, []);

  window.addEventListener("scroll", changeNavbar);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const { token, avatar, name } = useSelector(selectUser);
  const handleOpenModal = () => {
    dispatch(openDefaultModal());
  };
  return (
    <Navbar expand="lg" fixed="top" className={navbar ? "bg-dark-color " : ""}>
      <Container>
        <Navbar.Brand className="flex-basis-100 sm-flex-basis-auto">
          <Link to={URLMAP.HOME}>
            <img
              src="/images/logo_big_z.png"
              alt="Logo"
              className="logo-image"
            />
          </Link>
          <SoundButton />
        </Navbar.Brand>

        <Nav className="menu flex-basis-100 sm-flex-basis-auto justify-content-center">
          <Menu />
        </Nav>

        <Nav className="ml-auto d-none flex-basis-100 sm-flex-basis-auto justify-content-end d-lg-flex align-items-center">
          {token ? (
            <Button
              onClick={handleOpenModal}
              variant="info"
              className="bg-transparent btn-no-shadow p-0"
            >
              <span className="text-md">
                {token && name && t("greetUser", { username: name })}{" "}
              </span>
              <img src={avatar} className="profile-pic m-1" />
            </Button>
          ) : (
            <Button variant="info" className="bg-transparent btn-no-shadow p-0">
              <Nav.Link href={`${middlewareUrl}/auth/steam`}>
                <img
                  src="/images/steam_button.png"
                  alt="steam logo"
                  className="steam-image"
                />
              </Nav.Link>
            </Button>
          )}
        </Nav>

        <Navbar.Toggle
          className="navbar-toggler"
          aria-controls="nav-controls"
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="bi bi-list toggle-navigation"></i>
        </Navbar.Toggle>

        <div className={windowWidth >= 992 ? "d-none" : "d-flex"} ref={navRef}>
          <Navbar.Collapse id="nav-controls" in={isOpen}>
            <Nav className="me-auto">
              {isOpen && (
                <Button
                  variant="info"
                  className="top-0 py-3 end-0 position-absolute  bg-transparent"
                  onClick={() => setIsOpen(false)}
                >
                  <FontAwesomeIcon size="xl" icon={faTimes} />
                </Button>
              )}
              <div
                className="container d-flex flex-column justify-content-between"
                style={{ minHeight: "95vh" }}
              >
                <div className="row flex-fill">
                  <div className="col">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <img src="/images/logo_big_z.png" alt="Logo" />
                    </div>
                  </div>
                </div>
                <div className="row flex-fill">
                  <div className="col">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <div className="welcome-text">{t("welcome")}</div>
                    </div>
                  </div>
                </div>
                <div className="row flex-fill">
                  <div className="col">
                    <div className="h-100 d-flex align-items-end justify-content-center">
                      <div className="row justify-content-center">
                        <div className="row">
                          {token ? (
                            <Button
                              onClick={handleOpenModal}
                              variant="info"
                              className="bg-transparent btn-no-shadow p-0"
                            >
                              <span className="text-md">
                                {t("greetUser", { username: name })}{" "}
                              </span>
                              <img src={avatar} className="profile-pic m-1" />
                            </Button>
                          ) : (
                            <Button className="login-button mb-5">
                              <Nav.Link
                                className="text-uppercase"
                                href={`${middlewareUrl}/auth/steam`}
                              >
                                {t("logIn")}
                              </Nav.Link>
                            </Button>
                          )}
                        </div>
                        <div className="row justify-content-center text-center bottom-text">
                          <p>{t("copyright")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
}

export default Navigation;
