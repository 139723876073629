import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
import { useSelector } from "react-redux";
import { selectVoucher } from "../../store/slices/voucheSlice";
import { TowerState, selectTower } from "../../store/slices/towerSlice";
import axiosInstance from "../../util/axios";
import apiRequest from "../../util/apiUtil";
function TicketForm() {
  const { t } = useTranslation();
  const { open } = useSelector(selectVoucher);
  const { publicHash, secret, ticket, clientSeed } = useSelector(selectTower);

  const [fetching, setFetching] = useState(false);
  const [formValues, setFormValues] = useState<TowerState>({
    ticket: [],
    publicHash: "",
    clientSeed: "",
    secret: "",
  });

  useEffect(() => {
    if (publicHash) {
      setFormValues(() => ({
        publicHash,
        secret,
        ticket,
        clientSeed,
      }));
    }
  }, [publicHash, secret, ticket, clientSeed]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setFetching(true); // Start fetching
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("/towers/verifyFairness", { ...formValues }),
        successMessage: t("fairnessVerified"),
      });
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  if (!open) {
    return <React.Fragment />;
  }
  return (
    <Row className="no-gutters mb-5 fade-in-fast">
      <Col className="p-0" xs={12} md={7}>
        <Form
          onSubmit={handleSubmit}
          className="form-verify text-left d-flex flex-column gap-2 md-gap-3 bg-purple-shade p-4 "
        >
          <Row>
            <h4 className="text-golden text-uppercase text-left">
              {t("ticketname")}
            </h4>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="hash">
                <Form.Label>{t("hash")}</Form.Label>
                <Form.Control
                  name="publicHash"
                  value={formValues.publicHash}
                  onChange={handleChange}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="serverSeed">
                <Form.Label>{t("serverSeed")}</Form.Label>
                <Form.Control
                  name="secret"
                  value={formValues.secret}
                  onChange={handleChange}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md>
              <Form.Group controlId="clientSeed">
                <Form.Label>{t("clientSeed")}</Form.Label>
                <Form.Control
                  name="clientSeed"
                  value={formValues.clientSeed}
                  onChange={handleChange}
                  type="text"
                />
              </Form.Group>
            </Col>
            {/* <Col xs={12} md={3}>
              <Form.Group controlId="result">
                <Form.Label>{t("result")}</Form.Label>
                <Form.Control
                  name="ticket"
                  onChange={handleChange}
                  value={
                    formValues.ticket?.length > 0
                      ? JSON.stringify(formValues.ticket)
                      : ""
                  }
                  type="text"
                />
              </Form.Group>
            </Col> */}
            <Col xs={12} md={6} className="d-flex align-items-end">
              <Button
                disabled={fetching}
                className="text-uppercase w-100"
                type="submit"
                variant="secondary"
              >
                {t("verify")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col className="p-0" xs={12} sm={5}>
        <Form className="form-verify d-flex flex-column gap-2 md-gap-3 text-left bg-secondary-light p-4">
          <Row>
            <h4 className="text-golden text-uppercase text-left">
              {t("nextTicket")}
            </h4>
          </Row>

          <Row>
            <Form.Group controlId="hash">
              <Form.Label>{t("hash")}</Form.Label>
              <Form.Control readOnly value={publicHash} type="text" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group controlId="clientSeed">
              <Form.Label>{t("clientSeed")}</Form.Label>
              <Form.Control readOnly type="text" />
            </Form.Group>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default TicketForm;
