import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, Button } from "react-bootstrap";
import { selectVoucher, setOpen } from "../../store/slices/voucheSlice";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const AccordionComponent: React.FC = () => {
  const { open } = useSelector(selectVoucher);
  const dispatch = useDispatch();

  const toggleAccordion = () => {
    dispatch(setOpen(!open));
  };
  const { t } = useTranslation();
  return (
    <Accordion activeKey={open ? "0" : undefined}>
      <Button
        className={`w-100 verify-button rounded-top-0 ${
          !open ? " bg-transparent" : "btn-secondary"
        }`}
        variant="button"
        onClick={toggleAccordion}
        style={{ color: "white" }}
      >
        <span className="text-white ml-auto mx-2">
          {t(!open ? "verifyTicket" : "hideVerification")}
        </span>
        <FontAwesomeIcon
          icon={!open ? faChevronDown : faChevronUp}
          className={`${open ? "open" : ""}`}
          style={{ color: "white" }}
        />
      </Button>
    </Accordion>
  );
};

export default AccordionComponent;
