import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
interface PaginationComponentProps {
  onPageChange: (pageNumber: number) => void;
  hasNext: boolean;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  onPageChange,
  hasNext,
}) => {
  const [page, setPage] = useState<number>(1);

  const handlePrevClick = () => {
    const newPage = page - 1;
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleNextClick = () => {
    const newPage = page + 1;
    setPage(newPage);
    onPageChange(newPage);
  };
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-start pagination-div">
      <Pagination size="lg" className="d-flex gap-2">
        <Pagination.Prev disabled={page === 1} onClick={handlePrevClick}>
          {t("prev")}
        </Pagination.Prev>
        <Pagination.Item active>{page}</Pagination.Item>
        <Pagination.Next disabled={!hasNext} onClick={handleNextClick}>
          {t("next")}
        </Pagination.Next>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
