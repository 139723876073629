import React, { useEffect, useState } from "react";

import "./index.css";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import CustomInput from "../Inputs";

import axiosInstance from "../../util/axios";
import apiRequest from "../../util/apiUtil";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/userSlice";
import CustomStatistics from "../Statistics/Custom";
import { MonkeyLevel, MonkeyLevelType } from "../../types";
import Monkey from "../Referral/Monkey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

interface ReferalStats {
  earningsRefCurrent: number;
  earningsRefTotal: number;
  totalReferred: number;
}

const monkeyLevels: MonkeyLevel[] = [
  {
    min: 0,
    max: 50,
    percent: 0.25,
    title: "Baby",
    level: 1,
  },
  {
    min: 50,
    max: 150,
    percent: 0.5,
    title: "Savage",
    level: 2,
  },
  {
    min: 150,
    max: 500,
    percent: 0.75,
    title: "Major",
    level: 3,
  },
  {
    min: 500,
    max: 99999999,
    percent: 1,
    title: "Harambe",
    level: 4,
  },
];
const AffiliateModal: React.FC = () => {
  const { t } = useTranslation();
  const { code, referredByCode } = useSelector(selectUser);

  const [level, setLevel] = useState<MonkeyLevelType>(1);

  const [stats, setStats] = useState<ReferalStats>({
    earningsRefCurrent: 0,
    earningsRefTotal: 0,
    totalReferred: 0,
  });

  const { totalReferred, earningsRefTotal } = stats;
  const [referalCode, setReferalCode] = useState<string>(referredByCode || "");
  const [affCode, setAffCode] = useState<string>(code || "");

  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setLevel(
      monkeyLevels
        .slice()
        .reverse()
        .find((monkey) => totalReferred > monkey.min && monkey)?.level || 1,
    );
  }, [totalReferred]);

  useEffect(() => {
    const fetchReferalData = async () => {
      try {
        const data = await apiRequest({
          axiosFunction: () => axiosInstance.post("/user/affdata"),
        });
        setStats(data.data);
      } catch (error) {
        console.error("Error during API request:", error);
      }
    };

    fetchReferalData();
  }, []);

  const updateCode = async () => {
    setFetching(true);
    try {
      await apiRequest({
        axiosFunction: () => axiosInstance.post(`/user/updateCode/${affCode}`),
        errorMessage: t("errors.createCode"),
        successMessage: t("success.affCode"),
      });
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };
  const dispatch = useDispatch();
  const redeemCode = async () => {
    setFetching(true);
    try {
      await apiRequest({
        axiosFunction: () => axiosInstance.post(`/user/useCode/${referalCode}`),
        errorMessage: t("errors.redeemCode"),
        successMessage: t("success.redeemCode"),
      });

      const data = await apiRequest({
        axiosFunction: () => axiosInstance.post("user"),
      });

      dispatch(setUser(data.user));
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const collectEarnings = async () => {
    setFetching(true);
    try {
      apiRequest({
        axiosFunction: () => axiosInstance.post(`/user/collectEarnings`),
        errorMessage: t("errors.collectEarnings"),
        successMessage: t("success.collectEarnings"),
      });
      setStats({
        ...stats,
        earningsRefCurrent: 0,
      });
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  return (
    <Row className="mt-4 gap-3 gap-md-0">
      <Col sm={12} md={12}>
        <div className="text-center d-flex justify-content-center align-items-center gap-2 mb-5">
          <FontAwesomeIcon
            className="ref-icon p-3 rounded-full"
            icon={faHandshake}
          />
          <h6 className="text-uppercase mb-0 strong-white">
            {<Trans i18nKey={"invitePeopleEarnMoney"} />}
          </h6>
        </div>
      </Col>
      <Col sm={12} md={6}>
        <CustomInput
          disabledBtn={fetching || !referalCode}
          onClick={redeemCode}
          value={referalCode}
          onChange={setReferalCode}
          readOnly={!!referredByCode}
          label={t("forms.redeemCode")}
          name={"redeemCode"}
          buttonText={t("redeem")}
        />
      </Col>

      <Col sm={12} md={6}>
        <CustomInput
          value={affCode}
          onChange={setAffCode}
          disabledBtn={fetching || !affCode || affCode === code}
          onClick={updateCode}
          label={t("forms.createYourCode")}
          name={"createYourCode"}
          buttonText={t("create")}
        />
      </Col>

      <Col sm={12} md={6} className="mt-4">
        <CustomInput
          readOnly
          disabledInput
          disabledBtn={fetching || stats.earningsRefCurrent < 1}
          label={t("forms.collect")}
          name={"createYourCode"}
          onClick={collectEarnings}
          value={stats.earningsRefCurrent?.toFixed(2)}
          buttonText={t("collect")}
        />
      </Col>

      <Col md={6}></Col>

      {monkeyLevels.map((monkey) => {
        const isCurrentLevel: boolean = monkey.level === level;
        return (
          <Col
            className={`mt-4 mb-4 px-0    ${
              isCurrentLevel ? "d-block" : "d-none d-md-block"
            }`}
            key={monkey.level}
            md={3}
          >
            <Monkey
              currentLevel={level}
              name={monkey.title}
              level={monkey.level}
            />
          </Col>
        );
      })}

      <Col sm={12} className="mt-4">
        <CustomStatistics
          label1="usersReferred"
          value1={totalReferred}
          label2="totalEarnedCoins"
          value2={earningsRefTotal}
        />
        <div className="mt-3">
          <p className="text-center">
            {<Trans i18nKey={"forms.postReedem"} />}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default AffiliateModal;
