// src/reducers/socketSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SocketState {
  messages: Record<string, string[]>; // Using an object to store different event messages
}

const initialState: SocketState = {
  messages: {},
};

// todo: tidy up socket actions, do not harvest arrays of data where you can simply take the latest value and keep in redux 
// todo: define ws types and specific selectors for specific values
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<{ event: string; message: string }>) => {
      const { event, message } = action.payload;
      if (!state.messages[event]) {
        state.messages[event] = [];
      }
      
      // Push the new message
      state.messages[event].push(message);
  
      // Check if the number of messages exceeds 100
      if (state.messages[event].length > 1500) {
        // Slice the messages from index 50 to 100
        state.messages[event] = state.messages[event].slice(1000, 1500);
      }
    },
  },
})

export const { addMessage } = socketSlice.actions;
export default socketSlice.reducer;
