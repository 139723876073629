// towerSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TowerState {
  ticket: number[];
  publicHash: string;
  clientSeed: string;
  secret: string;
}

const initialState: TowerState = {
  ticket: [],
  publicHash: '',
  clientSeed: '',
  secret: '',
};

const towerSlice = createSlice({
  name: 'tower',
  initialState,
  reducers: {
    setTicket: (state, action: PayloadAction<number[]>) => {
      state.ticket = action.payload;
    },
    setPublicHash: (state, action: PayloadAction<string>) => {
   
      Object.assign(state, initialState, { publicHash: action.payload });
    },
    setClientSeed: (state, action: PayloadAction<string>) => {
      state.clientSeed = action.payload;
    },
    setSecret: (state, action: PayloadAction<string>) => {
      state.secret = action.payload;
    },
    setTower: (state, action: PayloadAction<Partial<TowerState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setTicket, setPublicHash, setClientSeed, setSecret, setTower } = towerSlice.actions;

export const selectTower = (state: { tower: TowerState }) => state.tower;

export default towerSlice.reducer;
