import { toast } from 'react-toastify';
import  {  AxiosResponse } from 'axios'; // Import axios here
import { URLMAP } from './const';

type AxiosFunction<T> = () => Promise<AxiosResponse<T>>;

interface HandleApiRequestParams<T> {
  axiosFunction: AxiosFunction<T>;
  successMessage?: string;
  errorMessage?: string;
}


const BAN_MSG = 'User not found'
const apiRequest = async <T>(params: HandleApiRequestParams<T>): Promise<T> => {
  try {
    const response = await params.axiosFunction();
    params.successMessage && toast.success(params.successMessage);
    return response?.data;
       // eslint-disable-next-line
  } catch (error : any) {
    
    if(error?.response?.data && error?.response?.data===BAN_MSG && !window.location.href.includes('banned')){
      // also for invalid tokens the same
      // due to BE architecture of rotating tokens post-ban receivement
      window.location.href = `${window.location?.href}${URLMAP.BANNED.replace('/', '')}`
    }
    
    params.errorMessage &&  console.error("Error during API request:", error); // Log only the error message for non-Axios errors

    toast.error(typeof error?.data?.response?.data === 'string' && error?.response?.data  || error?.response?.data?.msg || error?.response?.data?.message || params.errorMessage);
    throw error; // Rethrow the error to be handled by the calling code
  }
};

export default apiRequest;
