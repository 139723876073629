import React, { useEffect, useState } from "react";
import "./index.css";
import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { setUser } from "../../../store/slices/userSlice";
import { useDispatch } from "react-redux";

interface SpinItem {
  color: string;
  image: string;
  name: string;
  percentage: number;
  price: number;
}

interface WinItem extends SpinItem {
  chance: number[];
}

const COIN_IMG = "/images/coin.png";
const DEMO_MODE = false;
const SpinModal: React.FC = () => {
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  const [data, setData] = useState<SpinItem[]>([]);
  const [animationEnded, setAnimationEnded] = useState<boolean>(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [winItem, setWinItem] = useState<WinItem>({
    color: "#",
    image: "",
    name: "",
    percentage: 0,
    price: 0,
    chance: [],
  });
  const dispatch = useDispatch();
  const handleSpin = async () => {
    try {
      setFetching(true);
      let request = {
        winningItem: {
          image: "coins",
          name: "Coins 400",
          percentage: 20,
          price: 400,
          chance: [1],
          color: "",
        },
      };
      if (!DEMO_MODE) {
        request = await apiRequest({
          axiosFunction: () => axiosInstance.post("/dailycase/open"),
        });
      }
      setSpinning(true);
      const audioElement = new Audio("audio/daily-spin2.mp3");
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
      setWinItem(request.winningItem);

      const data = await apiRequest({
        axiosFunction: () => axiosInstance.post("user"),
      });

      dispatch(setUser(data.user));
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const handleAnimationEnd = () => {
    setAnimationEnded(true);
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = await apiRequest({
          axiosFunction: () => axiosInstance.get("/dailycase"),
        });
        setData(request);
      } catch (error) {
        console.error("Error during API request:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, []);

  const distributeItems = (items: SpinItem[]): SpinItem[] => {
    const coins = items.filter((item) => item.name.includes("Coins"));
    const skins = items.filter((item) => !item.name.includes("Coins"));

    const distributedItems: SpinItem[] = [];

    for (let i = 0; i < 8; i++) {
      // Add 8 coins
      for (let j = 0; j < 8; j++) {
        distributedItems.push(coins[0]); // Assuming there's only one type of coins based on the provided data
      }
      // Add 3 skins distributed among the coins
      for (let j = 0; j < 3; j++) {
        // Calculate the indices to insert skins among coins
        const coinIndex = i * 11 + j * 3; // Start index of coins block
        const skinIndex = coinIndex + 1 + j; // Position of skin after j-th coin

        distributedItems.splice(skinIndex, 0, skins[j % skins.length]);
      }
    }
    const shuffleArray = (array: SpinItem[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    // Randomize the distributed items
    shuffleArray(distributedItems);
    return distributedItems;
  };

  const [mutatedData, setMutatedData] = useState<SpinItem[]>([]);

  useEffect(() => {
    setMutatedData(data.length > 0 ? distributeItems(data) : []);
  }, [data]);

  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column gap-3 md=gap-5 spin-modal-body h-100">
      <div className="text-center">
        <p>{t("dailyRewardsDesc")}</p>
        <div>
          <h5 className="text-golden">{t("warning")}</h5>
          <p>{t("spinWarning")}</p>
        </div>
      </div>
      {(!winItem?.name && !spinning) || (winItem?.name && spinning) ? (
        <div className="spin-container">
          <div
            className="spin-bar overflow-hidden gap-3"
            onAnimationEnd={handleAnimationEnd}
            style={{ animation: spinning ? "spin 7.3s ease forwards" : "" }}
          >
            {mutatedData.map((item, index) => {
              const isWinner: boolean = !!winItem?.name && index === 73;
              let image: string = "";

              if (isWinner) {
                if (winItem.name.includes("Coins")) {
                  image = COIN_IMG;
                } else {
                  image = item.image;
                }
              } else {
                image = item?.image?.toLowerCase().includes("coins")
                  ? COIN_IMG
                  : item.image;
              }

              return (
                <div
                  key={index}
                  id={`item-${index}`}
                  className={`spin-item transition-all d-flex justify-content-center align-items-center border border-2 rounded-md border-primary p-3 ${
                    isWinner && !animationEnded
                      ? "bg-secondary-light"
                      : "bg-secondary-light"
                  }`}
                >
                  <img className="spin-image" src={image} alt={item.name} />
                </div>
              );
            })}
          </div>
          {spinning && <div className="bar fade-in-fast"></div>}
        </div>
      ) : (
        <div className="text-center text-uppercase fade-in-fast d-flex flex-column gap-3">
          <div>
            <h4 className="">{t("yourPrizeIs")}</h4>
            <h4 className="text-white fw-bold">
              {winItem.name === "Coins"
                ? `${winItem.price} ${winItem.name}`
                : winItem.name}
              !
            </h4>
          </div>
          {/* <p className="px-5">{t("winSpinWarning")}</p> */}
        </div>
      )}

      {!winItem.name && (
        <div className="mx-auto">
          <Button
            disabled={data?.length < 1}
            onClick={handleSpin}
            className="spin-button px-4 text-uppercase fw-bold"
          >
            {t("spin")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SpinModal;
