import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { URLMAP, tabTitleClassName } from "../../../util/const";
import { Link } from "react-router-dom";
import TabTable from "../TabTable";
import { TableData } from "../../../types";
import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";
import { formatHeroesHistory } from "../../../util/helpers";

function Heores() {
  const [key, setKey] = useState<string>("daily");

  const { t } = useTranslation();
  const [weeklyData, setWeeklyData] = useState<TableData[]>([]);
  const [dailyData, setDaily] = useState<TableData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [weeklyResponse, dailyResponse] = await Promise.all([
          apiRequest({
            axiosFunction: () => axiosInstance.get("/heroes/weekly"),
          }),
          apiRequest({
            axiosFunction: () => axiosInstance.get("/heroes/daily"),
          }),
        ]);

        const weeklyData = weeklyResponse.map(formatHeroesHistory);
        const dailyData = dailyResponse.map(formatHeroesHistory);

        setWeeklyData(weeklyData);
        setDaily(dailyData);
      } catch (error) {
        console.error("Error during API request:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="">
      <div className="d-flex heroes justify-content-between align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <img src="/images/trophy.png" alt="Trophy" />
          <h6 className="text-center text-uppercase text-golden  m-0">
            {t("hallOfHeroes")}
          </h6>
        </div>
        <Link to={URLMAP.BONUSES}>
          <Button size="sm" variant="secondary" className={" text-uppercase"}>
            {t("bonuses")}
          </Button>
        </Link>
      </div>
      <Tabs
        id="tab-switcher"
        activeKey={key}
        onSelect={(k) => setKey(String(k))}
        className="d-flex full-tabs px-0 active-gold justify-content-around"
      >
        <Tab
          eventKey="daily"
          title={<span className={tabTitleClassName}>{t("dailyTab")}</span>}
        >
          <TabTable data={dailyData} />
        </Tab>

        <Tab
          eventKey="weekly"
          title={<span className="text-uppercase">{t("weeklyTab")}</span>}
        >
          <TabTable data={weeklyData} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Heores;
