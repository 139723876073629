import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface Props {
  goBack: () => void;
}

const BackButton: React.FC<Props> = ({ goBack }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={goBack}
      variant="secondary"
      className="secondary text-uppercase text-white d-flex gap-2 align-items-center justify-content-center"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      {t("back")}
    </Button>
  );
};

export default BackButton;
