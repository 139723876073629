import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
import { useLocation } from "react-router-dom";
import { URLMAP } from "../../../util/const";
export type GameName = "TOWERS" | "PVP" | "COLORS";
export interface Game {
  name: GameName;
  disabled: boolean;
}

interface GameCardProps {
  game: Game;
  onClick: () => void;
}

const GameCard: React.FC<GameCardProps> = ({ game, onClick }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const active: boolean = pathname === URLMAP[game.name];

  return (
    <Card
      onClick={onClick}
      className={` cursor-pointer mx-auto  ${active ? "active" : ""}`}
      style={{
        backgroundImage: `url(images/gameselect/${game.name.toLowerCase()}-bg.png)`,
      }}
    >
      <Card.Body className="d-flex flex-column justify-content-between align-items-center">
        <div className="flex-grow-1 d-flex align-items-center">
          {/* Icon in the middle of the card body */}
          <img src={`/images/gameselect/${game.name.toLowerCase()}-ic.png`} />
        </div>

        <Card.Title className="mt-2">
          <h4 className="fw-bold text-golden text-uppercase text-center">
            {t(game.name)}
          </h4>
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default GameCard;
