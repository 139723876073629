import React from "react";
import { Trans } from "react-i18next";
import { COINS_PER_DOLLAR } from "../../../util/const";

interface BalanceProps {
  balance: number;
}

const Balance: React.FC<BalanceProps> = ({ balance }) => {
  const formattedCoins = new Intl.NumberFormat().format(balance);

  return (
    <div className="text-white d-flex justify-content-center justify-content-md-end align-items-center gap-1 text-center">
      <Trans
        i18nKey="yourBalance"
        values={{
          coins: formattedCoins,
          dollars: (balance / COINS_PER_DOLLAR).toFixed(2),
        }}
        components={[
          <span className="fw-bold text-golden" key="coins">
            {balance.toFixed(0)}
          </span>,
          <span className="fw-bold text-golden" key="dollars">
            {(balance / COINS_PER_DOLLAR).toFixed(2)}
          </span>,
        ]}
      />
      <img src="./images/balance.svg" alt="Balance" />
    </div>
  );
};

export default Balance;
