import React, { useState, useRef, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

const audioSource = "audio/background.mp3";

function SoundButton() {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [isSoundOn, setIsSoundOn] = useState(() => {
    const savedSoundState = localStorage.getItem("isSoundOn");
    return savedSoundState ? savedSoundState === "true" : true;
  });

  const handleToggleSound = () => {
    setIsSoundOn(!isSoundOn);
  };

  useEffect(() => {
    const audio = audioRef.current!;
    const handleMouseClick = () => {
      if (isSoundOn && audio.paused) {
        audio.play().catch((error) => console.error("Autoplay error:", error));
      }
    };
    window.addEventListener("click", handleMouseClick);

   
    audio.volume = 0.25;

    // Save state to localStorage whenever it changes
    localStorage.setItem("isSoundOn", String(isSoundOn));

    return () => {
      window.removeEventListener("click", handleMouseClick);
    };
  }, [isSoundOn]);

  return (
    <>
      <audio
        ref={audioRef}
        muted={!isSoundOn}
        loop
        src={audioSource}
        autoPlay={isSoundOn}
      />
      <Button
        variant="info"
        className="sound-button bg-transparent"
        onClick={handleToggleSound}
      >
        <FontAwesomeIcon
          className=""
          icon={isSoundOn ? faVolumeUp : faVolumeMute}
        />
      </Button>
    </>
  );
}

export default SoundButton;
