import React from "react";
import { Col, Row } from "react-bootstrap";

import { useSelector } from "react-redux";
import { selectModal } from "../../store/slices/modalSlice";
import { GameSkin } from "../../types";
import SkinItem from "../SkinItem";

const SkinModal: React.FC = () => {
  const { data } = useSelector(selectModal);

  return (
    <>
      <Row className="mt-0 gap-3 gap-md-0">
        <Row className="pb-2">
          {/* eslint-disable-next-line */}
          {data?.map((skin: GameSkin, index: number) => (
            <Col className="mt-4" key={index} xs={6} sm md lg={3}>
              <SkinItem
                float={skin.float}
                name={skin.name}
                image={skin.image}
                price={skin.price}
                id={skin.id}
                isSelected
              />
            </Col>
          ))}
        </Row>
      </Row>
    </>
  );
};

export default SkinModal;
