import React from "react";
import { useTranslation } from "react-i18next";
import { MethodType, PaymentMethodType } from "../../types";
import { Button } from "react-bootstrap";
import { getCryptoIcon } from "../../util/helpers";

interface DepositItemProps {
  method: MethodType;
  category: PaymentMethodType;
  isCrypto: boolean;
  onClick: (category: PaymentMethodType, method: MethodType) => void;
  key?: string | number;
}

const DepositItem: React.FC<DepositItemProps> = ({
  method,
  isCrypto,
  onClick,
  category,
 
}) => {
  const { t } = useTranslation();
  return (
    <div
     
      className="d-flex sm-flex-1 flex-column gap-1 text-center fw-bold"
    >
      <Button
        onClick={() => onClick(category, method)}
        className="p-0"
        variant="bg-transparent"
      >
        <img
          className="rounded-md transition-all border border-1 bg-win border-primary p-2"
          style={{ height: "60px", maxWidth: "100%" }}
          src={
            !isCrypto
              ? `/images/${method.toLowerCase()}.png`
              : getCryptoIcon(method.toUpperCase())
          }
          alt={method}
        />
      </Button>
      <span className="depo-label text-capitalize">{t(method)}</span>
    </div>
  );
};

export default DepositItem;
