import { useEffect, useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation
import {
  TABLE_LIMIT,
  TOWER_ACTIONS,
  WS_EVENTS,
  tabTitleClassName,
} from "../../../util/const";
import TabTable from "../TabTable";
import { BetData, TableData, WebsocketAction } from "../../../types";
import CountUp from "react-countup";
import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { formatGamesHistory } from "../../../util/helpers";
const recentPlaysRequest = {
  axiosFunction: () =>
    axiosInstance.post("/towers/gethistory", { limit: TABLE_LIMIT }),
};
function TowerInfo() {
  const [recentWins, setRecentWins] = useState<TableData[]>([]);
  const [playedGames, setPlayedGames] = useState<TableData[]>([]);
  const { token } = useSelector(selectUser);

  const towerMsgs = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.onTower] || [],
  );

  const towersHistoryArr: BetData[] = towerMsgs
    ?.slice()
    .reverse()
    .find((t: WebsocketAction) => t.action === TOWER_ACTIONS.history)?.history;

  const playerTowersHistory: BetData[] = towerMsgs
    ?.slice()
    .reverse()
    .find(
      (t: WebsocketAction) => t.action === TOWER_ACTIONS.playerTowersHistory,
    )?.playerTowersHistory;

  useEffect(() => {
    if (playerTowersHistory) {
      setPlayedGames(
        playerTowersHistory.slice(0, TABLE_LIMIT).map(formatGamesHistory),
      );
    }
  }, [playerTowersHistory]);

  useEffect(() => {
    towersHistoryArr &&
      setRecentWins(
        towersHistoryArr.slice(0, TABLE_LIMIT).map(formatGamesHistory),
      );
  }, [towersHistoryArr]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiRequest(recentPlaysRequest);

        setRecentWins(data.all.map(formatGamesHistory));
        setPlayedGames(data.user.map(formatGamesHistory));
      } catch (error) {
        console.error("Error during API request:", error);
      }
    };

    token && recentWins?.length < 1 && fetchData();
  }, [token]);

  const [key, setKey] = useState<string>("recent");

  const { t } = useTranslation(); // Initialize the useTranslation hook

  const totalGamesArr = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.totalGames] || 0,
  );
  const wageredCoinsArr = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.totalWagered] || 0,
  );

  let totalGames,
    wageredCoins = 0;
  if (totalGamesArr) {
    totalGames = totalGamesArr.slice().reverse()[0];
  }
  if (wageredCoinsArr) {
    wageredCoins = wageredCoinsArr.slice().reverse()[0];
  }

  return (
    <div className="game-left-column">
      <Row>
        <Button
          className="btn-success text-white text-uppercase d-none"
          size="lg"
          variant="success"
        >
          {t("youtubersWanted")}
        </Button>
      </Row>
      <Row className="p-2 text-golden bg-secondary statistics">
        <Col className="d-flex flex-column justify-content-center align-items-center total-plays">
          <div className="counter-text">
            {<CountUp end={totalGames} start={totalGames - 4} duration={2} />}
          </div>
          <div className="counter-title">{t("gamesPlayed")}</div>
        </Col>
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <div className="counter-text">
            {" "}
            {
              <CountUp
                end={wageredCoins}
                start={wageredCoins > 0 ? wageredCoins - 100 : wageredCoins}
                duration={2}
              />
            }
          </div>
          <div className="counter-title">{t("wageredCoins")}</div>
        </Col>
      </Row>
      <Row className="mt-3 d-flex  flex-column">
        <Tabs
          id="tab-switcher"
          activeKey={key}
          onSelect={(k) => setKey(String(k))}
          className=" py-3 px-2 justify-content-around  rounded-top-md bg-secondary"
        >
          <Tab
            eventKey="recent"
            title={<span className={tabTitleClassName}>{t("recentTab")}</span>}
          >
            <TabTable data={recentWins} />
          </Tab>
          <Tab
            eventKey="myplays"
            title={<span className={tabTitleClassName}>{t("myPlaysTab")}</span>}
          >
            <TabTable data={playedGames} />
          </Tab>
          {/* <Tab
            eventKey="bigwins"
            title={<span className={tabTitleClassName}>{t("bigWinsTab")}</span>}
          >
            <TabTable data={recentWins} />
          </Tab> */}
        </Tabs>
      </Row>
    </div>
  );
}

export default TowerInfo;
