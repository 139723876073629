import React from "react";
import { MonkeyLevelType, MonkeyType } from "../../../types";
import { ProgressBar } from "react-bootstrap";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
interface MonkeyProps {
  name: MonkeyType;
  level: MonkeyLevelType;
  currentLevel: MonkeyLevelType;
}

const Monkey: React.FC<MonkeyProps> = ({ name, level, currentLevel }) => {
  const imageSrc = `/images/${name.toLowerCase()}.png`;
  const completedLevel: boolean = currentLevel >= level;
  const { t } = useTranslation();
  return (
    <div
      className={`monkey-referal-div 
       
      `}
    >
      <img src={imageSrc} alt={`${name} Monkey`} />
      <div className="level_text_block">
        <p className="monkey_name">{name}</p>
        <span className="level_title">
          {t("level")} {level}
        </span>
      </div>
      <div className="progress-bar-div mt-2 d-none d-md-block">
        <img
          id={`${name}-monkey`}
          className="icon-img"
          src={`./images/${completedLevel ? "checked" : "unchecked"}.png`}
        />
        <Tooltip
          anchorSelect={`#${name}-monkey`}
          place="bottom"
          className="tooltip-button"
        >
          {t(`referrals.${name.toLowerCase()}`)}
        </Tooltip>
        <ProgressBar
          animated
          now={completedLevel ? 100 : 0}
          variant="primary"
        />
      </div>
    </div>
  );
};

export default Monkey;
