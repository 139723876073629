import { useTranslation } from "react-i18next";

const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <div className="purple-gradient  text-justify inner-page-container mb-5">
      <div className="container py-5">
        <h4 className="text-uppercase text-center text-golden">
          {t("footer.privacyPolicy")}
        </h4>
        <hr></hr>
        <p>
          By using csgotower.com you acknowledge and consent to the Service
          provider processing your personal data for the purposes of allowing
          access and usage of csgotower.com and in order to allow participating
          in activities provided at csgotower.com. Your personal data will be
          used only to allow you to participate in the activities provided at
          csgotower.com and for the purposes of carrying out verification
          procedures in relation to activities at csgotower.com. The only
          information that csgotower.com collects is the Trading URL and Steam
          ID of the user account.
        </p>

        <p>
          Your personal data will not be disclosed to any third parties, unless
          such disclosure is necessary for processing your requests, to carry
          verification procedures or unless it is required by law. You have the
          right to access personal data held by the Service provider about you.
          You hereby consent and undertake to immediately inform the Service
          provider about changes of the personal data or other provided
          information.
        </p>
        <p>
          The Service provider collects a small piece of information sent from
          browser (cookies) which might be turned off. However, turning off
          cookies may restrict the use of csgotower.com. By accepting the Terms,
          you consent to the Service provider informing you from time to time
          about changes on csgotower.com, new services and promotions.
        </p>
      </div>
    </div>
  );
};
export default PrivacyPage;
