import React, { useEffect, useState } from "react";

import apiRequest from "../../util/apiUtil";
import axiosInstance from "../../util/axios";

import PaginationComponent from "../Pagination";
import TabTable from "../Tables/TabTable";

import { useTranslation } from "react-i18next";
import { PaginationType, Transaction } from "../../types";
import { DEFAULT_ITEMS_PER_PAGE } from "../../util/const";
import { transformData } from "../../util/helpers";

interface TransactionResponse extends PaginationType {
  data: Transaction[];
}

interface CoinModalProps {
  steamIdProps?: string;
}

const CoinsModal: React.FC<CoinModalProps> = ({ steamIdProps }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const [data, setData] = useState<TransactionResponse>({
    data: [],
    total: 0,
    page: 0,
    per_page: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const request = await apiRequest({
          axiosFunction: () =>
            axiosInstance.post(
              steamIdProps ? `/coinsHistory/${steamIdProps}` : "/coinsHistory",
              {
                page: page,
                per_page: DEFAULT_ITEMS_PER_PAGE,
                sortOrder: "asc",
              },
            ),
        });

        setData(request);
      } catch (error) {
        console.error("Error during API request:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [page]);
  const transformedData = transformData(data?.data, t) || [];
  return (
    <div>
      <div className="d-flex flex-column flex-col gap-3 py-3">
        <TabTable data={transformedData} />

        {data?.data?.length > 0 && (
          <PaginationComponent
            hasNext={data.total >= page * DEFAULT_ITEMS_PER_PAGE}
            onPageChange={setPage}
          />
        )}
      </div>
    </div>
  );
};

export default CoinsModal;
