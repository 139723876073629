import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./index.css";
import { Container } from "react-bootstrap";
import { URLMAP } from "../../util/const";
import { setLoggedIn } from "../../store/slices/userSlice";

const VerifyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const handleVerification = async () => {
      try {
        if (token) {
          localStorage.setItem("token", token);

          dispatch(setLoggedIn(token));

          navigate(URLMAP.HOME);
          // This is due to balance bug post-login
          window.location.reload();
        } else {
          // TODO: CONSIDER ERROR PAGE CREATION
          console.error("Token not found in the URL");
          navigate(URLMAP.HOME);
        }
      } catch (error) {
        console.error("Error during token verification:", error);
        // Handle error and navigate to an error page or perform other actions
        // navigate(URLMAP.ERROR);
      }
    };

    handleVerification();
  }, [token, navigate, dispatch]);

  return <Container className="container-page"></Container>;
};

export default VerifyPage;
