import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameSkin } from '../../types';

export interface ModalState {
  showModal: boolean;
  modalHeader: React.ReactNode | null;
  modalContent: React.ReactNode | null;
  modalFooter: React.ReactNode | null;
  activeTab?: string;
  data?: GameSkin[] | null;
  className?: string;
  showIfLoggedOut?: boolean; // New optional property
}

const initialState: ModalState = {
  showModal: false,
  modalHeader: null,
  modalContent: null,
  modalFooter: null,
  activeTab: '',
  className: '',
  showIfLoggedOut: false, // Default value
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<
        {
          modalHeader?: React.ReactNode;
          modalContent?: React.ReactNode;
          modalFooter?: React.ReactNode;
          activeTab?: string;
          data?: GameSkin[];
          className?: string;
          showIfLoggedOut?: boolean; 
        }
      >
    ) => {
      const { modalHeader, modalContent, modalFooter, activeTab, data, className, showIfLoggedOut } = action.payload;

      state.showModal = true;
      state.modalHeader = modalHeader || null;
      state.modalContent = modalContent || null;
      state.modalFooter = modalFooter || null;
      state.activeTab = activeTab || '';
      state.data = data || null;
      state.className = className || '';
      state.showIfLoggedOut = showIfLoggedOut ?? false; 
    },
    openDefaultModal: (state) => {
      state.showModal = true;
    },
    closeModal: (state) => {
      state.showModal = false;
      state.modalHeader = null;
      state.modalContent = null;
      state.modalFooter = null;
      state.activeTab = '';
      state.data = null;
      state.className = '';
      state.showIfLoggedOut = false; 
    },
  },
});

export const { openModal, openDefaultModal, closeModal } = modalSlice.actions;
export const selectModal = (state: { modal: ModalState }) => state.modal;

export default modalSlice.reducer;
