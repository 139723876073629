import { useTranslation } from "react-i18next";
import "./index.css";
const AmlPage = () => {
  const { t } = useTranslation();
  return (
    <div className="purple-gradient  text-justify inner-page-container mb-5">
      <div className="container py-5">
        <h4 className="text-uppercase text-center text-golden">
          {t("amlKycTitle")}
        </h4>
        <hr></hr>
        <h5 className="fw-bold">
          {" "}
          (AML) Anti-Money Laundering Policy Introduction:
        </h5>{" "}
        <p>
          Intech Operations, REG NO. 306709958 is committed to maintaining a
          safe, secure, and compliant platform for our users to engage in
          gaming, lootbox games, and other entertainment activities. As part of
          our commitment, we have implemented an Anti-Money Laundering
          (&ldquo;AML&rdquo;) Policy to prevent our platform from being used for
          illicit activities, including money laundering and terrorist
          financing. Our website, https://csgotower.com, is owned by Intech
          Operations, REG NO. 306709958.
        </p>
        <h5 className="fw-bold"> Know Your Customer (KYC) Procedures:</h5>{" "}
        <p>
          Intech Operations, REG NO. 306709958 is committed to conducting
          thorough and effective KYC procedures to verify the identities of our
          users and prevent money laundering and terrorist financing on our
          platform. Our KYC procedures are designed to be compliant with
          regulatory requirements and are regularly reviewed and updated to
          ensure they are effective.{" "}
        </p>
        {/* <p>
          To ensure compliance with AML regulations, Intech Operations, REG NO.
          306709958 shall conduct KYC checks on all withdrawals from FIAT
          deposits to cryptocurrency in excess of 1000 EUR. When a user requests
          a withdrawal of crypto to FIAT currency on https://csgotower.com, we
          require them to submit the following information:
        </p> */}
        {/* <ul>
          <li>Full name</li>
          <li>Date of birth</li>
          <li>Residential address</li>
          <li>
            Government-issued identification document (passport, national ID
            card, or driver&apos;s license)
          </li>
          <li>
            Proof of address (utility bill, bank statement, or any other
            document showing the user’s name and residential address)
          </li>
        </ul> */}
        <p>
          We remain the right to verify this information through our third-party
          partners.
        </p>
        <h5 className="fw-bold">AML Controls:</h5>
        <p>
          Intech Operations has implemented several AML controls to prevent
          money laundering and terrorist financing on our platform. These
          controls are designed to be compliant with regulatory requirements and
          are regularly reviewed and updated to ensure they are effective.
        </p>
        <ul>
          <li>
            <b> Transaction Monitoring:</b> We monitor all transactions on our
            platform for suspicious activity, including large transactions,
            unusual patterns, and transactions from high-risk countries. Our
            advanced transaction monitoring systems use machine learning
            algorithms and rule-based engines to detect suspicious activity,
            such as transactions involving high-risk countries, high-value
            transactions, and transactions with no apparent economic or lawful
            purpose.
          </li>
          <li>
            <b>Risk Assessment:</b> We perform regular risk assessments to
            identify and mitigate potential AML risks on our platform. We also
            categorize our users based on their AML risk profile and apply
            appropriate AML controls accordingly. Our risk assessment process
            takes into account factors such as the user’s location, transaction
            history, and the type of gaming, lootbox games, and entertainment
            activities they engage in.
          </li>
          <li>
            <b> Employee Training:</b> We provide regular AML training to our
            employees to ensure they are aware of AML risks and understand their
            role in preventing money laundering and terrorist financing on our
            platform. Our training covers topics such as AML regulations, risk
            assessment, and suspicious activity detection.
          </li>
          <li>
            <b>Customer Due Diligence (CDD):</b> We perform customer due
            diligence on all users to verify their identities and assess their
            AML risk. We also conduct enhanced due diligence on high-risk users,
            including politically exposed persons (PEPs) and users from
            high-risk countries. Our CDD procedures are designed to be compliant
            with regulatory requirements and are regularly reviewed and updated
            to ensure they are effective.
          </li>
          <li>
            <b>Enhanced Transaction Monitoring:</b> In addition to our regular
            transaction monitoring, we use advanced transaction monitoring
            systems to detect suspicious activity, such as transactions
            involving high-risk countries, high-value transactions, and
            transactions with no apparent economic or lawful purpose. Our
            enhanced transaction monitoring systems use machine learning
            algorithms and rule-based engines to detect suspicious activity and
            generate alerts for further investigation.
          </li>
          <li>
            <b> Record Keeping:</b> We maintain records of all transactions on
            our platform, including user identification, transaction history,
            and risk assessments. These records are kept for a minimum of five
            years and are designed to be compliant with regulatory requirements.
          </li>
        </ul>
        <h5 className="fw-bold">External Support:</h5>{" "}
        <p>
          In addition to our internal AML controls and procedures, Intech
          Operations engages with external partners to ensure compliance with
          AML regulations. These partners include:
        </p>
        <ul>
          <li>
            <b> Law Enforcement and Regulatory Authorities:</b> We cooperate
            with law enforcement and regulatory authorities in the investigation
            of any suspected money laundering or terrorist financing activities
            on our platform.
          </li>
          <li>
            <b> AML Consultants:</b> We engage with AML consultants to review
            and assess our AML controls and procedures and to provide
            recommendations for improvement.
          </li>
        </ul>
        <h5 className="fw-bold">Conclusion:</h5>{" "}
        <p>
          Intech Operations, REG NO. 306709958 takes its AML responsibilities
          seriously and is committed to maintaining a safe and secure platform
          for our users. We recognize the importance of preventing money
          laundering and terrorist financing on our platform and have
          implemented a robust AML policy to achieve this goal. Our AML controls
          and procedures are regularly reviewed and updated to ensure they are
          effective and in line with regulatory requirements. If you have any
          questions or concerns about our AML policy, please contact us at
          csgotower.com@gmail.com.
        </p>
      </div>
    </div>
  );
};
export default AmlPage;
