import { Col, Container, Row } from "react-bootstrap";
import TowerInfo from "../../../components/Tables/TowerInfo";

import HallOfHeroes from "../../../components/Tables/HallOfHeroes";
import Verification from "../../../components/Verification";
import TowerAdminGame from "../components/TowerAdminGame";

function TowerAdminPage() {
  return (
    <Container className="container-page tower">
      <Row className="gap-5 gap-md-0">
        <Col className="d-none d-md-block" xs={12} md lg={4}>
          <TowerInfo />
        </Col>
        <Col className="mt-5 mt-md-0" xs md lg={4}>
          <TowerAdminGame />
        </Col>
        <Col className="d-none d-md-block" xs={12} md={6} lg={4}>
          <HallOfHeroes />
        </Col>

        <Col className="pt-md-5 " xs={12} md={12}>
          <Verification />
        </Col>
      </Row>
    </Container>
  );
}

export default TowerAdminPage;
