import React, { useState } from "react";

import "./index.css";
import { Col, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import CustomInput from "../Inputs";
import { Link } from "react-router-dom";
import Statistics from "../Statistics";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/userSlice";
import apiRequest from "../../util/apiUtil";
import axiosInstance from "../../util/axios";

const ProfileModal: React.FC = () => {
  const { wagered, totalGames, avatar, name, email } = useSelector(selectUser);
  const { t } = useTranslation();
  const { tradelink } = useSelector(selectUser);
  const [useremail, setUserEmail] = useState<string>(email);
  const [link, setLink] = useState<string>(tradelink);
  const [fetching, setFetching] = useState<boolean>(false);
  const dispatch = useDispatch();
  const updateTradingURL = async () => {
    setFetching(true);
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("user/updateTradelink", { link }),
        successMessage: t("success.urlUpdate"),
        errorMessage: t("errors.errorTradeURL"),
      });
      const data = await apiRequest({
        axiosFunction: () => axiosInstance.post("user"),
      });

      dispatch(setUser(data.user));
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const updateEmail = async () => {
    setFetching(true);
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post("user/email", { email: useremail }),
        successMessage: t("success.email"),
      });
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  return (
    <Row className="mt-4 g-4">
      <Col sm={12} md={6}>
        <div className="text-white">
          <h6>{t("greetUser", { username: name })}</h6>
        </div>
        <div className="mt-5 pt-4 d-flex flex-column gap-3">
          <CustomInput
            label={t("forms.yourEmailAddress")}
            buttonText={t("save")}
            value={useremail}
            onChange={setUserEmail}
            onClick={updateEmail}
            name={"email"}
          />
          <CustomInput
            disabled={fetching}
            onChange={(e: string) => setLink(e)}
            value={link}
            onClick={updateTradingURL}
            label={
              <Trans
                i18nKey="forms.yourTradeUrl"
                components={[
                  <Link
                    target="_blank"
                    key="0"
                    to={"http://steamcommunity.com/my/tradeoffers/privacy"}
                  />,
                ]}
              />
            }
            buttonText={t("save")}
            name={"Trade URL"}
          />
        </div>
      </Col>
      <Col
        className="d-flex align-items-center flex-column gap-3"
        sm={12}
        md={6}
      >
        <img
          className="profile-pic"
          src={avatar || "/images/logo_big_z.png"}
          alt="Profile picture"
        />
        {t("forms.yourStats")}
        <hr></hr>

        <Statistics gamesPlayed={totalGames} wageredCoins={wagered} />
      </Col>
    </Row>
  );
};

export default ProfileModal;
