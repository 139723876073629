import React from "react";
import PropTypes from "prop-types";
import CopyToClipboard from "../CopyToolTip";

interface CopyFieldProps {
  value: string | JSX.Element;
  copyValue: string | number;
}

const CopyField: React.FC<CopyFieldProps> = ({ value, copyValue }) => {
  return (
    <div className="bg-secondary rounded-md ">
      <div
        style={{ paddingLeft: "15px", minWidth: "200px" }}
        className="d-flex gap-2 flex-row align-items-center justify-content-between pl-2 py-1.5"
      >
        {value}
        <CopyToClipboard value={String(copyValue)} />
      </div>
    </div>
  );
};

CopyField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  copyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default CopyField;
