import { Link } from "react-router-dom";
import { URLMAP, WS_EVENTS } from "../../util/const";
import "./index.css";
import { Trans, useTranslation } from "react-i18next";
import ChatInput from "./ChatInput";
import { useSelector } from "react-redux";
import { User } from "../../types";
import Message from "./Message";
import { RefObject, useEffect, useRef } from "react";

export interface MessageType {
  content: string;
  user: User;
  time: number;
  action?: string;
}
interface RoomAction {
  action: string;
  name: string;
  id: number;
  msgs: MessageType[];
}

function Chat({ sm }: { sm?: boolean }) {
  const { t } = useTranslation();
  const messages = useSelector(
    // eslint-disable-next-line
    (state: any) => state.socket.messages[WS_EVENTS.chat] || [],
  );

  const joinedRoomsMsgs: MessageType[] =
    messages.find((r: RoomAction) => r.action === "joined room")?.msgs || [];

  const newMessages: MessageType[] = messages.filter(
    (m: MessageType) => m.action === "message" || m.action === "system message",
  );

  const allMsgs: MessageType[] = [...joinedRoomsMsgs, ...newMessages];

  const chatContainerRef: RefObject<HTMLDivElement> = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMsgs]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollToBottom();
    }, 1000); // TODO: this is not ideal, works in most of cases. useEffect ignores ref.current.scrolltop changes if added to dependency

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="d-flex flex-column gap-3 h-100 justify-content-between ">
      <div
        className={`d-flex flex-column gap-2 chat-wrapper ${sm ? "sm" : ""}`}
        ref={chatContainerRef}
      >
        <div className="bg-glass rounded-md text-sm text-left text-golden px-3 py-2">
          {t("chatClean")}
        </div>
        <div className="bg-glass rounded-md text-left text-golden px-3 py-2 d-none">
          <Trans
            i18nKey="chatCommands"
            components={[
              <Link className="green-link" key="0" to={URLMAP.RULES}></Link>,
            ]}
          />
        </div>
        <div className="messages-wrapper">
          <div className="d-flex flex-column gap-1">
            {allMsgs?.map((m, index) => <Message key={index} {...m} />)}
          </div>
        </div>
      </div>

      <ChatInput />
    </div>
  );
}

export default Chat;
