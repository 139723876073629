import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import { closeModal, selectModal } from "../../store/slices/modalSlice";
import { useTranslation } from "react-i18next";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ProfileModal from "./ProfileModal";
import { TabContentItem } from "../../types";
import AffiliateModal from "./AffiliateModal";

import { middlewareUrl } from "../../util/const";
import { selectUser, setLoggedOut } from "../../store/slices/userSlice";
import CoinsModal from "./CoinsModal";
import BetsModal from "./BetsModal";

export const tabContent: TabContentItem[] = [
  {
    component: <ProfileModal />,
    title: "profile",
  },

  {
    component: <CoinsModal />,
    title: "coinsTrx",
  },
  {
    component: <BetsModal />,
    title: "betsHistory",
  },
  {
    component: <AffiliateModal />,
    title: "affiliate",
  },
];
const CustomModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    showModal,
    modalHeader,
    className,
    modalContent,
    modalFooter,
    activeTab,
    showIfLoggedOut,
  } = useSelector(selectModal);

  const { token } = useSelector(selectUser);
  const DEFAULT_TAB = tabContent[0]?.title;
  const [key, setKey] = useState<string>(activeTab || DEFAULT_TAB);
  const handleLogIn = () => {
    window.location.href = `${middlewareUrl}/auth/steam`;
    handleClose();
  };

  useEffect(() => {
    if (!token && showModal && !showIfLoggedOut) {
      handleLogIn();
    }
  }, [token, showModal, showIfLoggedOut]);

  useEffect(() => {
    setKey(activeTab || DEFAULT_TAB);
  }, [activeTab]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const DEFAULT_CONTENT = (
    <>
      <Tabs
        id="tab-switcher"
        activeKey={key}
        onSelect={(k) => setKey(String(k))}
        className="active-gold border-b bg-transparent d-flex justify-content-around  "
      >
        {tabContent.map((tab: TabContentItem, index) => (
          <Tab
            key={index}
            eventKey={tab.title}
            title={
              <span className={"text-uppercase "}>
                {t(`tabs.${tab.title}`)}
              </span>
            }
          >
            {tab.component}
          </Tab>
        ))}
      </Tabs>
    </>
  );

  const handleLogout = () => {
    dispatch(setLoggedOut());
    // window.open(`${middlewareUrl}/logout`); no middleware stuff
    handleClose();
  };

  if ((!token && showModal && !showIfLoggedOut) || !showModal) {
    return <div></div>;
  }

  return (
    <Modal className={className} show={showModal} onHide={handleClose} centered>
      <Modal.Header>
        <h5 className="text-golden text-uppercase">
          {modalHeader || t("account")}
        </h5>
        <hr></hr>
        <Button
          onClick={handleClose}
          variant="info"
          className="close bg-transparent"
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </Button>
      </Modal.Header>

      <Modal.Body>{modalContent || DEFAULT_CONTENT}</Modal.Body>
      <Modal.Footer>
        {modalFooter ? (
          modalFooter
        ) : (
          <div className="d-flex w-100 justify-content-between">
            <Button className="text-uppercase" onClick={handleLogout}>
              {t("logout")}
            </Button>

            <Button
              className="text-uppercase"
              variant="secondary"
              onClick={handleClose}
            >
              {t("close")}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;

export const CloseFooter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <div className="d-flex w-100 justify-content-center">
      <Button
        className="text-uppercase px-5 py-2 m-2"
        variant="secondary"
        onClick={handleClose}
      >
        {t("close")}
      </Button>
    </div>
  );
};
