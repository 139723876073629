import React from "react";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

interface StatisticsProps {
  gamesPlayed: number;
  wageredCoins: number;
}

const Statistics: React.FC<StatisticsProps> = ({
  gamesPlayed,
  wageredCoins,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="p-2 mx-auto text-golden bg-secondary statistics w-100">
      <Col className="d-flex flex-column justify-content-center align-items-center total-plays">
        <div className="counter-text">
          <CountUp end={gamesPlayed} duration={2} />
        </div>
        <div className="counter-title">{t("gamesPlayed")}</div>
      </Col>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <div className="counter-text">
          <CountUp end={wageredCoins} duration={5} />
        </div>
        <div className="counter-title">{t("wageredCoins")}</div>
      </Col>
    </Row>
  );
};

export default Statistics;
