import React from "react";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

interface StatisticsProps {
  label1: string;
  label2: string;
  value1: number;
  value2: number;
}

const CustomStatistics: React.FC<StatisticsProps> = ({
  label1,
  label2,
  value1,
  value2,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="p-2 mx-auto text-golden bg-secondary statistics w-100">
      <Col className="d-flex flex-column justify-content-center align-items-center total-plays">
        <div className="counter-text">
          <CountUp end={value1} duration={2} />
        </div>
        <div className="counter-title">{t(label1)}</div>
      </Col>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <div className="counter-text">
          <CountUp end={value2} duration={5} />
        </div>
        <div className="counter-title">{t(label2)}</div>
      </Col>
    </Row>
  );
};

export default CustomStatistics;
