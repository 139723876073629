import axios, { AxiosInstance } from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${backendUrl}/api`,
});


axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const adminToken = localStorage.getItem('adminToken');

    // Check if the request URL contains the word "admin"
    if (config.url && config.url.includes('admin')) {
      if (adminToken) {
        config.headers['Authorization'] = `Bearer ${adminToken}`;
      }
    } 
    if (token) {
      config.data = { ...config.data, token };
    }

    return config;
  },
  (error) => {
  
    return Promise.reject(error);
  }
);

export default axiosInstance;

