import { Col, Container, Row } from "react-bootstrap";
import TowerInfo from "../../components/Tables/TowerInfo";
import TowerGame from "../../components/Games/Tower";
import "./index.css";
import HallOfHeroes from "../../components/Tables/HallOfHeroes";
import Verification from "../../components/Verification";

function TowerMainPage() {
  return (
    <Container className="container-page tower">
      <Row className="gap-5 gap-xl-0">
        <Col className="d-none d-md-block order-2 order-xl-1" lg={12} xl={4}>
          <TowerInfo />
        </Col>
        <Col className="mt-4 mt-sm-5 mt-xl-2 order-1 order-xl-2" lg={12} xl={4}>
          <TowerGame />
        </Col>
        <Col className="d-none d-md-block order-3 order-xl-3" lg={12} xl={4}>
          <HallOfHeroes />
        </Col>

        <Col className="pt-md-5 order-5 order-md-4" lg={12} xl={12}>
          <Verification />
        </Col>
      </Row>
    </Container>
  );
}

export default TowerMainPage;
