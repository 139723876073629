import React from "react";
import { Button } from "react-bootstrap";

import { CHAT_LANGUAGES } from "../../../util/const";
import "./index.css";
import OnlineCount from "../../UI/OnlineCount";

interface ChatBarProps {
  customLayout?: boolean;
}

const ChatBar: React.FC<ChatBarProps> = ({ customLayout = false }) => {
  return customLayout ? (
    <div className="d-flex justify-content-between pt-4 pb-1 border-b-primary custom-layout">
      <div
        className="d-flex flex-wrap gap-2
   justify-content-start"
      >
        {CHAT_LANGUAGES.map((lang, index: number) => (
          <Button
            variant="info"
            className="bg-transparent p-0 text-uppercase"
            key={index}
          >
            <span className="">{lang.code}</span>
          </Button>
        ))}
      </div>

      <OnlineCount />
    </div>
  ) : (
    <div
      className="d-flex flex-wrap gap-3
   justify-content-around"
    >
      {CHAT_LANGUAGES.map((lang, index: number) => (
        <Button
          variant="info"
          className="bg-transparent  text-uppercase"
          key={index}
        >
          {" "}
          {lang.code}{" "}
        </Button>
      ))}
    </div>
  );
};

export default ChatBar;
