import React from "react";
import { MessageType } from "..";
import "./index.css";
import CopyToClipboard from "../../UI/CopyToolTip";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { ADMIN_RANK } from "../../../util/const";
const Message = (message: MessageType) => {
  const { rank } = useSelector(selectUser);
  const isAdmin = message && message?.user?.rank === ADMIN_RANK;
  return (
    <div
      {...message}
      className="d-flex flex-column gap-0 message px-2 py-1  rounded-md fade-in-fast"
    >
      <div className="d-flex gap-2 align-items-center">
        <img
          className="rounded-full icon-image"
          src={message.user?.avatar || "/images/logo_big_z.png"}
        />
        <span className="username overflow-hidden fw-bold text-truncate">
          <div
            className="d-flex gap-1 justify-items-center align-items-center"
            style={{ maxHeight: "30px" }}
          >
            {message.user?.name || "CSGOTOWER AI"}{" "}
            {message?.user?.steamid && rank === ADMIN_RANK && (
              <CopyToClipboard
                value={message.user.steamid}
                label="copySteamid"
              />
            )}
          </div>
        </span>
      </div>
      <span className={`content text-md ${isAdmin ? "text-green" : ""}`}>
        {message.content}
      </span>
    </div>
  );
};

export default Message;
