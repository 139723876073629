import React, { useEffect, useState } from "react";

import apiRequest from "../../../util/apiUtil";
import axiosInstance from "../../../util/axios";

import BackButton from "../../UI/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { closeModal, openDefaultModal } from "../../../store/slices/modalSlice";
import { toast } from "react-toastify";

type TransactionStatus = "success" | "error" | null;

interface Transaction {
  url: string;
  transaction_id: number | null;
  status: TransactionStatus;
}
interface CardModalProps {
  goBack: () => void;
}
const CardModal: React.FC<CardModalProps> = ({ goBack }) => {
  const { tradelink } = useSelector(selectUser);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tradelink) {
      toast.error(t("errors.noTradingUrl"));
      
      dispatch(closeModal());
      dispatch(openDefaultModal());
    }
  }, [tradelink]);
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  const [trx, setTrx] = useState<Transaction>({
    url: "",
    transaction_id: null,
    status: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true); // Set withdraw pending
        const data = await apiRequest({
          axiosFunction: () => axiosInstance.post(`/skinsdrip`),
        });

        setTrx(data);
      } catch (error) {
        console.error("Error during API request:", error);
      } finally {
        setFetching(false); // Reset withdraw pending
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   if (trx.url) {
  //     window.location.href = trx.url; // Redirect to trx.url when it's available
  //   }
  // }, [trx]);

  return (
    <div>
      <div className="mb-2">
        <BackButton goBack={goBack} />
      </div>

      {trx?.url && <iframe src={trx.url} title="Deposit" />}
    </div>
  );
};

export default CardModal;
