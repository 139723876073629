import React from "react";
import { Button } from "react-bootstrap";
import "./index.css";
import { COINS_PER_DOLLAR } from "../../util/const";
interface SkinProps {
  float: string;
  price: number;
  image: string;
  name: string;
  onClick?: (id: string) => void;
  isSelected: boolean;
  id: string;
}

const SkinItem: React.FC<SkinProps> = ({
  float,
  price,
  image,
  name,
  onClick,
  isSelected,
  id,
}) => {
  return (
    <Button
      variant="bg-transparent"
      className={`p-0 border w-100 rounded-md  cursor-pointer transition-all ${
        isSelected ? "border-primary" : "border-secondary"
      }`}
      onClick={() => (onClick ? onClick(id) : {})}
    >
      <div className="d-flex justify-content-between mb-3 pt-2 px-2">
        <div>{float ? parseFloat(float).toFixed(2) : ""}</div>
        <div className="text-golden fw-bold">{price / COINS_PER_DOLLAR}$</div>
      </div>
      <div className="text-center">
        <div className="skin-image-div">
          <img
            src={image || "/images/no-image.png"}
            alt="Item"
            className="skin-image"
          />
        </div>
      </div>
      <div className="mt-3 py-1 rounded-md text-center bg-secondary px-2 skin-item-name">
        {name}
      </div>
    </Button>
  );
};

export default SkinItem;
