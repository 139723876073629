// components/CookieConsent.tsx

import React from "react";
import { useCookies } from "react-cookie";
import { Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
interface CookieConsentProps {}

const CookieConsent: React.FC<CookieConsentProps> = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const { t } = useTranslation();
  const handleAccept = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  if (cookies.cookieConsent) {
    return null;
  }

  return (
    <Alert variant="secondary" className="cookiebar container fixed-bottom ">
      <div className="d-flex flex-column align-items-start">
        <div>{t("cookiesMessage")}</div>
        <Button
          className="ml-auto mt-3"
          onClick={handleAccept}
          variant="primary"
        >
          {t("accept")}
        </Button>
      </div>
    </Alert>
  );
};

export default CookieConsent;
