import { CreateGameResponse, Difficulty } from "../types";
import apiRequest from "../util/apiUtil";
import axiosInstance from "../util/axios";

const createGame = async (
  level: Difficulty,
  betValue: string,
): Promise<CreateGameResponse> => {
  return apiRequest({
    // if you want all first column fields to be bad, other fields to be okay, uncomment the the line below
    // !!!!! important !!!! this should never ever endup in the production
    axiosFunction: () =>
      axiosInstance.post("/towers/create", {
        data: { level, betValue, 
       // shouldFake: "qiUUnc3X0e" 
        },
      }),
    // axiosFunction: () =>
    //   axiosInstance.post("/towers/create", { data: { level, betValue } }),
  });
};

export default createGame;
