import { useTranslation } from "react-i18next";
import "./index.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap"; // Import Form from react-bootstrap
import { useEffect, useState } from "react";
import apiRequest from "../../util/apiUtil";
import axiosInstance from "../../util/axios";
import {
  DepositStats,
  FullUser,
  GameSkin,
  GamePayload,
  Transaction,
  Feature,
  SettingsType,
  GamblerInfo,
  PaginationType,
} from "../../types";
import TabTable from "../../components/Tables/TabTable";
import { transformData } from "../../util/helpers";
import CustomStatistics from "../../components/Statistics/Custom";

import CustomInput from "../../components/Inputs";

import { debounce } from "lodash";
import { Link } from "react-router-dom";
import PaginationComponent from "../../components/Pagination";
import { DEFAULT_ITEMS_PER_PAGE } from "../../util/const";
import { openModal } from "../../store/slices/modalSlice";
import { useDispatch } from "react-redux";
import BetsModal from "../../components/Modals/BetsModal";
import CoinsModal from "../../components/Modals/CoinsModal";
import OnlineCount from "../../components/UI/OnlineCount";
import WofAdmin from "./WofAdmin";
export interface TransactionFull extends Transaction {
  items: GameSkin[];
}
interface DateRange {
  startTime: Date | undefined;
  endTime: Date | undefined;
}

interface RemainingBalance extends PaginationType {
  data: GamblerInfo[];
}

interface CryptoData extends PaginationType {
  data: GamblerInfo[];
}
type BlockType = "block" | "unblock";
type WithdrawalAction = "approve" | "reject";
const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
const ranges = [
  { label: "Last 24hrs", value: "24hrs" },
  { label: "Last Week", value: "week" },
  { label: "Last Month", value: "month" },
  { label: "All Time", value: "all" },
];
const AdminPage = () => {
  const [adminToken, setAdminToken] = useState<string>(
    localStorage.getItem("adminToken") || "",
  );
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [inputToken, setInputToken] = useState<string>("");
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  const [user, setUser] = useState<FullUser | null>(null);
  const [games, setGames] = useState<GamePayload[]>([]);
  const [cryptoData, setCryptoData] = useState<CryptoData>();
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<TransactionFull[]>();
  const [flags, setFlags] = useState<Feature[]>([]);
  const [playerBalances, setPlayerBalances] = useState<RemainingBalance>();
  const [stats, setStats] = useState<DepositStats>({
    total_deposited: 0,
    total_withdrawn: 0,
  });

  const [search, setSearch] = useState<string>("");
  const [cryptoAddress, setcryptoAddress] = useState<string>("");

  const [settings, setSettings] = useState<SettingsType>({
    minUserCount: null,
    maxUserCount: null,
    depositNotificationThreshold: null,
    betNotificationThreshold: null,
  });
  const [balance, setBalance] = useState<number | string>("");
  const [balanceTouched, setBalanceTouched] = useState<boolean>(false);
  const [ipSearch, setIpSearch] = useState<string>();
  const [accounts, setAccounts] = useState<FullUser[]>();
  const [range, setRange] = useState<DateRange>({
    startTime: twentyFourHoursAgo,
    endTime: new Date(),
  });
  const [selectedRange, setSelectedRange] = useState<string>("24hrs");
  const handleRangeChange = (rangeType: string) => {
    const now = new Date();
    let startTime: Date;

    switch (rangeType) {
      case "24hrs":
        startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "week":
        startTime = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "month":
        startTime = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "all":
        startTime = new Date(2024, 4, 1);
        break;
      default:
        startTime = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    }

    setRange({ startTime, endTime: now });
    setSelectedRange(rangeType);
  };

  useEffect(() => {
    const setBalance = debounce(async () => {
      try {
        await apiRequest({
          axiosFunction: () =>
            axiosInstance.post(`/admin/users/${search}/balance`, {
              amount: balance,
              action: "set",
            }),
          successMessage: t("success.balanceSet"),
        });
      } catch (error) {
        console.error("Error during API request:", error);
      }
    }, 0);

    balanceTouched && setBalance();

    return () => {
      setBalance.cancel();
    };
  }, [balance, balanceTouched]); 



  const dispatch = useDispatch();

  const fetchRemainingBalances = async () => {
    try {
      const request = await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/remainingBalance?page=${page}`, { page }),
      });
      setPlayerBalances(request);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (adminToken) {
      fetchRemainingBalances();
    }
  }, [page, adminToken]);

  const fetchData = async () => {
    try {
      setFetching(true);

      const [transactionsRequest, featureFlagsRequest] = await Promise.all([
        apiRequest({
          axiosFunction: () =>
            axiosInstance.post(`/admin/skinsback/transactions`),
        }),
        apiRequest({
          axiosFunction: () => axiosInstance.post(`/admin/featureFlags`, {}),
        }),
      ]);

      setData(transactionsRequest.transactions);
      setFlags(featureFlagsRequest.featureFlags);
      setTokenValid(true);
      // eslint-disable-next-line
    } catch (error: any) {
      if (error?.response?.status === 403) {
        setTokenValid(false);
        localStorage.removeItem("adminToken");
      }

      console.error("Error during API request :", error);
    } finally {
      setFetching(false);
    }
  };

  const fetchTowers = async () => {
    try {
      setFetching(true);
      const request = await apiRequest({
        axiosFunction: () => axiosInstance.post(`/admin/towers`),
      });
      const newData: GamePayload[] = [];
      for (const game of request.games) {
        let payload: GamePayload = {
          avatar: "",
          id: "",
          steamid: "",
          timestamp: 0,
          userId: "",
          deposited: 0,
          betValue: 0,
          currentLevel: 0,
        };
        const { id, timestamp, userData, towers, userId } = game;
        const { avatar, deposited, steamid } = userData;
        const { betValue, currentLevel } = towers;
        payload = {
          avatar,
          id,
          steamid,
          timestamp,
          userId,
          deposited,
          betValue,
          currentLevel,
        };

        newData.push(payload);
      }
      setGames(newData);
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const closeTower = async (steamid: string) => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/towers/close/${steamid}`),
      });
      await fetchTowers();
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const searchBySteam = async (steamIdProps?: string) => {
    const targetSteam =
      typeof steamIdProps === "object" ? search : steamIdProps;
    try {
      const request = await apiRequest({
        axiosFunction: () => axiosInstance.post(`/admin/users/${targetSteam}/data`),
      });
      setUser(request);
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };


  const searchByIp = async () => {
    try {
      const request = await apiRequest({
        axiosFunction: () => axiosInstance.post('/admin/users/ip', {ip:ipSearch}),
      });
      setAccounts(request);
    } catch (error) {
      console.error("Error during API request:", error);
    }
  }

  const searchCryptoAddress = async () => {
    try {
      const request = await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/crypto/transactions/${cryptoAddress}`),
      });
      setCryptoData(request);
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const getSettings = async () => {
    try {
      const response = await axiosInstance.post(`/admin/getSettings`);
      setSettings({
        minUserCount: response.data.minUserCount,
        maxUserCount: response.data.maxUserCount,
        depositNotificationThreshold:
          response.data.depositNotificationThreshold,
        betNotificationThreshold: response.data.betNotificationThreshold,
      });
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const changeSettings = async () => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/settings`, { ...settings }),
        successMessage: t("success.settings"),
      });
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const fetchStats = async () => {
    try {
      if (range.endTime && range.startTime) {
        setFetching(true);
        const endTimeUnixTimestamp = Math.floor(
          range.startTime.getTime() / 1000,
        );
        const startTimeUnixTimestamp = Math.floor(
          range.endTime.getTime() / 1000,
        );
        const request = await apiRequest({
          axiosFunction: () =>
            axiosInstance.post(`/admin/transactions`, {
              startTime: startTimeUnixTimestamp,
              endTime: endTimeUnixTimestamp,
            }),
        });

        setStats(request);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  const handleFlagChange = async (type: string, changeVal: boolean) => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/featureFlags/${type}`, {
            enabled: changeVal,
          }),
      });

      await fetchData();
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const handleBlock = async (action: BlockType, steamid: string) => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/users/${steamid}/${action}`),
        successMessage: `User successfully ${action}ed`,
      });

      searchBySteam(steamid);
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    adminToken && fetchStats();
  }, [selectedRange, adminToken]);

  useEffect(() => {
    if (adminToken && !tokenValid) {
      fetchData();
      fetchTowers();
      getSettings();
    }
  }, [adminToken, tokenValid]);

  const { t } = useTranslation();
  const transformedData = transformData(data || [], t);
  const userData = transformData(user ? [user] : [], t);
  const accountData = transformData(accounts ? accounts : [], t);
  const cryptoDataTransformed = transformData(
    cryptoData && cryptoData?.data?.length > 0 ? [cryptoData.data] : [],
    t,
  );
  const handleWithdrawal = async (
    transactionId: string,
    action: WithdrawalAction,
  ) => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/skinsback/${action}`, { transactionId }),
      });
      await fetchData();
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const gamesData = transformData(games ? games : [], t);
  const balanceData = transformData(
    playerBalances ? playerBalances.data : [],
    t,
  );

  const dataWithActions = transformedData.map((item, index) => ({
    ...item,
    "": (
      <Button
        variant="success"
        onClick={() =>
          data ? handleWithdrawal(data[index].id, "approve") : {}
        }
      >
        {t("approve")}
      </Button>
    ),
    " ": (
      <Button
        variant="danger"
        onClick={() => (data ? handleWithdrawal(data[index].id, "reject") : {})}
      >
        {t("reject")}
      </Button>
    ),
  }));

  const userDataWithActions = userData.map((item, index) => ({
    ...item,
    balance: (
      <CustomInput
        key={index}
        name="balance"
        type="number"
        value={String(
          balanceTouched ? balance : String(item.balance).replace(/,/g, ""),
        )}
        onChange={(e) => {
          setBalanceTouched(true), setBalance(e);
        }}
      />
    ),
    coinHistory: (
      <Button
        onClick={() =>
          dispatch(
            openModal({
              modalHeader: t("coinsHistory"),
              modalContent: <CoinsModal steamIdProps={String(item.steamid)} />,
              modalFooter: <></>,
            }),
          )
        }
      >
        {t("view")}
      </Button>
    ),
    gameHistory: (
      <Button
        onClick={() =>
          dispatch(
            openModal({
              modalHeader: t("gameHistory"),
              modalContent: <BetsModal steamIdProps={String(item.steamid)} />,
              modalFooter: <></>,
            }),
          )
        }
      >
        {t("view")}
      </Button>
    ),
    unblock: (
      <Button
        onClick={() => handleBlock("unblock", String(item.steamid))}
        variant="success"
      >
        {t("unblock")}
      </Button>
    ),
    block: (
      <Button
        onClick={() => handleBlock("block", String(item.steamid))}
        variant="danger"
      >
        {t("block")}
      </Button>
    ),
  }));

  const gameDataWithActions = gamesData.map((item) => {
    const secretTowerUrl = `${process.env.REACT_APP_SECRET_TOWER_URL}/${item.userId}`;

    // Create the object with properties in the desired order
    return {
      ...Object.fromEntries(Object.entries(item).slice(0, 2)), // First two properties
      Close: (
        <Button
          onClick={() => closeTower(String(item.steamid))}
          variant="danger"
        >
          {t("close")}
        </Button>
      ),
      ...Object.fromEntries(Object.entries(item).slice(2)), // Remaining properties
      Spectate: (
        <Link to={String(secretTowerUrl)}>
          <Button>{t("spectate")}</Button>
        </Link>
      ),
    };
  });

  const handleTokenSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    localStorage.setItem("adminToken", inputToken);
    setAdminToken(inputToken);
  };

  return (
    <Container className="container-page admin-panel rounded-md">
      {adminToken && tokenValid ? (
        <>
          <Row className="py-3">
            <Col xs={12} md={6}>
              <CustomStatistics
                value1={stats.total_deposited}
                label1={t("totalDeposited")}
                value2={stats.total_withdrawn}
                label2={"totalWithdrawn"}
              />
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex py-3 align-items-center justify-content-center gap-2">
                {ranges.map(({ label, value }) => (
                  <Button
                    key={value}
                    className="py-1.5 px-3"
                    variant={selectedRange === value ? "primary" : "secondary"}
                    onClick={() => handleRangeChange(value)}
                  >
                    {label}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>

          <Row className="gap-3 gap-md-0">
            {flags.map((flag, index) => (
              <Col xs={12} md key={index}>
                <div
                  onClick={() => handleFlagChange(flag.type, !flag.enabled)}
                  className={`feature-flag ${
                    flag.enabled ? "bg-win" : "bg-secondary"
                  } cursor-pointer transition-all flex-column border-secondary border text-center d-flex rounded-md justify-content-center align-items-center p-3`}
                >
                  <Form.Check
                    className="cursor-pointer"
                    disabled={false}
                    onChange={() => handleFlagChange(flag.type, !flag.enabled)}
                    checked={flag.enabled}
                    type="switch"
                    label={flag.name}
                    id="custom-switch"
                  />
                </div>
              </Col>
            ))}
          </Row>

          <Row className="my-4">
            <Col xs md={2}>
              <div className="d-flex h-100 align-items-center justify-content-center">
                <OnlineCount />
              </div>
            </Col>

            {Object.keys(settings).map((key) => (
              <Col xs key={key}>
                <CustomInput
                  name={key}
                  label={t(`labels.${key}`)}
                  buttonText={t("save")}
                  onClick={() => changeSettings()}
                  onChange={(e) => {
                  
                    setSettings((prev) => ({
                      ...prev,
                      [key as keyof SettingsType]: Number(e),
                    }));
                  }}
                  value={String(settings[key as keyof SettingsType] || "")}
                />
              </Col>
            ))}
          </Row>

          <div className="admin-table-wrap py-3">
            <h4 className="text-center">{t("pendingWithdrawals")}</h4>
            <TabTable data={dataWithActions} />
          </div>

          <hr></hr>
          <div className="admin-towers">
            <h4 className="text-center">{t("towerGames")}</h4>
            <TabTable data={gameDataWithActions} />{" "}
          </div>

          <hr></hr>

          <div className="admin-towers">
            <h4 className="text-center">{t("colors")}</h4>
            <div className="mx-auto text-center">
              <Link to={String(process.env.REACT_APP_SECRET_WOF_URL)}>
                <Button>{t("spectateColorGame")}</Button>
              </Link>
            </div>
            <WofAdmin />
          </div>

          <hr></hr>

          <div className="admin-towers">
            <h4 className="text-center">
              {t("topBalances")} <span>{`(${playerBalances?.total})`}</span>
            </h4>
            <div className="">
              {" "}
              <TabTable data={balanceData} />{" "}
            </div>
            {playerBalances?.data && (
              <div className="mt-3">
                <PaginationComponent
                  hasNext={
                    playerBalances.total >= page * DEFAULT_ITEMS_PER_PAGE
                  }
                  onPageChange={setPage}
                />
              </div>
            )}
          </div>

          <hr></hr>

          <div className="justify-content-center d-flex input-block">
            <CustomInput
              placeholder={t("searchBySteamId")}
              buttonText={t("search")}
              value={search}
              onChange={setSearch}
              onClick={searchBySteam}
              name={"search-user"}
            />
          </div>

          {user && (
            <div className=" py-3">
              <TabTable vertical data={userDataWithActions} />
            </div>
          )}


<div className="justify-content-center d-flex input-block my-3">
            <CustomInput
              placeholder={t("searchByIp")}
              buttonText={t("search")}
              value={ipSearch}
              onChange={setIpSearch}
              onClick={searchByIp}
              name={"search-ip"}
            />
          </div>

          {accountData && (
            <div className=" py-3">
              <TabTable data={accountData} />
            </div>
          )}

          <div className="justify-content-center mt-5 d-flex input-block">
            <CustomInput
              placeholder={t("searchByCryptoAddress")}
              buttonText={t("search")}
              value={cryptoAddress}
              onChange={setcryptoAddress}
              onClick={searchCryptoAddress}
              name={"search-crypto-address"}
            />
          </div>

          {cryptoData && (
            <div className=" py-3">
              <TabTable data={cryptoDataTransformed} />
              {cryptoDataTransformed?.length > 0 && (
                <div className="mt-3">
                  <PaginationComponent
                    hasNext={
                      cryptoData?.total
                        ? cryptoData?.total >= page * DEFAULT_ITEMS_PER_PAGE
                        : false
                    }
                    onPageChange={(e) =>
                      setCryptoData({ ...cryptoData, page: e })
                    }
                  />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <Form onSubmit={handleTokenSubmit}>
          <Form.Group controlId="adminToken">
            <Form.Label>{t("Enter Admin Token")}</Form.Label>
            <Form.Control
              type="text"
              value={inputToken}
              onChange={(e) => setInputToken(e.target.value)}
              placeholder={t("Admin Token")}
            />
          </Form.Group>
          <Button className="ml-auto d-block mt-3" type="submit">
            {t("Submit")}
          </Button>
        </Form>
      )}
    </Container>
  );
};

export default AdminPage;
