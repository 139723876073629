import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css";
import { isMobile } from "react-device-detect";

interface TabTableProps {
  data: { [key: string]: unknown | JSX.Element }[];
  vertical?: boolean;
}

const TabTable: React.FC<TabTableProps> = ({ data, vertical = false }) => {
  const { t } = useTranslation();

  if (data?.length < 1) {
    return (
      <div className="bg-inactive text-center p-2 rounded-md fade-in-fast">
        <span className="">{t("noData")}</span>
      </div>
    );
  }

  const getColumnHeaders = (): string[] => {
    const firstRow = data[0];
    return firstRow ? Object.keys(firstRow) : [];
  };

  // eslint-disable-next-line
  const renderCell = (field: string, value: any): JSX.Element => (
    <td key={field}>
      {field === "difficulty" ? (
        <div className="difficulty-wrapper p-1">
          <img
            className="difficulty-icon"
            src={`/images/${value}.png`}
            alt={String(value)}
          />
        </div>
      ) : (
        value
      )}
    </td>
  );

  const renderHorizontalCell = (
    row: { [key: string]: unknown },
    header: string,
  ): JSX.Element => {
    // eslint-disable-next-line
    const returnVal: any = row[header];

    return (
      <td key={header}>
        {header === "difficulty" ? (
          <div className="difficulty-wrapper  p-1">
            <img
              className="difficulty-icon"
              src={`/images/${returnVal}.png`}
              alt={returnVal as string}
            />
          </div>
        ) : (
          returnVal
        )}
      </td>
    );
  };

  const renderHorizontalTable = (): JSX.Element => {
    const columnHeaders = getColumnHeaders();

    return (
      <div className="overflow-x-auto">
        <Table className="table-default" borderless hover>
          <thead>
            <tr>
              {columnHeaders.map((header) => (
                <th className="" key={header}>
                  {t(header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnHeaders.map((header) =>
                  renderHorizontalCell(row, header),
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderVerticalTable = (): JSX.Element => {
    return (
      <>
        {isMobile ? (
          <div className="d-md-none">
            <tbody>
              {data.flatMap((row, rowIndex) => {
                const entries = Object.entries(row);
                const rows: JSX.Element[] = [];
                for (let i = 0; i < entries.length; i++) {
                  const [field, value] = entries[i];

                  rows.push(
                    <tr key={`${rowIndex}-${i}`}>
                      <td className="fw-bold">{t(field)}</td>
                      <td>{value as JSX.Element}</td>
                    </tr>,
                  );
                }
                return rows;
              })}
            </tbody>
          </div>
        ) : (
          <tbody className="">
            {data.flatMap((row, rowIndex) => {
              const entries = Object.entries(row);
              const rows: JSX.Element[] = [];
              for (let i = 0; i < entries.length; i += 2) {
                const [field1, value1] = entries[i];
                const field2Pair = entries[i + 1];
                const field2 = field2Pair?.[0];
                const value2 = field2Pair?.[1];

                rows.push(
                  <tr key={`${rowIndex}-${i}`}>
                    <td className="fw-bold">{t(field1)}</td>
                    <td>{value1 as JSX.Element}</td>
                    {field2 ? (
                      <>
                        <td className="fw-bold">{t(field2)}</td>
                        <td>{value2 as JSX.Element}</td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>,
                );
              }
              return rows;
            })}
          </tbody>
        )}
      </>
    );
  };

  return (
    <div className={`overflow-x-auto ${vertical ? "table-vertical" : ""}`}>
      <Table className="table-default" borderless hover>
        {vertical ? renderVerticalTable() : renderHorizontalTable()}
      </Table>
    </div>
  );
};

export default TabTable;
