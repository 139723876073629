import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBomb } from "@fortawesome/free-solid-svg-icons";

interface GameDisplayProps {
  currentLevel: boolean;
  lost: boolean;
  isBomb: boolean;
  latestChoice: boolean;
  t: (key: string) => string;
  mode: string;
  awaitingCheck: boolean;
  wonButton: boolean;
  value: number;
}

export const GameDisplay: React.FC<GameDisplayProps> = ({
  currentLevel,
  lost,
  isBomb,
  latestChoice,
  t,
  mode,
  awaitingCheck,
  wonButton,
  value,
}) => {
  if (awaitingCheck) {
    return <span className="game-text fw-bold animated-element">WAIT...</span>;
  }

  if (wonButton && !lost) {
    return (
      <div className="win-div animated-element">
        <img src={`/images/${mode}.png`} alt="Winning icon" />
        <span className="game-text fw-bold">cool</span>
      </div>
    );
  }

  if (currentLevel && lost) {
    if (isBomb && latestChoice) {
      return (
        <div className="game__icon">
          <FontAwesomeIcon
            size="lg"
            className="icon-bomb animated-element" // TODO: get real icons!
            icon={faBomb}
          />
          <span className="game-text animated-element fw-bold">
            {t("tryAgain")}
          </span>
        </div>
      );
    }
  }

  if (lost && !isBomb) {
    return (
      <img
        className="game-ic animated-element"
        src={`/images/${mode}.png`}
        alt="Lost icon"
      />
    );
  }

  return <span className="amount animated-element">{Math.floor(value)}</span>;
};
