import React, { useEffect } from "react";
import "./index.css";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLMAP } from "../../util/const";
import { useDispatch } from "react-redux";
import { setLoggedOut } from "../../store/slices/userSlice";
const BannedPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoggedOut());
  }, []);
  return (
    <div className="tower h-screen not-found-page overflow-hidden">
      <Row className="h-100">
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-end align-items-md-center"
        >
          <img className="img-fluid" src="/images/404_error_tower.png" />
        </Col>
        <Col className="text-center" xs={12} md={6}>
          <div className="d-flex h-100  justify-content-md-center flex-column">
            <h1 className="text-white fw-bold">{t("bannedTitle")}</h1>
            <p className="text-white">{t("bannedSubTitle")}</p>
            <Link className="d-none" to={URLMAP.HOME}>
              <Button className="px-5 py-2 fw-bold">{t("goHome")}</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BannedPage;
