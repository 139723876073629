import { BetStat, BetStatData, BreakPoint, Difficulty, GameColor, Player, PlayerMap, RollStat } from "../types";
import { gameConfig } from "../util/const";

type FindBreakpointFn = (breakpoints: { [amount: number]: BreakPoint }, currentValue: number) => BreakPoint;

export const findBreakpoint: FindBreakpointFn = (breakpoints, currentValue) => {

  const breakpointKeys = Object.keys(breakpoints)
    .map(Number)
    .sort((a, b) => a - b);

  let interval = 10

  breakpointKeys.map((num) => {
    if (currentValue >= num) {
      interval = num
    }
  })


  return breakpoints[interval]

}

export function generateBombArray(mode: Difficulty) {

 
  const {fields, bombs}  = gameConfig.gameDetails[mode]
  const gameArray = new Array(fields).fill(false);

  // Set random positions as true (indicating a bomb)
  for (let i = 0; i < bombs; i++) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * fields);
    } while (gameArray[randomIndex]); // Make sure the position is not already a bomb

    gameArray[randomIndex] = true;
  }

  return gameArray;
}



export function generateFakeBombArray(mode: Difficulty, key: number) {
  const {fields, bombs}  = gameConfig.gameDetails[mode]
  const gameArray = generateBombArray(mode) // einstein level
  const originalWinProbability = (fields - bombs) / fields;
  const increasedWinProbability = originalWinProbability + 0.08;

  // Adjust the probability based on the key for the element at index `key`
  gameArray[key] = Math.random() > increasedWinProbability;

  // Count the number of true values in the array
  let trueCount = gameArray.reduce((count, val) => count + (val ? 1 : 0), 0);

  // If the true count exceeds the number of bombs, randomly reassign true values
  while (trueCount > bombs) {
    const indexToReassign = Math.floor(Math.random() * fields);
    if (indexToReassign !== key && gameArray[indexToReassign]) {
      gameArray[indexToReassign] = false;
      trueCount--;
    }
  }

  // If the true count is less than bombs, randomly assign a bomb until it matches bombs
  while (trueCount < bombs) {
    const indexToAssign = Math.floor(Math.random() * fields);
    if (!gameArray[indexToAssign] && indexToAssign !==key) {
      gameArray[indexToAssign] = true;
      trueCount++;
    }
  }

  return gameArray;
}






export const transformData = (data: PlayerMap): BetStatData => {
  const transformedData: BetStatData = {};

  Object.keys(data).forEach(color => {
    const playersObj = data[color];
    const colorStats: BetStat = {
      totalBet: 0,
      totalPlayers: Object.keys(playersObj).length,
      players: Object.values(playersObj)
    };

    colorStats.totalBet = colorStats.players.reduce((total, player) => total + player.bet, 0);

    transformedData[color] = colorStats;
  });

  return transformedData;
};




interface FairRound {
  type: string;
  hash: string;
  ticket: number;
  secret: string;
  color: GameColor;
}

interface GameData {
  players: {
    red: Player;
    black: Player;
    green: Player;
    yellow: Player;
  };
  status: string;
  counter: number;
  fairRound: FairRound;
  end: number;
  type: string;
}


export const getLastWonColors = (games: GameData[], limit: number): GameColor[] => {
  const wonColors: GameColor[] = [];

  for (let i =  0; i < limit; i++) {
   if(games[i]){
    const color = games[i].fairRound.color;
    wonColors.push(color);
   }
  }

  return wonColors;
};

export const getLastRollColorsCount = (games: GameData[], limit: number): RollStat[] => {
  const rollColors: { [color: string]: number } = {};

  for (let i =0; i < limit; i++) {
    if(games[i]){
      const color = games[i].fairRound.color;
      rollColors[color] = (rollColors[color] || 0) + 1;
    }
   
  }

// Include all colors in the result array, even if their count is zero
const colorOrder = ['red', 'black', 'green', 'yellow'];

// This part maps each color to its count
const result = colorOrder.map(color => ({
    color,
    count: rollColors[color] || 0
} as RollStat));

// Sort the result array by colors alphabetically
result.sort((a, b) => {
  if (a.color === 'red') return -1; // 'red' comes before other colors
  if (b.color === 'red') return 1; // Other colors come after 'red'
  return a.color.localeCompare(b.color); // Sort other colors alphabetically
});

return result;

};


