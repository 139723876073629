import { Trans, useTranslation } from "react-i18next";
import "./index.css";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { SITE_LINKS, URLMAP } from "../../util/const";
import {
  faSteam,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageDropdown from "../Dropdowns/LanguageDropdown";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import SpinModal from "../Modals/SpinModal";
import { CloseFooter } from "../Modals";
import { openModal } from "../../store/slices/modalSlice";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
function Footer() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const dispatch = useDispatch();
  return (
    <footer className="footer">
      <Container className="p-4">
        <Row className="gap-2 gap-md-0">
          <Col xs={12} lg={4}>
            <div className="footer-text">
              {t("footer.footerTitle")}
              <br></br>
              {t("footer.footerSlogan")}
            </div>
            <p className="mt-3">{t("footer.copyright")}</p>
          </Col>
          <Col
            className="relative flex-column d-flex align-items-center justify-content-center overflow-hidden"
            xs={12}
            lg={4}
          >
            <h2 className="z-2">
              <Trans i18nKey="footer.mysteryBox" />
            </h2>
            <img
              className="giftcard-bg"
              src="./images/giftcardbg.svg"
              style={{ pointerEvents: "none" }}
            />

            <img className="z-2" src="./images/mystery_box.png" />

            <Button
              size="sm"
              disabled
              className=" z-2 mt-4 px-5 text-uppercase"
            >
              <Trans i18nKey="footer.comingSoon" />
            </Button>
          </Col>

          <Col xs={12} lg={4} className="gap-2 z-3 d-flex flex-column mt-md-3">
            <Button size="lg" className="text-uppercase w-100 d-none">
              <Trans i18nKey="footer.getFreeCoins" />
            </Button>
            <Button
              size="lg"
              onClick={() =>
                dispatch(
                  openModal({
                    modalHeader: t("dailySpin"),
                    modalContent: <SpinModal />,
                    modalFooter: <CloseFooter />,
                  }),
                )
              }
              variant="success"
              className="text-uppercase w-100 "
            >
              <Trans i18nKey="footer.getDailyExtraCoins" />
            </Button>
          </Col>
        </Row>
      </Container>
      <div className="bg-secondary-light relative ">
        <Container>
          <Row className="footer-middle-row gap-2 gap-md-0  py-3">
            <Col xs={12} md={3}>
              <LanguageDropdown />
            </Col>
            <Col
              xs={12}
              className="sm-text-center d-flex flex-row flex-wrap align-items-center justify-content-center gap-3"
              md={6}
            >
              <Link
                className={
                  location.pathname === URLMAP.BONUSES
                    ? "text-decoration-underline"
                    : ""
                }
                to={URLMAP.BONUSES}
              >
                {t("footer.bonuses")}
              </Link>
              <Link
                className={
                  location.pathname === URLMAP.RULES
                    ? "text-decoration-underline"
                    : ""
                }
                to={URLMAP.RULES}
              >
                {t("footer.rules")}
              </Link>
              <Link
                className={
                  location.pathname === URLMAP.AML
                    ? "text-decoration-underline"
                    : ""
                }
                to={URLMAP.AML}
              >
                {t("footer.aml")}
              </Link>
              <Link
                className={
                  location.pathname === URLMAP.PRIVACY
                    ? "text-decoration-underline"
                    : ""
                }
                to={URLMAP.PRIVACY}
              >
                {t("footer.privacyPolicy")}
              </Link>
              <Link
                className={
                  location.pathname === URLMAP.TOS
                    ? "text-decoration-underline"
                    : ""
                }
                to={URLMAP.TOS}
              >
                {t("footer.TOS")}
              </Link>
              <a
                onClick={() =>
                  dispatch(
                    openModal({
                      showIfLoggedOut: true,
                      modalHeader: t("footer.support"),
                      modalContent: (
                        <div className="cursor-pointer d-flex flex-column gap-3 text-center justify-items-center">
                          <a
                            target="_blank"
                            href={`mailto:${SITE_LINKS.email}`}
                            rel="noopener noreferrer"
                          >
                            <div className="d-flex justify-content-center align-items-center gap-1">
                              <FontAwesomeIcon icon={faEnvelope} size="2x" />
                              {SITE_LINKS.email}
                            </div>
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                            href={`https://t.me/${SITE_LINKS.telegram}`}
                          >
                            <div className="d-flex justify-content-center align-items-center gap-1">
                              <FontAwesomeIcon icon={faTelegram} size="2x" />
                              {t("footer.telegram")}
                            </div>
                          </a>
                        </div>
                      ),
                      modalFooter: <></>,
                      className: "auto-modal",
                    }),
                  )
                }
                className="text-uppercase cursor-pointer"
              >
                {t("footer.support")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom-row py-3">
        <Container className="px-4 px-md-0">
          <Row className="justify-content-between align-items-center">
            <Col xs={6} md={6}>
              <div className="d-flex  justify-content-md-start align-items-center    flex-row gap-2">
                <div className=" bg-gradient-purple rounded-circle p-2">
                  <span className="plus18">18+</span>
                </div>
                <span className="text-golden text-xs d-none d-md-block">
                  {t("footer.gamblingWarning")}
                </span>
              </div>
            </Col>

            <Col xs={6} md={6}>
              <div className="d-flex justify-content-end justify-content-md-end flex-row gap-2">
                <a
                  href="https://steamcommunity.com/groups/csgotower-Monkey"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSteam} size="2x" />
                </a>
                <a
                  href="https://twitter.com/CSGOTowercom"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
