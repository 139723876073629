import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GameColor } from "../../types";

interface CountdownProps {
  counter: number;
  winColor: GameColor | string;
  animationEnded: boolean;
}

const CountdownComponent: React.FC<CountdownProps> = ({
  counter,

  animationEnded,
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(counter);
  const { t } = useTranslation();
  useEffect(() => {
    setRemainingTime(counter);
  }, [counter]);

  const formatTime = (time: number): string => {
    if (time === -1) {
      return "";
    }
    // Extract whole number part
    const wholeSeconds = Math.floor(time);
    // Extract fractional part
    const fraction = (time - wholeSeconds).toFixed(1).slice(1); // Ignore leading '0'
    return `${wholeSeconds}${fraction}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => Math.max(prevTime - 0.1, 0));
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="roll-text">
      {animationEnded && counter === 0
        ? ""
        : counter === 0
        ? t("spinning")
        : formatTime(remainingTime) + "s"}
    </div>
  );
};

export default CountdownComponent;
