import { Col, Row, Tab, Tabs, Button } from "react-bootstrap";
import "../../../../components/Games/Tower/style.css";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ColumnValues, Difficulty, GameState } from "../../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faBomb,
} from "@fortawesome/free-solid-svg-icons";
import { findBreakpoint } from "../../../../helpers";
import { gameConfig, middlewareUrl } from "../../../../util/const";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/userSlice";
import axiosInstance from "../../../../util/axios";
import apiRequest from "../../../../util/apiUtil";

import createGame from "../../../../actions/createTowerGame";

import { setPublicHash } from "../../../../store/slices/towerSlice";
import { playGameSound } from "../../../../util/helpers";
import { useParams } from "react-router-dom";
import socket from "../../../../websockets/socket";

enum BetAction {
  INCREASE,
  DECREASE,
  MIN,
  MAX,
}
const DEMO_COINS = 500;
type GameFate = "win" | "lose";
function TowerAdminGame() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { token } = useSelector(selectUser);
  // eslint-disable-next-line
  const [gambled, setGambled] = useState<boolean>(false);
  const [mode, setMode] = useState<Difficulty>("easy");
  const [tickets, setTickets] = useState<number[]>([]);

  const [choices, setChoices] = useState<number[]>([]);
  // eslint-disable-next-line
  const [recentChoices, setRecentChoices] = useState<number[]>([]);
  const [columnValues, setColumnValues] = useState<ColumnValues>({
    easy: [14, 21, 30, 44, 64, 92, 134, 195, 283, 410],
    medium: [19, 38, 74, 144, 281, 549, 1072, 2090, 4076, 7949],
    hard: [29, 87, 256, 757, 2234, 6590, 19442, 57355, 169199, 499137],
  });

  const [gameState, setGameState] = useState<GameState>();
  // eslint-disable-next-line
  const [fetching, setFetching] = useState<boolean>(false);
  // eslint-disable-next-line
  const [creatingGame, setCreatingGame] = useState<boolean>(false);

  const [demoMode, setDemo] = useState<boolean>(false);

  const [showDemo, setShowDemo] = useState<boolean>(false);
  // eslint-disable-next-line
  const [userCheckedOut, setCheckedOut] = useState<boolean>(false);

  const [win, setWin] = useState<number>(0);
  const [balance, setBalance] = useState<number>(DEMO_COINS);

  const [bet, setBet] = useState<number>(10);

  const [playing, setPlaying] = useState<boolean>(true);
  const [steamid, setSteamId] = useState<string>('');

  const [currentStep, setStep] = useState<number>(1);

 



  const showDemoOverlay = () => {
    setShowDemo(true);
    setDemo(true);
    setBet(DEMO_COINS);
    setBalance(DEMO_COINS);
  };

  const startSpectate = async () => {
    try {
      const request = await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/towers/spectate/${String(id)}`),
        successMessage: t("startedSpectating"),
      });
      const modifiedGameState = {
        ...request.towerInfo.towers,
        level: request.towerInfo.towers.currentLevel,
        mode: request.towerInfo.towers.level,
      };
      setGameState(modifiedGameState);
      setMode(request.towerInfo.towers.level);
      setStep(request.towerInfo.towers.currentLevel + 1);
      setChoices(request.towerInfo.towers.answers);
      setBet(request.towerInfo.towers.betValue);
      setPlaying(true);
     
      setSteamId(request.towerInfo.userData.steamid)
    } catch (e) {
      setPlaying(false);
      console.error(e);
    }
  };

  const stopSpectate = async () => {
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/towers/stop-spectate/${id}}`),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchBalance = async () => {
    try {
      const request = await apiRequest({
        axiosFunction: () => axiosInstance.post(`/admin/users/${steamid}`),
      });
      setBalance(request.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  useEffect(() => {
 
  
    if (steamid) {
      fetchBalance();
    }
  }, [steamid, tickets]);
  

  useEffect(() => {
    if (id) {

    
      
      startSpectate();
      // eslint-disable-next-line
      socket.on(`towers-${id}`, (socketState: GameState | any) => {
        setPlaying(true);
      
        if (socketState.action !== "createTowersResponse") {
          setGameState(socketState);
          setMode(socketState.mode);
        } else {
          handleReset();
          calculateGameMap();
          socketState.towersInfoData.mode = socketState.towersInfoData.level;
          socketState.towersInfoData.level =
            socketState.towersInfoData.currentLevel;
          socketState.towersInfoData.action = socketState.action;
          setGameState(socketState.towersInfoData);

          setStep(socketState.towersInfoData.currentLevel + 1);
          setChoices(socketState.towersInfoData.answers);
          setBet(socketState.towersInfoData.betValue);
        }
      });
    }
    return () => {
      stopSpectate();
    };
  }, [id]);

  useEffect(() => {
    if (balance < 1 && demoMode && !playing) {
      showDemoOverlay();
    }
  }, [balance]);

  useEffect(() => {
    if (token) {
      if (balance < 1) {
        showDemoOverlay();
      }
    } else {
      showDemoOverlay();
    }
  }, [token]);

  useEffect(() => {
    if (gameState) {
      if (gameState.action === "createTowersResponse") {
        handleReset();

        setMode(typeof gameState.mode === "string" ? gameState.mode : "easy");
        setPlaying(true);
      } else if (
        Object.prototype.hasOwnProperty.call(gameState, "rightAnswer") &&
        gameState.rightAnswer === false
      ) {
        setTickets(gameState?.tickets ?? []);
        // ;
      } else {
        setPlaying(true);
        setBet(parseInt(gameState.bet || gameState.betValue));
        setStep(gameState.level + 1);
        setMode(gameState.mode);

        gameState.answers && setChoices(gameState.answers);
        const potentialWinning =
          gameState.level > 0
            ? Math.floor(columnValues[mode][gameState.level - 1])
            : parseInt(gameState.bet);
        setWin(potentialWinning);

        gameState.level > 0 && gambled && playGameSound(true);
      }
    }
    setClickedButton("");
  }, [
    gameState?.action,
    gameState?.rightAnswer,
    gameState?.level,
    gameState?.currentLevel,
  ]);

  

  const calculateGameMap = () => {
    if (bet < 0 || !bet) {
      return;
    }
    const brkpoint = findBreakpoint(
      gameConfig.gameDetails[mode].breakPoints,
      bet,
    );

    const currentModeValues: number[] = [];
    const betVal = bet * gameConfig.gameDetails[mode].multiplier;
    currentModeValues.push(betVal);
    setWin(bet);

    for (let i = 0; i < brkpoint.steps - 1; i++) {
      const val =
        currentModeValues[i] * gameConfig.gameDetails[mode].multiplier;
      currentModeValues.push(parseFloat(String(val)));
    }

    setColumnValues({
      ...columnValues,
      [mode]: currentModeValues,
    });
  };

  const handleReset = () => {
    setStep(1);
    setChoices([]);
    setTickets([]);
    calculateGameMap();
  };

  useEffect(() => {
    calculateGameMap();
  }, [mode, bet, playing, gameState]);

  // useEffect(() => {
  //   if (!showDemo) {
  //     setBet(gameConfig.gameDetails[mode].minBet);
  //     handleReset();
  //     // ;
  //   }
  // }, [mode, showDemo]);

  const handlePlay = async () => {
    setCheckedOut(false);
    if (!gameState?.rightAnswer && !demoMode) {
      try {
        setCreatingGame(true);
        const data = await createGame(mode, String(bet));
        dispatch(setPublicHash(data.publicHash));
        setPlaying(true);
      } catch (error) {
        console.warn(error);
      } finally {
        setCreatingGame(false);
      }
    } else {
      setBalance(balance - bet);
      setPlaying(true);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handlePlay();
    }
  };
  const handleSetBet = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = 0;
    const { value } = event.target;
    const { maxBet } = gameConfig.gameDetails[mode];

    if (demoMode) {
      if (parseInt(value) > balance) {
        return;
      }
    }

    if (maxBet < parseInt(value)) {
      newValue = maxBet;
    } else {
      newValue = parseInt(value);
    }
    setBet(newValue);
  };

  const handleBetChange = (
    action: BetAction,
  ): MouseEventHandler<HTMLButtonElement> | undefined => {
    let { maxBet } = gameConfig.gameDetails[mode];
    const { minBet } = gameConfig.gameDetails[mode];
    if (demoMode) {
      maxBet = balance;
    }
    switch (action) {
      case BetAction.INCREASE:
        if (bet > 0 && bet * 2 <= maxBet)
          setBet(parseFloat((bet * 2).toFixed(0)));
        else if (!bet) {
          setBet(minBet);
        } else {
          setBet(maxBet);
        }
        break;
      case BetAction.DECREASE:
        if (bet > 0 && bet / 2 >= minBet) {
          setBet(parseFloat((bet / 2).toFixed(0)));
        } else {
          setBet(minBet);
        }
        break;
      case BetAction.MIN:
        setBet(minBet);
        break;
      case BetAction.MAX:
        setBet(demoMode ? balance : balance < maxBet ? balance : maxBet);
        break;
      default:
        break;
    }
    return;
  };

  const { t } = useTranslation();

  // handle super rare instances of unstable WS data?
  const { fieldsPerRow } = mode
    ? gameConfig.gameModes[mode]
    : { fieldsPerRow: 3 };

  const elements = Array.from({ length: fieldsPerRow });
  const lost: boolean = tickets?.length > 0;

  const inputDisabled = !lost && playing;
  const [clickedButton, setClickedButton] = useState<GameFate | string>("");
  const overrideGame = async (fate: GameFate) => {
    setClickedButton(fate);
    try {
      await apiRequest({
        axiosFunction: () =>
          axiosInstance.post(`/admin/towers/fake/${id}`, {
            nextStep: fate,
          }),
        successMessage: t("overrideSuccess"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="relative monkey-div mx-auto">
      {mode !== "medium" && (
        <img
          className={`monkey-head-wrapper bounce-in`}
          src="/images/monkey2x.png"
          alt="monkey"
        />
      )}

      <div
        className={`monkey-wrapper ${
          mode === "medium" ? "opacity-1" : "opacity-0"
        }`}
      >
        <img
          className="monkey-head-wrapper-second bounce-in"
          src="/images/head.svg"
          alt="monkey-head"
        />
        <img
          className="monkey-body bounce-in"
          src="/images/Body.png"
          alt="monkey-head"
        />
        <img
          className="monkey-right bounce-in"
          src="/images/handright2.png"
          alt="monkey-right"
        />
        <img
          className="monkey-left bounce-in"
          src="/images/handleft2.png"
          alt="monkey-left"
        />
      </div>

      <div className={`ticket-wrapper admin mx-auto ${mode}`}>
        {demoMode && showDemo && (
          <div className="overlay fade-in-fast demo-overlay">
            <div className="d-flex flex-column gap-3 px-5 align-items-center justify-content-center h-50 w-100">
              <Button
                onClick={() => setShowDemo(false)}
                className="w-100 py-3 text-uppercase"
                variant="secondary"
              >
                {t("playDemo")}
              </Button>
              <Button className="w-100 py-3 text-uppercase" variant="success">
                {token ? (
                  t("depositPlay")
                ) : (
                  <a
                    className="text-uppercase text-white"
                    href={`${middlewareUrl}/auth/steam`}
                  >
                    {t("depositPlay")}
                  </a>
                )}
              </Button>
              {/* <div className="rounded-full text-uppercase"> {t("or")} </div> */}
            </div>
          </div>
        )}

        {demoMode && !showDemo && (
          <div className="demo-label-wrapper">
            <div className="demo-label fade-in-fast py-2 bg-danger absolute ">
              <div className="text-uppercase text-golden fw-bold">
                {t("demo")}
              </div>
            </div>
          </div>
        )}
        <div className="px-4 py-2 pt-4 d-flex flex-column gap-2">
          <Row>
            <div className="d-flex align-items-center justify-content-between balance-unit ">
              <img src="/images/balance.svg" />

              <CountUp
                key={balance}
                start={playing ? balance : balance - win}
                end={balance}
                duration={1}
              />
              <span className="bi bi-coin opacity-0" />
            </div>
          </Row>

          <div
            key={mode}
            className="d-flex flex-column-reverse gap-2 betting-tiles"
          >
            {columnValues[mode as Difficulty].map((value, index) => {
              const currentLevel: boolean = currentStep === index + 1;

              return (
                <Row key={index} className="gap-1 relative ">
                  {currentLevel && playing && !lost && (
                    <>
                      {" "}
                      <FontAwesomeIcon
                        className="fa-icon fa-left bounce-right"
                        icon={faChevronRight}
                      />{" "}
                      <FontAwesomeIcon
                        className="fa-icon fa-right bounce-left"
                        icon={faChevronLeft}
                      />{" "}
                    </>
                  )}
                  {elements.map((val, key) => {
                    // this stuff has grown to be unreadable and hardly maintable. You must doa seperate component, which simply evaluates all the element state and returns () needed JSX
                    const wonButton: boolean = choices[index] === key + 1;
                    const latestChoice: boolean = recentChoices[0] === key;
                    const awaitinigCheck: boolean =
                      fetching && latestChoice && currentLevel;

                    const isBomb =
                      (tickets[index] === key + 1 && mode !== "hard") ||
                      (mode === "hard" && tickets[index] !== key + 1);
                    return (
                      <Col key={key}>
                        <Button
                          disabled={true}
                          className="bg-transparent  w-100 h-100 p-0 "
                        >
                          {currentLevel && lost && (
                            <div className="game__icon">
                              {isBomb && latestChoice && (
                                <div>
                                  <FontAwesomeIcon
                                    size="lg"
                                    className="icon-bomb animated-element" // TODO: get real icons!
                                    icon={faBomb}
                                  />
                                  <span className="game-text animated-element fw-bold">
                                    {t("tryAgain")}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}

                          {lost && !isBomb && (
                            <img
                              className="game-ic animated-element"
                              src={`/images/${mode}.png`}
                            />
                          )}

                          {((lost && isBomb && !currentLevel) ||
                            !lost ||
                            (lost &&
                              isBomb &&
                              !latestChoice &&
                              mode === "hard")) &&
                            !awaitinigCheck &&
                            !wonButton && (
                              <span className="amount animated-element">
                                {Math.floor(value)}
                              </span>
                            )}

                          {awaitinigCheck && (
                            <span className="game-text fw-bold animated-element">
                              WAIT...
                            </span>
                          )}

                          {wonButton && !lost && (
                            <div className="win-div animated-element">
                              <img src={`/images/${mode}.png`} />
                              <span className="game-text fw-bold">cool</span>
                            </div>
                          )}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>

          <Row className="d-flex flex-column gap-1">
            <div className="d-flex gap-3  py-2 rounded-md  px-0 flex-row justify-content-between">
              <Button
                onClick={() => overrideGame("win")}
                className="flex-1 transition-all"
                variant={
                  clickedButton === "win"
                    ? "success border border-2 border-primary"
                    : "outline-success"
                }
              >
                WIN
              </Button>{" "}
              <Button
                onClick={() => overrideGame("lose")}
                className="flex-1 transition-all"
                variant={
                  clickedButton === "lose"
                    ? "danger border border-2 border-danger"
                    : "outline-danger"
                }
              >
                LOSE
              </Button>
            </div>

            <Button
              key={playing ? "2" : "@"}
              variant={lost || !playing ? "success" : "secondary"}
              className="text-uppercase py-3 text-white fadeIn w-100 cursor-default"
            >
              {lost || !playing ? (
                <span className="fw-bold text-white">
                  {t(demoMode ? "playDemo" : "play")}
                </span>
              ) : (
                <Trans
                  i18nKey="claimWin"
                  values={{
                    winnings:
                      gameState?.level && gameState?.level > 0
                        ? Math.floor(
                            columnValues[mode][
                              (gameState?.level - 1) as number
                            ],
                          )
                        : bet || win,
                  }}
                  components={[
                    <span className="fw-bold text-golden" key="0">
                      {0}
                    </span>,
                  ]}
                />
              )}
            </Button>
            <div className="d-flex action-wrapper bg-secondary-lighter rounded-md p-3 justify-content-between ">
              <div className="d-flex button-wrapper justify-content-between">
                <Button
                  className="text-uppercase px-2 bg-secondary-base"
                  size="sm"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.MIN)}
                >
                  {t("min")}
                </Button>
                <Button
                  className="bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.DECREASE)}
                >
                  <i className="bi bi-dash-lg"></i>{" "}
                </Button>
              </div>

              <input
                className="bg-transparent border-0 text-center text-golden bet-input"
                type="number"
                onKeyPress={handleKeyPress}
                disabled={inputDisabled}
                value={bet}
                onChange={handleSetBet}
              />

              <div className="d-flex button-wrapper justify-content-between">
                <Button
                  className="bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.INCREASE)}
                >
                  <i className="bi bi-plus-lg"></i>{" "}
                </Button>
                <Button
                  className="text-uppercase px-2 bg-secondary-base"
                  variant="secondary"
                  disabled={inputDisabled}
                  onClick={() => handleBetChange(BetAction.MAX)}
                >
                  {t("max")}
                </Button>
              </div>
            </div>
          </Row>
        </div>

        <Tabs
          id="tab-switcher"
          activeKey={mode}
          onSelect={(k) => setMode(k as Difficulty)}
          className=" w-100 full-tabs text-uppercase active-green justify-content-between"
        >
          <Tab
            eventKey="easy"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/easy.png" alt="easy" />
                <span>{t("easy")}</span>
              </div>
            }
          />
          <Tab
            eventKey="medium"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/medium.png" alt="medium" />
                <span>{t("medium")}</span>
              </div>
            }
          />
          <Tab
            eventKey="hard"
            disabled={inputDisabled}
            title={
              <div className="difficulty-button">
                <img src="/images/hard.png" alt="hard" />
                <span>{t("hard")}</span>
              </div>
            }
          />
        </Tabs>
      </div>
    </div>
  );
}

export default TowerAdminGame;
