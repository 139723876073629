import React from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GameCard, { Game, GameName } from "../../Cards/GameCard";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { URLMAP } from "../../../util/const";
interface GameModalProps {
  open: boolean;
  onClose: () => void;
}

const ACTIVE_GAMES: Game[] = [
  { name: "TOWERS", disabled: false },
  // { name: "PVP", disabled: true },
  { name: "COLORS", disabled: true },
];

const GameModal: React.FC<GameModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGameClick = (gameName: GameName) => {
    navigate(URLMAP[gameName.toUpperCase() as GameName]);

    onClose();
    // Add your logic for handling the click event
  };
  return (
    <Modal
      className="purple-gradient fullscreen-modal"
      show={open}
      onHide={onClose}
      size="xl"
    >
      <Container className="games-container m-0 mx-auto d-flex gap-2 flex-column h-100 justify-content-center align-items-center ">
        <h2 className="fw-bold text-center  text-uppercase text-golden">
          {t("selectGame")}
        </h2>
        <Row className="">
          {ACTIVE_GAMES.map((game) => (
            <Col key={game.name} xs={12} sm={6} md className="mb-3">
              <GameCard
                game={game}
                onClick={() => handleGameClick(game.name)}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Modal>
  );
};

export default GameModal;
