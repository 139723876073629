import { Coin, Config, GameColor, Language, PaymentMethod, PaymentMethodType } from "../types";

export const tabTitleClassName: string = "text-uppercase ";
export const subTabClassName: string =
  "text-uppercase bg-secondary-light p-2 rounded-bottom";

export const COINS_MULTIPLIER = 1000

export const ADMIN_RANK = 2

export const URLMAP = {
  HOME: "/",
  BONUSES: "/bonuses",
  RULES: "/rules",
  VERIFY: "/auth/:token",
  'COLORS' : '/colors',
  'TOWERS' : '/',
  'PVP' : '/PVP',
  ADMIN: '/ciaNiekoNera',
  AML: '/aml-kyc-policy',
  PRIVACY : '/privacy-policy',
  TOS: '/terms-of-service',
  BANNED: '/banned'
};

export const COINS: Coin[] = [
  "BTC",
  "BCH",
  "ETH",
  "LTC",
  "SOL",
  "USDT",
  "USDC.TRC20",
];

export const DEFAULT_ITEMS_PER_PAGE = 12

export const SITE_META = {
  title: 'CSGOTower - Fun, Addictive & Profitable CSGO Game',
  description: 'A CS2 gambling site with fun and engaging games'
}

export const TABLE_LIMIT = 13
// we do this because our backend has different reward/color schemes
export const SPIN_COLORS: Record<string, GameColor> = {
  primary: 'red',
  orange: 'black',
  green: 'green',
  purple: 'yellow'
};

type TowerColor = 'purple' | 'green' | 'orange' | 'yellow'

export const SPIN_COLORS_REVERSED: Record<string, TowerColor> = {
  'red' : 'yellow',
  'black': 'orange',
  'green': 'green',
  'yellow': 'purple'
};

export const WS_EVENTS = {
  onTower : 'towers',
  onCheck : 'towersCheckAlternativeResponse',
  user : "user",
  chat: "chat",
  spin : 'wheelOfFortune',
  onlineUsers : 'onlineUsers',
  totalWagered : 'totalWagered',
  totalGames:'totalGames'
}
  
  export const TOWER_ACTIONS = {
   history : 'towersHistory',
   playerTowersHistory : 'playerTowersHistory'

  }



export const CHAT_ACTIONS = {
  updateCount : 'update room user count'
}

export const SITE_LINKS = {

  email: "csgotower.com@gmail.com", 


  telegram: 'csgotower'
};

export interface SiteLanguage {
  name: Language;
  code: string;
}

export const DEFAULT_LNG: SiteLanguage = {
  name: "English",
  code: "en",
};
export const SITE_LANGUAGES: SiteLanguage[] = [
  { name: "English", code: "en" },
  { name: "Français", code: "fr" },
  { name: "Español", code: "es" },
  { name: "Deutsch", code: "de" },
];

export const CHAT_LANGUAGES: SiteLanguage[] = [
  { name: "English", code: "en" },

];


export const backendUrl: string = process.env.REACT_APP_BACKEND_URL || 'http://localhost:1337';
export const middlewareUrl: string = process.env.REACT_APP_MIDDLEWARE_URL || 'http://localhost:5001';
export const COINS_PER_DOLLAR = 1000
export const MIN_BET = 100
export const MAX_BET = 25 * COINS_PER_DOLLAR // 25$
export const gameConfig: Config = {
  gameDetails: {
    easy: {
      minBet: MIN_BET,
      maxBet: MAX_BET,
      fields: 3,
      bombs: 1,
      multiplier: 1.45,
      name: 'easy',
      breakPoints: {
        10: { steps: 10, maxAmount: 20 * 10000, nextSteps: 8 },
        235000: { steps: 8, maxAmount: 28.5 * 10000, nextSteps: 7 },
        285000: { steps: 7, maxAmount: 33 * 10000, nextSteps: 6 },
        330000: { steps: 6, maxAmount: 37 * 10000, nextSteps: 5 },
        370000: { steps: 5, maxAmount: 40.5 * 10000, nextSteps: 4 },
        405000: { steps: 3, maxAmount: 50 * 10000, nextSteps: 3 },
        500000: { steps: 3, maxAmount: 50.1 * 10000, nextSteps: 3 },
      },
    },
    medium: {
      minBet: MIN_BET,
      maxBet: MAX_BET,
      fields: 2,
      bombs: 1,
      multiplier: 1.95,
      name: 'medium',
      breakPoints: {
        10: { steps: 10, maxAmount: 10000, nextSteps: 9 },
        10000: { steps: 9, maxAmount: 1.8 * 10000, nextSteps: 8 },
        18000: { steps: 8, maxAmount: 3.2 * 10000, nextSteps: 7 },
        32000: { steps: 7, maxAmount: 5.6 * 10000, nextSteps: 6 },
        56000: { steps: 6, maxAmount: 8.7 * 10000, nextSteps: 5 },
        87000: { steps: 5, maxAmount: 12 * 10000, nextSteps: 4 },
        120000: { steps: 4, maxAmount: 15 * 10000, nextSteps: 3 },
        150000: { steps: 3, maxAmount: 20 * 10000, nextSteps: 3 },
        200000: { steps: 3, maxAmount: 20.1 * 10000, nextSteps: 3 },
      },
    },
    hard: {
      minBet: MIN_BET,
      maxBet: MAX_BET,
      fields: 3,
      bombs: 2,
      multiplier: 2.95,
      name: 'hard',
      breakPoints: {
        10: { steps: 10, maxAmount: 200, nextSteps: 9 },
        200: { steps: 9, maxAmount: 600, nextSteps: 8 },
        600: { steps: 8, maxAmount: 4.7 * 1000, nextSteps: 6 },
        4700: { steps: 6, maxAmount: 12 * 1000, nextSteps: 5 },
        12000: { steps: 5, maxAmount: 25 * 1000, nextSteps: 4 },
        25000: { steps: 4, maxAmount: 36 * 1000, nextSteps: 3 },
        36000: { steps: 3, maxAmount: 50 * 1000, nextSteps: 3 },
        50000: { steps: 3, maxAmount: 50.1 * 1000, nextSteps: 3 },
      },
    },
  },
  gameModes: {
    'easy': {
      name: 'easy',
      fieldsPerRow: 3,
    },
    'medium': {
      name: 'medium',
      fieldsPerRow: 2,
    },
    'hard': {
      name: 'hard',
      fieldsPerRow: 3,
    },
  },
};

export const DEFAULT_WIN_ITEM = {
  color: "#a7ec2e",
  image:
    "https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXQ9QVcJY8gulRPRVzETuCo6NzGQVtnLQtH-bCkLAB51frbeQISvuO6lZKMkrmtZOKIwWkHvJYl277D9tTz3AXkqkNkNW76cYPEdFJrY1zS-1S4xennm9bi69kJXNWO",
  name: "Coins",
  percentage: 90,
  price: 0.5,
  chance: [10, 100],
}

export  const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
}


export const paymentMethods: { [category in PaymentMethodType]: PaymentMethod } = {
  game: { methods: ["csgo", "rust"]},

  crypto: { methods: COINS },
  cash: {
    methods: ['credit card'],
    withdrawDisabled: true
  },
};