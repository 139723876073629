// store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import modalSlice, { ModalState } from './slices/modalSlice';
import chatSlice, { ChatState } from './slices/chatSlice';
import voucheSlice, { VoucherState } from './slices/voucheSlice';
import socketSlice, { SocketState } from './slices/socketSlice';
import { User } from '../types';
import towerSlice from './slices/towerSlice';


export interface RootState {
  user: User;
  modal: ModalState;
  chat: ChatState;
  voucher: VoucherState;
  socket: SocketState;
}

const store = configureStore({
  reducer: {
    user: userSlice,
    modal: modalSlice,
    chat: chatSlice,
    voucher: voucheSlice,
    socket: socketSlice,
    tower: towerSlice
  },
});

export default store;
