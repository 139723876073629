import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../types';

export const getToken = (): string | null => localStorage.getItem('token');
export const clearToken = () => localStorage.removeItem('token')

const initialState: User = {
  steamid: '',
  avatar: '',
  name: '',
  email: '',
  join_date: 0,
  crypto_address: {
    BTC: '',
    ETH: '',
    LTC: ''
  },
  balance: 0,
  profit: 0,
  wagered: 0,
  deposited: 0,
  withdrawn: 0,
  tickets: 0,
  rank: 0,
  token: getToken(),
  totalGames : 0,
  last_steam_inv_update: 0,
  exp: 0,
  level: 0,
  referredBy: '',
  earningsRefCurrent: 0,
  earningsRefTotal: 0,
  tradelink: '',
  code: '',
  fetching: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.token = action.payload;
    },
    setLoggedOut: (state) => {
      state.token = null;
      clearToken(); 
    },
    setUser: (state, action) => {
      Object.assign(state, { fetching: false }, action.payload);
    },
    setFetching: (state, action) => {
      state.fetching = action.payload;
    },
  },
});


export const { setLoggedIn, setLoggedOut, setUser, setFetching} = userSlice.actions;

export const selectUser = (state: { user: User }) => state.user;

export default userSlice.reducer;
