import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navbar";
import TowerMainPage from "./pages/Tower";
import "./App.css";
import Footer from "./components/Footer/Footer";
import RulesPage from "./pages/Rules";
import { ADMIN_RANK, URLMAP, WS_EVENTS } from "./util/const";
import BonusesPage from "./pages/Bonuses";
import CustomModal from "./components/Modals";
import VerifyPage from "./pages/Verify";
import ColorsPage from "./pages/Colors";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setFetching, setUser } from "./store/slices/userSlice";
import axiosInstance from "./util/axios";
import apiRequest from "./util/apiUtil";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import CookieConsent from "./components/CookieBar";
import { addMessage } from "./store/slices/socketSlice";
import socket from "./websockets/socket";
import AdminPage from "./pages/Admin";
import WofAdmin from "./pages/Admin/WofAdmin";
import TowerAdminPage from "./pages/Admin/Towers";
import { isSerializable } from "./util/helpers";
import NotFoundPage from "./pages/404";
import AmlPage from "./pages/AML";
import PrivacyPage from "./pages/Privacy";
import TosPage from "./pages/Tos";
import BannedPage from "./pages/Banned";

const App: React.FC = () => {
  const { token, rank } = useSelector(selectUser);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleWebSocketEvent = (event: string, data: string) => {
      isSerializable(data) && dispatch(addMessage({ event, message: data }));
    };

    (Object.keys(WS_EVENTS) as Array<keyof typeof WS_EVENTS>).forEach(
      (eventKey: keyof typeof WS_EVENTS) => {
        const event = WS_EVENTS[eventKey];
        socket.on(event, (data: string) => {
          handleWebSocketEvent(event, data);
        });
      },
    );

    socket.emit("user", {
      action: "handshake",
      token,
    });

    socket.emit("chat", { action: "join room", token, room: 0 });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiRequest({
          axiosFunction: () => axiosInstance.post("user"),
        });

        dispatch(setUser(data.user));
      } catch (error) {
        console.error("Error during API request:", error);
      }
    };

    if (token) {
      fetchData();
    } else {
      dispatch(setFetching(false));
    }
  }, [token]);
  const { t } = useTranslation();
  return (
    <Router>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.desc")} />
      </Helmet>
      <CustomModal />
      <>
        <div className="scene" />
        <Navigation />

        <Routes>
          <Route path={URLMAP.HOME} element={<TowerMainPage />} />
          <Route path={URLMAP.COLORS} element={<ColorsPage />} />
          <Route path={URLMAP.BONUSES} element={<BonusesPage />} />
          <Route path={URLMAP.RULES} element={<RulesPage />} />
          <Route path={URLMAP.VERIFY} element={<VerifyPage />} />
          <Route path={URLMAP.ADMIN} element={<AdminPage />} />
          <Route path={URLMAP.AML} element={<AmlPage />} />
          <Route path={URLMAP.PRIVACY} element={<PrivacyPage />} />
          <Route path={URLMAP.TOS} element={<TosPage />} />
          <Route path={URLMAP.BANNED} element={<BannedPage />} />
          {rank === ADMIN_RANK && (
            <Route
              path={process.env.REACT_APP_SECRET_WOF_URL}
              element={<WofAdmin />}
            />
          )}
          {rank === ADMIN_RANK && (
            <Route
              path={`${process.env.REACT_APP_SECRET_TOWER_URL}/:id`}
              element={<TowerAdminPage />}
            />
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <Footer />
        <CookieConsent />
      </>
    </Router>
  );
};

export default App;
