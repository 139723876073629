import apiRequest from "../util/apiUtil";
import axiosInstance from "../util/axios";

interface CheckAlternativeParams {
  alternative: number;
}

const checkAlternative = async ({ alternative }: CheckAlternativeParams): Promise<void> => {
  try {
   await apiRequest({
      axiosFunction: () => axiosInstance.post("/towers/checkalternative", { data: {alternative} }),
    });

    

  } catch (error) {
    console.error("Error during API request:", error);
   
  }
};

export default checkAlternative;
``
